import * as types from '../types'

const initialState = {
    loading: false,
    mensajeError: null,
    list: null,
    totalitems: 0
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_RECOLECCION_LIST_START:
            return {
                ...state,
                mensajeError: null,
                loading: true,
            }
        case types.GET_RECOLECCION_LIST_ERROR:
            return {
                ...state,
                loading: false,
                mensajeError: payload,
            }
        case types.GET_RECOLECCION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data,
                // totalitems: parseInt(payload.headers['x-total-count'], 10)
            }
        case types.RESET:
            return {
                ...initialState,
            }
        default:
            return state;
    }
}