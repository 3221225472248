import {
  GET_AGENTES_START,
  GET_AGENTES_SUCCESS,
  GET_AGENTES_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: [],
  error: null
}

const agentes = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_AGENTES_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_AGENTES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case GET_AGENTES_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default agentes