import NotificationManager from './NotificationManager';

export default () => next => action => {
  
  if (action && 'notification' in action) {
    const {type='info', title="", message="", customClassName} = action.notification;
    
    NotificationManager[type](
      message,
      title,
      3000,
      null,
      null,
      customClassName
    );
  }
  return next(action);

};
