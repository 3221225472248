import { combineReducers } from 'redux';

import ordenPorPaciente from './ordenPorPaciente';
import generales from './generales';
import venta from './venta';
import comentarios from './comentarios';
import productos from './productos';
import detalleOrden from './detalleOrden';
import encuesta from './encuesta';
import promociones from './promociones';
import deleteComentario from './deleteComentario';
import cupon from './cupon';
import kits from './kits';
import validaCP from './validaCP';
import orden from './orden';
import promocionesExpiradas from './promocionesExpiradas';
import solicitudCancelar from './solicitudCancelar';
import cancelaOrden from './cancelaOrden';
import wizardCargaTrabajo from './wizardCargaTrabajo';
import infoEmpresa from './infoEmpresa';
import estudiosCertificados from './estudiosCertificados';

export default combineReducers({
  ordenPorPaciente,
  generales,
  venta,
  comentarios,
  productos,
  detalleOrden,
  encuesta,
  promociones,
  deleteComentario,
  cupon,
  kits,
  validaCP,
  orden,
  promocionesExpiradas,
  solicitudCancelar,
  cancelaOrden,
  wizardCargaTrabajo,
  infoEmpresa,
  estudiosCertificados,
});
