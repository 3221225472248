import * as types from './types';

export const getEntregaStart = () => ({
    type: types.GET_ENTREGA_START
})
export const getEntregaSuccess = payload => ({
    type: types.GET_ENTREGA_SUCCESS,
    payload
})
export const getEntregaError = payload => ({
    type: types.GET_ENTREGA_ERROR,
    payload
})

export const getLogEntrega = () => ({
  type: types.GET_LOG_ENTREGAS_START,
});
export const getLogEntregaSuccess = (payload) => ({
  type: types.GET_LOG_ENTREGAS_SUCCESS,
  payload,
});
export const getLogEntregaError = (payload) => ({
  type: types.GET_LOG_ENTREGAS_ERROR,
  payload,
});


export const getLogInfoStart = () => ({
  type: types.GET_LOG_INFO_START,
});
export const getLogInfoSuccess = (payload) => ({
  type: types.GET_LOG_INFO_SUCCESS,
  payload,
});
export const getLogInfoError = (payload) => ({
  type: types.GET_LOG_INFO_ERROR,
  payload,
});

export const setSearchParam = (payload) => ({
  type: types.SET_SEARCH_PARAMS,
  payload,
});
export const clearState = () => ({
  type: types.CLEAR_STATE,
});

export const getEntregaOrdenStart = () => ({
  type: types.GET_ENTREGA_ORDEN_START,
});
export const getEntregaOrdenSuccess = (payload) => ({
  type: types.GET_ENTREGA_ORDEN_SUCCESS,
  payload,
});
export const getEntregaOrdenError = (payload) => ({
  type: types.GET_ENTREGA_ORDEN_ERROR,
  payload,
});

export const postEntregaOrdenStart = () => ({
  type: types.POST_ENTREGA_ORDEN_START,
});
export const postEntregaOrdenSuccess = (payload) => ({
  type: types.POST_ENTREGA_ORDEN_SUCCESS,
  payload,
});
export const postEntregaOrdenError = (payload) => ({
  type: types.POST_ENTREGA_ORDEN_ERROR,
  payload,
});

export const postExtraMailStart = () => ({
  type: types.POST_EXTRA_MAIL_START,
});
export const postExtraMailSuccess = (payload) => ({
  type: types.POST_EXTRA_MAIL_SUCCESS,
  payload,
});
export const postExtraMailError = (payload) => ({
  type: types.POST_EXTRA_MAIL_ERROR,
  payload,
});

export const putUpdateEmailStart = () => ({
  type: types.PUT_UPDATE_MAIL_START,
});
export const putUpdateEmailSuccess = (payload) => ({
  type: types.PUT_UPDATE_MAIL_SUCCESS,
  payload,
});
export const putUpdateEmailError = (payload) => ({
  type: types.PUT_UPDATE_MAIL_ERROR,
  payload,
});

export const UPDATE_LOG_BY_ID = 'UPDATE_LOG_BY_ID';
export const updateRowLogByID = (index, name, value) => ({
  type: UPDATE_LOG_BY_ID,
  index,
  name,
  value,
});


