export const GET_EMPRESAS_LIST_START = "empresas/GET_EMPRESAS_LIST_START"
export const GET_EMPRESAS_LIST_SUCCESS = "empresas/GET_EMPRESAS_LIST_SUCCESS"
export const GET_EMPRESAS_LIST_ERROR = "empresas/GET_EMPRESAS_LIST_ERROR"

export const GET_EMPRESA_START = "empresas/GET_EMPRESA_START"
export const GET_EMPRESA_SUCCESS = "empresas/GET_EMPRESA_SUCCESS"
export const GET_EMPRESA_ERROR = "empresas/GET_EMPRESA_ERROR"

export const GET_GRUPOS_LIST_START = "empresas/GET_GRUPOS_LIST_START"
export const GET_GRUPOS_LIST_SUCCESS = "empresas/GET_GRUPOS_LIST_SUCCESS"
export const GET_GRUPOS_LIST_ERROR = "empresas/GET_GRUPOS_LIST_ERROR"

export const CREATE_EMPRESA_START = 'empresas/CREATE_EMPRESA_START'
export const CREATE_EMPRESA_SUCCESS = 'empresas/CREATE_EMPRESA_SUCCESS'
export const CREATE_EMPRESA_ERROR = 'empresas/CREATE_EMPRESA_ERROR'

export const UPDATE_EMPRESA_START = 'empresas/UPDATE_EMPRESA_START'
export const UPDATE_EMPRESA_SUCCESS = 'empresas/UPDATE_EMPRESA_SUCCESS'
export const UPDATE_EMPRESA_ERROR = 'empresas/UPDATE_EMPRESA_ERROR'

export const DELETE_EMPRESA_START = 'empresas/DELETE_EMPRESA_START'
export const DELETE_EMPRESA_SUCCESS = 'empresas/DELETE_EMPRESA_SUCCESS'
export const DELETE_EMPRESA_ERROR = 'empresas/DELETE_EMPRESA_ERROR'

export const RESET = 'empresas/RESET'