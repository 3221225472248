import { UPDATE_FORM_PRODUCTOS, RESET_FORM_PRODUCTOS } from '../actions'

const initialState = {
  productos: [],
  subtotal: 0,
  descuento: 0,
  total: 0
}

const productos = (state = initialState, { type, name, value }) => {

  switch(type) {

    case UPDATE_FORM_PRODUCTOS:
      return {
        ...state,
        [name]: value
      }
    
    case RESET_FORM_PRODUCTOS:
      return initialState

    default: return state

  }

}

export default productos