import { combineReducers } from 'redux'

import recepcion from './recepcion'
import muestras from './muestras'
import traspasos from './traspasos'

export default combineReducers({
    recepcion,
    muestras,
    traspasos
})