import { REQUEST, SUCCESS, FAILURE } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const FETCH_PROMOCION_LIST = 'PROMOCION/GET_PROMOCION_LIST';
const FETCH_CUPON_LIST = 'PROMOCION/GET_CUPON_LIST';
const CREATE_PROMOCION = 'PROMOCION/CREATE_PROMOCION';
const FETCH_PROMOCION = 'PROMOCION/GET_PROMOCION';
const UPDATE_PROMOCION = 'PROMOCION/UPDATE_PROMOCION';
// const DELETE_PROMOCION = 'PROMOCION/DELETE_PROMOCION';

// Actions
export const {
  getPromocionesStart,
  getPromocionesSuccess,
  getPromocionesError,
} = getListActions('Promociones', FETCH_PROMOCION_LIST);
export const {
  getCuponesStart,
  getCuponesSuccess,
  getCuponesError,
} = getListActions('Cupones', FETCH_CUPON_LIST);
export const {
  createPromocionStart,
  createPromocionSuccess,
  createPromocionError,
} = getCreateActions('Promocion', CREATE_PROMOCION);
export const {
  getPromocionStart,
  getPromocionSuccess,
  getPromocionError
} = getReadActions('Promocion', FETCH_PROMOCION );
export const {
  updatePromocionStart,
  updatePromocionSuccess,
  updatePromocionError
} = getUpdateActions('Promocion', UPDATE_PROMOCION);

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  cupons: {
    list: [],
    loading: false,
    error: null
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(FETCH_PROMOCION_LIST):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS(FETCH_PROMOCION_LIST):
      return {
        ...state,
        loading: false,
        error: null,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case FAILURE(FETCH_PROMOCION_LIST):
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SUCCESS(FETCH_PROMOCION):
      return {
        ...state,
        loading: false,
        error: null,
        element: payload
      }
    case REQUEST(FETCH_CUPON_LIST):
      return {
        ...state,
        cupons:{
          ...state.cupons,
          loading: true,
          error: null
        }
      }
    case SUCCESS(FETCH_CUPON_LIST):
      return {
        ...state,
        cupons: {
          ...state.cupons,
          list: payload,
          loading: false,
        }
      }
    case FAILURE(FETCH_CUPON_LIST):
      return {
        ...state,
        cupons: {
          ...state.cupons,
          loading: false,
          error: payload
        }
      }
    default:
      return state;
  }
};
