import { combineReducers } from 'redux'

import cliente from './cliente'
import paciente from './paciente'
import medico from './medico'

export default combineReducers({
  cliente,
  paciente,
  medico
})