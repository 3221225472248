import { FAILURE, REQUEST, SUCCESS } from '../../../helpers/actionTypeCreator';
// Types
export const ACTION_TYPES = {
  UPDATE_PASSWORD: 'account/UPDATE_PASSWORD',
  RESET: 'account/RESET',
};

// Actions
export const savePasswordStart = () => ({
  type: REQUEST(ACTION_TYPES.UPDATE_PASSWORD),
});
export const savePasswordSuccess = (currentPassword, newPassword) => ({
  type: SUCCESS(ACTION_TYPES.UPDATE_PASSWORD),
  payload: { currentPassword, newPassword },
});
export const savePasswordError = (payload) => ({
  type: FAILURE(ACTION_TYPES.UPDATE_PASSWORD),
  payload,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  // currentPassword:'',
  // newPassword:''
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
        errorMessage:action.payload
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
