export const GET_EVENTOS_LIST_START = "socialEvents/GET_EVENTOS_LIST_START"
export const GET_EVENTOS_LIST_SUCCESS = "socialEvents/GET_EVENTOS_LIST_SUCCESS"
export const GET_EVENTOS_LIST_ERROR = "socialEvents/GET_EVENTOS_LIST_ERROR"

export const GET_EVENTO_START = "socialEvents/GET_EVENTO_START"
export const GET_EVENTO_SUCCESS = "socialEvents/GET_EVENTO_SUCCESS"
export const GET_EVENTO_ERROR = "socialEvents/GET_EVENTO_ERROR"

export const CREATE_EVENTO_START = 'socialEvents/CREATE_EVENTO_START'
export const CREATE_EVENTO_SUCCESS = 'socialEvents/CREATE_EVENTO_SUCCESS'
export const CREATE_EVENTO_ERROR = 'socialEvents/CREATE_EVENTO_ERROR'

export const DOWNLOAD_QR_EVENTO_START = 'socialEvents/DOWNLOAD_QR_EVENTO_START'
export const DOWNLOAD_QR_EVENTO_SUCCESS = 'socialEvents/DOWNLOAD_QR_EVENTO_SUCCESS'
export const DOWNLOAD_QR_EVENTO_ERROR = 'socialEvents/DOWNLOAD_QR_EVENTO_ERROR'

export const UPLOAD_FILE_EVENTO_START = 'socialEvents/UPLOAD_FILE_EVENTO_START'
export const UPLOAD_FILE_EVENTO_SUCCESS = 'socialEvents/UPLOAD_FILE_EVENTO_SUCCESS'
export const UPLOAD_FILE_EVENTO_ERROR = 'socialEvents/UPLOAD_FILE_EVENTO_ERROR'

export const UPDATE_EVENTO_START = 'socialEvents/UPDATE_EVENTO_START'
export const UPDATE_EVENTO_SUCCESS = 'socialEvents/UPDATE_EVENTO_SUCCESS'
export const UPDATE_EVENTO_ERROR = 'socialEvents/UPDATE_EVENTO_ERROR'

export const DELETE_EVENTO_START = 'socialEvents/DELETE_EVENTO_START'
export const DELETE_EVENTO_SUCCESS = 'socialEvents/DELETE_EVENTO_SUCCESS'
export const DELETE_EVENTO_ERROR = 'socialEvents/DELETE_EVENTO_ERROR'

export const GET_LIST_INVITADOS_ESTACION_START = "socialEvents/GET_LIST_INVITADOS_ESTACION_START"
export const GET_LIST_INVITADOS_ESTACION_SUCCESS = "socialEvents/GET_LIST_INVITADOS_ESTACION_SUCCESS"
export const GET_LIST_INVITADOS_ESTACION_ERROR = "socialEvents/GET_LIST_INVITADOS_ESTACION_ERROR"

export const SEND_QR_INVITADO_START = "socialEvents/SEND_QR_INVITADO_START"
export const SEND_QR_INVITADO_SUCCESS = "socialEvents/SEND_QR_INVITADO_SUCCESS"
export const SEND_QR_INVITADO_ERROR = "socialEvents/SEND_QR_INVITADO_ERROR"

export const RESET = 'socialEvents/RESET'
export const RESET_EVENTO = 'socialEvents/RESET_EVENTO'