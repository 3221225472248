import { REQUEST, SUCCESS, FAILURE } from '../../../helpers/actionTypeCreator';
import {
  getCreateActions,
  getListActions,
  getReadActions,
} from '../../../helpers/actionCreatorCreator';

const PUSH_TICKET_VENTA = 'impresiones/PUSH_TICKET_VENTA';
const PUSH_LABEL = 'impresiones/PUSH_LABEL';
const PUSH_RESULT = 'impresiones/PUSH_RESULT';
const FETCH_SERVICE_STATUS = 'impresiones/FETCH_STATUS';
const FETCH_IMPRESORAS = 'impresiones/IMPRESORAS_LIST';
const RESET = 'impresiones/RESET';
const SHOW_MODAL = 'impresiones/SHOW_MODAL';
const HIDE_MODAL = 'impresiones/HIDE_MODAL';
// Actions
export const {
  getServiceStatusStart,
  getServiceStatusSuccess,
  getServiceStatusError,
} = getReadActions('ServiceStatus', FETCH_SERVICE_STATUS);
export const createTicketVentaStart = (file) => ({
  type: REQUEST(PUSH_TICKET_VENTA),
  payload: file,
});
export const {
  createTicketVentaSuccess,
  createTicketVentaError,
} = getCreateActions('TicketVenta', PUSH_TICKET_VENTA);
export const createLabelStart = (file) => ({
  type: REQUEST(PUSH_LABEL),
  payload: file,
});
export const { createLabelSuccess, createLabelError } = getCreateActions(
  'Label',
  PUSH_LABEL
);
export const createResultStart = (file) => ({
  type: REQUEST(PUSH_RESULT),
  payload: file,
});
export const { createResultSuccess, createResultError } = getCreateActions(
  'Result',
  PUSH_RESULT
);
export const {
  getImpresorasStart,
  getImpresorasSuccess,
  getImpresorasError,
} = getListActions('Impresoras', FETCH_IMPRESORAS);
export const showModal = () => ({ type: SHOW_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });

export const reset = () => ({ type: RESET });

const initialState = {
  thermal: {
    sending: false,
    errorMessage: null,
    file: null,
  },
  label: {
    sending: false,
    errorMessage: null,
    file: null,
  },
  result: {
    sending: false,
    errorMessage: null,
    file: null,
  },
  modal: false,
  serviceStatus: null,
  impresoras: {
    list: [],
    loading: false,
    error: null,
  },
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(FETCH_SERVICE_STATUS):
    case FAILURE(FETCH_SERVICE_STATUS):
      return { ...state, serviceStatus: null };
    case SUCCESS(FETCH_SERVICE_STATUS):
      return { ...state, serviceStatus: action.payload };
    case REQUEST(PUSH_TICKET_VENTA):
      return {
        ...state,
        thermal: {
          errorMessage: null,
          sending: true,
          file: action.payload,
        },
      };
    case FAILURE(PUSH_TICKET_VENTA):
      return {
        ...state,
        thermal: {
          ...state.thermal,
          sending: false,
          errorMessage: action.payload,
        },
      };
    case SUCCESS(PUSH_TICKET_VENTA):
      return {
        ...state,
        serviceStatus: "Activo",
        thermal: {
          ...state.thermal,
          sending: false,
          errorMessage: null,
        },
      };
    case REQUEST(PUSH_LABEL):
      return {
        ...state,
        label: {
          errorMessage: null,
          sending: true,
          file: action.payload,
        },
      };
    case FAILURE(PUSH_LABEL):
      return {
        ...state,
        label: {
          ...state.label,
          sending: false,
          errorMessage: action.payload,
        },
      };
    case SUCCESS(PUSH_LABEL):
      return {
        ...state,
        serviceStatus: "Activo",
        label: {
          ...state.label,
          sending: false,
          errorMessage: null,
        },
      };
    case REQUEST(PUSH_RESULT):
      return {
        ...state,
        result: {
          errorMessage: null,
          sending: true,
          file: action.payload,
        },
      };
    case FAILURE(PUSH_RESULT):
      return {
        ...state,
        result: {
          ...state.result,
          sending: false,
          errorMessage: action.payload,
        },
      };
    case SUCCESS(PUSH_RESULT):
      return {
        ...state,
        serviceStatus: "Activo",
        result: {
          ...state.result,
          sending: false,
          errorMessage: null,
        },
      };
    case REQUEST(FETCH_IMPRESORAS):
      return {
        ...state,
        impresoras: { ...state.impresoras, loading: true, error: null },
      };
    case FAILURE(FETCH_IMPRESORAS):
      return {
        ...state,
        impresoras: {
          ...state.impresoras,
          loading: false,
          error: action.payload,
        },
      };
    case SUCCESS(FETCH_IMPRESORAS):
      return {
        ...state,
        impresoras: {
          ...state.impresoras,
          loading: false,
          list: action.payload,
        },
      };
    case SHOW_MODAL:
      return {
        ...state,
        modal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: false,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
