import { REQUEST, FAILURE, SUCCESS } from '../../helpers/actionTypeCreator';

const getInitialState = () => {
  return {
    default: {
      list: [],
      loading: false,
      error: null,
    },
  };
};

const createCategoryCatalogReducer = (name) => (
  state = getInitialState(),
  action
) => {
  const { type, payload, grp = 'default' } = action;

  switch (type) {
    case REQUEST(name):
      return {
        ...state,
        [grp]: {
          ...state[grp],
          loading: true,
          totalItems: 0,
          error: null,
        },
      };
    case SUCCESS(name):
      return {
        ...state,
        [grp]: {
          loading: false,
          error: null,
          list: payload.data,
          totalItems: payload.totalItems
        },
      };
    case FAILURE(name):
      return {
        ...state,
        [grp]: {
          ...state[grp],
          loading: false,
          error: payload,
        },
      };
    default:
      return state;
  }
};

export default createCategoryCatalogReducer

export const getListActions = (name, type) => {
  return {
    [`get${name}Start`]: (grp) => ({ type: REQUEST(type), grp }),
    [`get${name}Success`]: (grp, payload) => ({ type: SUCCESS(type), payload, grp }),
    [`get${name}Error`]: (grp, payload) => ({ type: FAILURE(type), payload, grp }),
  };
};