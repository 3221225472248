const SET_LIST_ORDENES = 'wizardCargaTrabajo/SET_LIST_ORDENES';
const NEXT_ORDEN = 'wizardCargaTrabajo/NEXT_ORDEN';
const BACK_ORDEN = 'wizardCargaTrabajo/BACK_ORDEN';
const EXIT_WIZARD_MODE = 'wizardCargaTrabajo/EXIT_WIZARD_MODE';

export const setListOrdenes = (payload) => ({
  type: SET_LIST_ORDENES,
  payload,
});

export const nextOrden = () => ({
  type: NEXT_ORDEN,
});

export const backOrden = () => ({
  type: BACK_ORDEN,
});
export const exitWizardMode = () => ({
  type: EXIT_WIZARD_MODE,
});

const initialState = {
  isActiveWizard: false,
  listOrdenes: [],
  ordenActualPosition: 0,
};

const wizardCargaTrabajo = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_ORDENES:
      return {
        ...state,
        listOrdenes: payload,
        ordenActualPosition: 0,
        isActiveWizard: true,
      };
    case NEXT_ORDEN:
      return {
        ...state,
        ordenActualPosition: state.ordenActualPosition + 1,
      };
    case BACK_ORDEN:
      return {
        ...state,
        ordenActualPosition: state.ordenActualPosition - 1,
      };
    case EXIT_WIZARD_MODE:
      return {
        ...state,
        listOrdenes: [],
        ordenActualPosition: 0,
        isActiveWizard: false,
      };
    default:
      return state;
  }
};

export default wizardCargaTrabajo;
