import { combineReducers } from 'redux'

import eventos from './eventos'
import agentes from './agentes'
import noAsignados from './noAsignados'
import asignados from './asignados'
import realizados from './realizados'
import cancelados from './cancelados'
import actualizaStatus from './actualizaStatus'

export default combineReducers({
  eventos,
  agentes,
  noAsignados,
  asignados,
  realizados,
  cancelados,
  actualizaStatus
})