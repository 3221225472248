import {
  GET_INFO_EMPRESA_START,
  GET_INFO_EMPRESA_SUCCESS,
  GET_INFO_EMPRESA_ERROR,
} from '../actions';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

const infoEmpresa = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_INFO_EMPRESA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_INFO_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };

    case GET_INFO_EMPRESA_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    default:
      return state;
  }
};

export default infoEmpresa;
