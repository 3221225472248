export const GET_PACIENTES_LIST_START = "pacientes/GET_PACIENTES_LIST_START"
export const GET_PACIENTES_LIST_SUCCESS = "pacientes/GET_PACIENTES_LIST_SUCCESS"
export const GET_PACIENTES_LIST_ERROR = "pacientes/GET_PACIENTES_LIST_ERROR"

export const GET_PACIENTE_START = "pacientes/GET_PACIENTE_START"
export const GET_PACIENTE_SUCCESS = "pacientes/GET_PACIENTE_SUCCESS"
export const GET_PACIENTE_ERROR = "pacientes/GET_PACIENTE_ERROR"

export const CREATE_PACIENTE_START = 'pacientes/CREATE_PACIENTE_START'
export const CREATE_PACIENTE_SUCCESS = 'pacientes/CREATE_PACIENTE_SUCCESS'
export const CREATE_PACIENTE_ERROR = 'pacientes/CREATE_PACIENTE_ERROR'

export const UPDATE_PACIENTE_START = 'pacientes/UPDATE_PACIENTE_START'
export const UPDATE_PACIENTE_SUCCESS = 'pacientes/UPDATE_PACIENTE_SUCCESS'
export const UPDATE_PACIENTE_ERROR = 'pacientes/UPDATE_PACIENTE_ERROR'

export const DELETE_PACIENTE_START = 'pacientes/DELETE_PACIENTE_START'
export const DELETE_PACIENTE_SUCCESS = 'pacientes/DELETE_PACIENTE_SUCCESS'
export const DELETE_PACIENTE_ERROR = 'pacientes/DELETE_PACIENTE_ERROR'

export const RESET = 'pacientes/RESET'
export const RESET_PACIENTE = 'pacientes/RESET_PACIENTE'