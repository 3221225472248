export const GET_CAJA_START = "caja/GET_CAJA_START"
export const GET_CAJA_SUCCESS = "caja/GET_CAJA_SUCCESS"
export const GET_CAJA_ERROR = "caja/GET_CAJA_ERROR"

export const CREATE_CAJA_START = 'caja/CREATE_CAJA_START'
export const CREATE_CAJA_SUCCESS = 'caja/CREATE_CAJA_SUCCESS'
export const CREATE_CAJA_ERROR = 'caja/CREATE_CAJA_ERROR'

export const UPDATE_CAJA_START = 'caja/UPDATE_CAJA_START'
export const UPDATE_CAJA_SUCCESS = 'caja/UPDATE_CAJA_SUCCESS'
export const UPDATE_CAJA_ERROR = 'caja/UPDATE_CAJA_ERROR'

export const ENABLE_CAJA_START = 'caja/ENABLE_CAJA_START'
export const ENABLE_CAJA_SUCCESS = 'caja/ENABLE_CAJA_SUCCESS'
export const ENABLE_CAJA_ERROR = 'caja/ENABLE_CAJA_ERROR'

export const DISABLE_CAJA_START = 'caja/DISABLE_CAJA_START'
export const DISABLE_CAJA_SUCCESS = 'caja/DISABLE_CAJA_SUCCESS'
export const DISABLE_CAJA_ERROR = 'caja/DISABLE_CAJA_ERROR'

export const GET_CAJAS_LIST_BY_SUCURSAL_START = "caja/GET_CAJAS_LIST_BY_SUCURSAL_START"
export const GET_CAJAS_LIST_BY_SUCURSAL_SUCCESS = "caja/GET_CAJAS_LIST_BY_SUCURSAL_SUCCESS"
export const GET_CAJAS_LIST_BY_SUCURSAL_ERROR = "caja/GET_CAJAS_LIST_BY_SUCURSAL_ERROR"
export const GET_CAJAS_LIST_BY_SUCURSAL_CRUD_SUCCESS = "caja/GET_CAJAS_LIST_BY_SUCURSAL_CRUD_SUCCESS"

export const RESET = 'caja/RESET'

export const GET_CORTE_CAJA_START = "caja/GET_CORTE_CAJA_START"
export const GET_CORTE_CAJA_SUCCESS = "caja/GET_CORTE_CAJA_SUCCESS"
export const GET_CORTE_CAJA_ERROR = "caja/GET_CORTE_CAJA_ERROR"

export const GET_CORTES_LIST_START = "caja/GET_CORTES_LIST_START"
export const GET_CORTES_LIST_SUCCESS = "caja/GET_CORTES_LIST_SUCCESS"
export const GET_CORTES_LIST_ERROR = "caja/GET_CORTES_LIST_ERROR"

export const RESET_CORTES = 'caja/RESET_CORTES'

export const MOVIMIENTOS_CAJA_START = 'caja/MOVIMIENTOS_CAJA_START'
export const MOVIMIENTOS_CAJA_SUCCESS = 'caja/MOVIMIENTOS_CAJA_SUCCESS'
export const MOVIMIENTOS_CAJA_ERROR = 'caja/MOVIMIENTOS_CAJA_ERROR'