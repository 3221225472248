import * as types from './types'

const initialState = {
    loading: false,
    mensajeError: null,
    recoleccion: null,
    totalitems: 0,
    list: null,
    updating: false,
    updateSuccess: false
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_RECOLECCIONES_LIST_START:
        case types.GET_RECOLECCION_START:
            return {
                ...state,
                mensajeError: null,
                loading: true,
                updateSuccess: false
            }
        case types.CREATE_RECOLECCION_START:
        case types.UPDATE_RECOLECCION_START:
        case types.DELETE_RECOLECCION_START:
            return {
                ...state,
                mensajeError: null,
                updateSuccess: false,
                updating: true
            }
        case types.GET_RECOLECCIONES_LIST_ERROR:
        case types.GET_RECOLECCION_ERROR:
        case types.CREATE_RECOLECCION_ERROR:
        case types.UPDATE_RECOLECCION_ERROR:
        case types.DELETE_RECOLECCION_ERROR:
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                mensajeError: payload,
            }
        case types.GET_RECOLECCIONES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data,
                totalitems: parseInt(payload.headers['x-total-count'], 10)
            }
        case types.GET_RECOLECCION_SUCCESS:
            return {
                ...state,
                loading: false,
                recoleccion: payload.data
            }
        case types.CREATE_RECOLECCION_SUCCESS:
        case types.UPDATE_RECOLECCION_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                recoleccion: payload.data
            }
        case types.DELETE_RECOLECCION_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                recoleccion: initialState.recoleccion
            }
        case types.RESET:
            return {
                ...initialState,
            }
        default:
            return state;
    }
}