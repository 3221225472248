import * as types from './types';

const initialState = {
  loading: false,
  mensajeErrorLoading: null,
  pacientes: null,
  totalItems: 0,
  paciente: null,
  updating: false,
  mensajeErrorUpdating: null,
  updateSuccess: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PACIENTES_LIST_START:
      return {
        ...state,
        mensajeErrorLoading: null,
        loading: true,
        updateSuccess: false,
      };
    case types.GET_PACIENTE_START:
    case types.CREATE_PACIENTE_START:
    case types.UPDATE_PACIENTE_START:
    case types.DELETE_PACIENTE_START:
      return {
        ...state,
        mensajeErrorUpdating: null,
        updateSuccess: false,
        updating: true,
      };
    case types.GET_PACIENTES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        mensajeErrorLoading: payload,
      };
    case types.GET_PACIENTE_ERROR:
    case types.CREATE_PACIENTE_ERROR:
    case types.UPDATE_PACIENTE_ERROR:
    case types.DELETE_PACIENTE_ERROR:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        mensajeErrorUpdating: payload,
      };
    case types.GET_PACIENTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pacientes: payload.data,
        totalItems: parseInt(payload.headers['x-total-count'], 10),
      };
    case types.GET_PACIENTE_SUCCESS:
    case types.CREATE_PACIENTE_SUCCESS:
    case types.UPDATE_PACIENTE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        paciente: payload.data,
      };
    case types.DELETE_PACIENTE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        paciente: initialState.paciente,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    case types.RESET_PACIENTE:
      return {
        ...state,
        paciente: initialState.paciente,
      };
    default:
      return state;
  }
};
