// Orden por paciente
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';
export const RESET_AGENDA_DATA = 'RESET_AGENDA_DATA';
export const INITIALIZE_FORM_DATA = 'INITIALIZE_FORM_DATA';
export const DELETE_ORDEN_BY_INDEX = 'ordenPorPaciente/DELETE_ORDEN_BY_INDEX';
export const ADD_ORDEN = 'ordenPorPaciente/ADD_ORDEN';
export const UPDATE_ORDEN_VALUE_BY_INDEX_AND_NAME =
  'ordenPorPaciente/UPDATE_ORDEN_VALUE_BY_INDEX_AND_NAME';
export const UPDATE_ALL_ORDEN_VALUE_BY_NAME = 'UPDATE_ALL_ORDEN_VALUE_BY_NAME';
export const UPDATE_ORDEN_ESTUDIOS_BY_INDEX_AND_NAME =
  'UPDATE_ORDEN_ESTUDIOS_BY_INDEX_AND_NAME';

export const updateFormData = (value) => ({
  type: UPDATE_FORM_DATA,
  value,
});

export const addOrdenFormData = (value) => ({
  type: ADD_ORDEN,
  value,
});

export const removeOrdenByIndex = (index) => ({
  type: DELETE_ORDEN_BY_INDEX,
  index,
});

export const updateOrdenValueByIndexAndName = (index, name, value) => ({
  type: UPDATE_ORDEN_VALUE_BY_INDEX_AND_NAME,
  index,
  name,
  value,
});
export const updateOrdenEstudiosValueByIndexAndName = (index, dataSource) => ({
  type: UPDATE_ORDEN_ESTUDIOS_BY_INDEX_AND_NAME,
  index,
  value: dataSource,
});

export const updateAllOrdenValueByName = (name, value) => ({
  type: UPDATE_ALL_ORDEN_VALUE_BY_NAME,
  name,
  value,
});

export const resetFormData = () => ({
  type: RESET_FORM_DATA,
});

export const resetAgendaData = () => ({
  type: RESET_AGENDA_DATA,
});

export const initializeFormData = (initialData) => ({
  type: INITIALIZE_FORM_DATA,
  initialData,
});

// generales
export const UPDATE_FORM_GENERALES = 'UPDATE_FORM_GENERALES';
export const RESET_FORM_GENERALES = 'RESET_FORM_GENERALES';
export const INITIALIZE_FORM_GENERALES = 'INITIALIZE_FORM_GENERALES';

export const updateFormGenerales = (name, value) => ({
  type: UPDATE_FORM_GENERALES,
  name,
  value,
});

export const resetFormGenerales = () => ({
  type: RESET_FORM_GENERALES,
});

export const initializeFormGenerales = (initialData) => ({
  type: INITIALIZE_FORM_GENERALES,
  initialData,
});

// Productos
export const UPDATE_FORM_PRODUCTOS = 'UPDATE_FORM_PRODUCTOS';
export const RESET_FORM_PRODUCTOS = 'RESET_FORM_PRODUCTOS';

export const updateFormProductos = (name, value) => ({
  type: UPDATE_FORM_PRODUCTOS,
  name,
  value,
});

export const resetFormProductos = () => ({
  type: RESET_FORM_PRODUCTOS,
});

// Venta
export const SAVE_VENTA_START = 'SAVE_VENTA_START';
export const SAVE_VENTA_SUCCESS = 'SAVE_VENTA_SUCCESS';
export const SAVE_VENTA_ERROR = 'SAVE_VENTA_ERROR';

export const saveVentaStart = () => ({
  type: SAVE_VENTA_START,
});

export const saveVentaSuccess = (payload) => ({
  type: SAVE_VENTA_SUCCESS,
  payload,
});

export const saveVentaError = (error) => ({
  type: SAVE_VENTA_ERROR,
  error,
});

// Comentarios cliente
export const ADD_COMENTARIO_START = 'ADD_COMENTARIO_START';
export const ADD_COMENTARIO_SUCCESS = 'ADD_COMENTARIO_SUCCESS';
export const ADD_COMENTARIO_ERROR = 'ADD_COMENTARIO_ERROR';

export const DELETE_COMENTARIO_CLIENTE_START =
  'DELETE_COMENTARIO_CLIENTE_START';
export const DELETE_COMENTARIO_CLIENTE_SUCCESS =
  'DELETE_COMENTARIO_CLIENTE_SUCCESS';
export const DELETE_COMENTARIO_CLIENTE_ERROR =
  'DELETE_COMENTARIO_CLIENTE_ERROR';

export const addComentarioStart = () => ({
  type: ADD_COMENTARIO_START,
});

export const addComentarioSuccess = (payload) => ({
  type: ADD_COMENTARIO_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Guardar comentario',
    message: 'El comentario fue agregado correctamente.',
  },
});

export const addComentarioError = (error) => ({
  type: ADD_COMENTARIO_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Guardar comentario',
    message: 'No se logró guardar el comentario.',
  },
});

export const deleteComentarioClienteStart = () => ({
  type: DELETE_COMENTARIO_CLIENTE_START,
});

export const deleteComentarioClienteSuccess = (payload) => ({
  type: DELETE_COMENTARIO_CLIENTE_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Eliminar comentario',
    message: 'El comentario ha sido eliminado correctamente',
  },
});

export const deleteComentarioClienteError = (error) => ({
  type: DELETE_COMENTARIO_CLIENTE_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Eliminar comentario',
    message: 'No se logró eliminar el comentario',
  },
});

// Detalle de Orden
export const GET_DETALLE_ORDEN_START = 'GET_DETALLE_ORDEN_START';
export const GET_DETALLE_ORDEN_SUCCESS = 'GET_DETALLE_ORDEN_SUCCESS';
export const GET_DETALLE_ORDEN_ERROR = 'GET_DETALLE_ORDEN_ERROR';
export const RESET_DETALLE_ORDEN = 'RESET_DETALLE_ORDEN';

export const getDetalleOrdenStart = () => ({
  type: GET_DETALLE_ORDEN_START,
});
export const getDetalleOrdenSuccess = (payload) => ({
  type: GET_DETALLE_ORDEN_SUCCESS,
  payload,
});
export const getDetalleOrdenError = (payload) => ({
  type: GET_DETALLE_ORDEN_ERROR,
  payload,
});
export const resetDetalleOrden = () => ({
  type: RESET_DETALLE_ORDEN,
});
// actualizacion de solo unelemento del objeto de orden
export const HOT_UPDATE_DETALLE_ORDEN_SUCCESS =
  'HOT_UPDATE_DETALLE_ORDEN_SUCCESS';
export const hotUpdateDetalleOrdenSuccess = (payload) => ({
  type: HOT_UPDATE_DETALLE_ORDEN_SUCCESS,
  payload,
});

// validación de formulas
export const VALIDAR_FORMULA_START = 'VALIDAR_FORMULA_START';
export const VALIDAR_FORMULA_SUCCESS = 'VALIDAR_FORMULA_SUCCESS';
export const VALIDAR_FORMULA_ERROR = 'VALIDAR_FORMULA_ERROR';

export const validarFormulaStart = () => ({
  type: VALIDAR_FORMULA_START,
});
export const validarFormulaSuccess = (payload) => ({
  type: VALIDAR_FORMULA_SUCCESS,
  payload,
});
export const validarFormulaError = () => ({
  type: VALIDAR_FORMULA_ERROR,
});

// comentario dentro de detalle de orden
export const ADD_COMENTARIO_DETALLE_ORDEN_START =
  'ADD_COMENTARIO_DETALLE_ORDEN_START';
export const ADD_COMENTARIO_DETALLE_ORDEN_SUCCESS =
  'ADD_COMENTARIO_DETALLE_ORDEN_SUCCESS';
export const ADD_COMENTARIO_DETALLE_ORDEN_ERROR =
  'ADD_COMENTARIO_DETALLE_ORDEN_ERROR';

export const addComentarioDetalleOrdenStart = () => ({
  type: ADD_COMENTARIO_DETALLE_ORDEN_START,
});
export const addComentarioDetalleOrdenSuccess = (payload) => ({
  type: ADD_COMENTARIO_DETALLE_ORDEN_SUCCESS,
  payload,
});
export const addComentarioDetalleOrdenError = () => ({
  type: ADD_COMENTARIO_DETALLE_ORDEN_ERROR,
});
// NOTAS VENTA Y ORDEN

export const addNotasStart = () => ({
  type: ADD_NOTAS_START,
});
export const addNotasSuccess = (payload) => ({
  type: ADD_NOTAS_SUCCESS,
  payload,
});
export const addNotasError = () => ({
  type: ADD_NOTAS_ERROR,
});

export const ADD_NOTAS_START = 'ADD_NOTAS_START';
export const ADD_NOTAS_SUCCESS = 'ADD_NOTAS_SUCCESS';
export const ADD_NOTAS_ERROR = 'ADD_NOTAS_ERROR';

// Encuesta cuestionario de detalle de orden
export const GET_PREGUNTAS_ORDEN_START = 'GET_PREGUNTAS_ORDEN_START';
export const GET_PREGUNTAS_ORDEN_SUCCESS = 'GET_PREGUNTAS_ORDEN_SUCCESS';
export const GET_PREGUNTAS_ORDEN_ERROR = 'GET_PREGUNTAS_ORDEN_ERROR';

export const getPreguntasOrdenStart = () => ({
  type: GET_PREGUNTAS_ORDEN_START,
});
export const getPreguntasOrdenSuccess = (payload) => ({
  type: GET_PREGUNTAS_ORDEN_SUCCESS,
  payload,
});
export const getPreguntasOrdenError = () => ({
  type: GET_PREGUNTAS_ORDEN_ERROR,
});
// Responder Cuestionario de detalle de orden
export const CREATE_ENCUESTA_ORDEN_START = 'CREATE_ENCUESTA_ORDEN_START';
export const CREATE_ENCUESTA_ORDEN_SUCCESS = 'CREATE_ENCUESTA_ORDEN_SUCCESS';
export const CREATE_ENCUESTA_ORDEN_ERROR = 'CREATE_ENCUESTA_ORDEN_ERROR';

export const createEncuestaOrdenStart = () => ({
  type: CREATE_ENCUESTA_ORDEN_START,
});
export const createEncuestaOrdenSuccess = (payload) => ({
  type: CREATE_ENCUESTA_ORDEN_SUCCESS,
  payload,
});
export const createEncuestaOrdenError = () => ({
  type: CREATE_ENCUESTA_ORDEN_ERROR,
});

// Comentarios paciente
export const ADD_COMENTARIO_PACIENTE_START = 'ADD_COMENTARIO_PACIENTE_START';
export const ADD_COMENTARIO_PACIENTE_SUCCESS =
  'ADD_COMENTARIO_PACIENTE_SUCCESS';
export const ADD_COMENTARIO_PACIENTE_ERROR = 'ADD_COMENTARIO_PACIENTE_ERROR';

export const DELETE_COMENTARIO_PACIENTE_START =
  'DELETE_COMENTARIO_PACIENTE_START';
export const DELETE_COMENTARIO_PACIENTE_SUCCESS =
  'DELETE_COMENTARIO_PACIENTE_SUCCESS';
export const DELETE_COMENTARIO_PACIENTE_ERROR =
  'DELETE_COMENTARIO_PACIENTE_ERROR';

export const addComentarioPacienteStart = () => ({
  type: ADD_COMENTARIO_PACIENTE_START,
});

export const addComentarioPacienteSuccess = (payload) => ({
  type: ADD_COMENTARIO_PACIENTE_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Guardar comentario',
    message: 'El comentario fue agregado correctamente.',
  },
});

export const addComentarioPacienteError = (error) => ({
  type: ADD_COMENTARIO_PACIENTE_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Guardar comentario',
    message: 'No se logró guardar el comentario.',
  },
});

export const deleteComentarioPacienteStart = () => ({
  type: DELETE_COMENTARIO_PACIENTE_START,
});

export const deleteComentarioPacienteSuccess = (payload) => ({
  type: DELETE_COMENTARIO_PACIENTE_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Eliminar comentario',
    message: 'El comentario fue eliminado correctamente',
  },
});

export const deleteComentarioPacienteError = (error) => ({
  type: DELETE_COMENTARIO_PACIENTE_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Eliminar comentario',
    message: 'No se logró eliminar el comentario',
  },
});

// Comentarios medico
export const ADD_COMENTARIO_MEDICO_START = 'ADD_COMENTARIO_MEDICO_START';
export const ADD_COMENTARIO_MEDICO_SUCCESS = 'ADD_COMENTARIO_MEDICO_SUCCESS';
export const ADD_COMENTARIO_MEDICO_ERROR = 'ADD_COMENTARIO_MEDICO_ERROR';

export const DELETE_COMENTARIO_MEDICO_START = 'DELETE_COMENTARIO_MEDICO_START';
export const DELETE_COMENTARIO_MEDICO_SUCCESS =
  'DELETE_COMENTARIO_MEDICO_SUCCESS';
export const DELETE_COMENTARIO_MEDICO_ERROR = 'DELETE_COMENTARIO_MEDICO_ERROR';

export const addComentarioMedicoStart = () => ({
  type: ADD_COMENTARIO_MEDICO_START,
});

export const addComentarioMedicoSuccess = (payload) => ({
  type: ADD_COMENTARIO_MEDICO_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Guardar comentario',
    message: 'El comentario fue agregado correctamente.',
  },
});

export const addComentarioMedicoError = (error) => ({
  type: ADD_COMENTARIO_MEDICO_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Guardar comentario',
    message: 'No se logró guardar el comentario.',
  },
});

export const deleteComentarioMedicoStart = () => ({
  type: DELETE_COMENTARIO_MEDICO_START,
});

export const deleteComentarioMedicoSuccess = (payload) => ({
  type: DELETE_COMENTARIO_MEDICO_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Eliminar comentario',
    message: 'EL comentario fue eliminado correctamente',
  },
});

export const deleteComentarioMedicoError = (error) => ({
  type: DELETE_COMENTARIO_MEDICO_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Eliminar comentario',
    message: 'No se logró eliminar el comentario',
  },
});

// Promociones de sucursal
export const GET_PROMOCIONES_SUCURSAL_START = 'GET_PROMOCIONES_SUCURSAL_START';
export const GET_PROMOCIONES_SUCURSAL_SUCCESS =
  'GET_PROMOCIONES_SUCURSAL_SUCCESS';
export const GET_PROMOCIONES_SUCURSAL_ERROR = 'GET_PROMOCIONES_SUCURSAL_ERROR';

export const getPromocionesSucursalStart = () => ({
  type: GET_PROMOCIONES_SUCURSAL_START,
});

export const getPromocionesSucursalSuccess = (payload) => ({
  type: GET_PROMOCIONES_SUCURSAL_SUCCESS,
  payload,
});

export const getPromocionesSucursalError = (error) => ({
  type: GET_PROMOCIONES_SUCURSAL_ERROR,
  error,
});

// Promociones expiradas
export const GET_PROMOCIONES_EXPIRADAS_START =
  'GET_PROMOCIONES_EXPIRADAS_START';
export const GET_PROMOCIONES_EXPIRADAS_SUCCESS =
  'GET_PROMOCIONES_EXPIRADAS_SUCCESS';
export const GET_PROMOCIONES_EXPIRADAS_ERROR =
  'GET_PROMOCIONES_EXPIRADAS_ERROR';

export const getPromocionesExpiradasStart = () => ({
  type: GET_PROMOCIONES_EXPIRADAS_START,
});

export const getPromocionesExpiradasSuccess = (payload) => ({
  type: GET_PROMOCIONES_EXPIRADAS_SUCCESS,
  payload,
});

export const getPromocionesExpiradasError = (error) => ({
  type: GET_PROMOCIONES_EXPIRADAS_ERROR,
  error,
});

// Promociones
// export const GET_PROMOCIONES_VENTA_START = 'GET_PROMOCIONES_VENTA_START'
// export const GET_PROMOCIONES_VENTA_SUCCESS = 'GET_PROMOCIONES_VENTA_SUCCESS'
// export const GET_PROMOCIONES_VENTA_ERROR = 'GET_PROMOCIONES_VENTA_ERROR'
// export const RESET_PROMOCIONES_VENTA = 'RESET_PROMOCIONES_VENTA'

// export const getPromocionesVentaStart = () => ({
//   type: GET_PROMOCIONES_VENTA_START
// })

// export const getPromocionesVentaSuccess = (payload) => ({
//   type: GET_PROMOCIONES_VENTA_SUCCESS,
//   payload
// })

// export const getPromocionesVentaError = (error) => ({
//   type: GET_PROMOCIONES_VENTA_ERROR,
//   error
// })

// export const resetPromocionesVenta = () => ({
//   type: RESET_PROMOCIONES_VENTA
// })

// Cupon
export const GET_CUPON_START = 'GET_CUPON_START';
export const GET_CUPON_SUCCESS = 'GET_CUPON_SUCCESS';
export const GET_CUPON_ERROR = 'GET_CUPON_ERROR';

export const getCuponStart = () => ({
  type: GET_CUPON_START,
});

export const getCuponSuccess = (payload) => ({
  type: GET_CUPON_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Validar cupón',
    message: 'Cupón válido',
  },
});

export const getCuponError = (error) => ({
  type: GET_CUPON_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Validar cupón',
    message: 'Cupón no aceptado',
  },
});

// Kit de productos
export const GET_PRODUCTOS_KIT_START = 'GET_PRODUCTOS_KIT_START';
export const GET_PRODUCTOS_KIT_SUCCESS = 'GET_PRODUCTOS_KIT_SUCCESS';
export const GET_PRODUCTOS_KIT_ERROR = 'GET_PRODUCTOS_KIT_ERROR';

export const getProductosKitStart = () => ({
  type: GET_PRODUCTOS_KIT_START,
});

export const getProductosKitSuccess = (payload) => ({
  type: GET_PRODUCTOS_KIT_SUCCESS,
  payload,
});

export const getProductosKitError = (error) => ({
  type: GET_PRODUCTOS_KIT_ERROR,
  error,
});

// Código postal

export const VALIDA_CP_START = 'VALIDA_CP_START';
export const VALIDA_CP_SUCCESS = 'VALIDA_CP_SUCCESS';
export const VALIDA_CP_ERROR = 'VALIDA_CP_ERROR';

export const validaCPStart = () => ({
  type: VALIDA_CP_START,
});

export const validaCPSuccess = (payload) => ({
  type: VALIDA_CP_SUCCESS,
  payload,
});

export const validaCPError = (error) => ({
  type: VALIDA_CP_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Validar código postal',
    message: 'Error al validar el cupón',
  },
});

// Data de orden

export const GET_EDIT_ORDEN_START = 'GET_EDIT_ORDEN_START';
export const GET_EDIT_ORDEN_SUCCESS = 'GET_EDIT_ORDEN_SUCCESS';
export const GET_EDIT_ORDEN_ERROR = 'GET_EDIT_ORDEN_ERROR';

export const getEditOrdenStart = () => ({
  type: GET_EDIT_ORDEN_START,
});

export const getEditOrdenSuccess = (payload) => ({
  type: GET_EDIT_ORDEN_SUCCESS,
  payload,
});

export const getEditOrdenError = (error) => ({
  type: GET_EDIT_ORDEN_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Editar orden',
    message: 'Error al obtener la información de la orden',
  },
});

export const GET_CANCELAR_ORDEN_START = 'GET_CANCELAR_ORDEN_START';
export const GET_CANCELAR_ORDEN_SUCCESS = 'GET_CANCELAR_ORDEN_SUCCESS';
export const GET_CANCELAR_ORDEN_ERROR = 'GET_CANCELAR_ORDEN_ERROR';

export const getCancelarOrdenStart = () => ({
  type: GET_CANCELAR_ORDEN_START,
});

export const getCancelarOrdenSuccess = (payload) => ({
  type: GET_CANCELAR_ORDEN_SUCCESS,
  payload,
});

export const getCancelarOrdenError = (error) => ({
  type: GET_CANCELAR_ORDEN_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Cancelar orden',
    message: 'Error al obtener la información de la orden.',
  },
});

export const CANCELAR_ORDEN_START = 'CANCELAR_ORDEN_START';
export const CANCELAR_ORDEN_SUCCESS = 'CANCELAR_ORDEN_SUCCESS';
export const CANCELAR_ORDEN_ERROR = 'CANCELAR_ORDEN_ERROR';

export const cancelarOrdenStart = () => ({
  type: CANCELAR_ORDEN_START,
});

export const cancelarOrdenSuccess = (payload) => ({
  type: CANCELAR_ORDEN_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Cancelar orden',
    message: 'La orden fue cancelada correctamente.',
  },
});

export const cancelarOrdenError = (error) => ({
  type: CANCELAR_ORDEN_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Cancelar orden',
    message: 'Error al cancelar la orden.',
  },
});

export const GET_INFO_EMPRESA_START = 'GET_INFO_EMPRESA_START';
export const GET_INFO_EMPRESA_SUCCESS = 'GET_INFO_EMPRESA_SUCCESS';
export const GET_INFO_EMPRESA_ERROR = 'GET_INFO_EMPRESA_ERROR';

export const getInfoEmpresaStart = () => ({
  type: GET_INFO_EMPRESA_START,
});

export const getInfoEmpresaSuccess = (payload) => ({
  type: GET_INFO_EMPRESA_SUCCESS,
  payload,
});

export const getInfoEmpresaError = (error) => ({
  type: GET_INFO_EMPRESA_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Información de empresa',
    message: 'Error al obtener información de la empresa.',
  },
});

export const GET_ESTUDIOS_CERTIFICADOS_START =
  'GET_ESTUDIOS_CERTIFICADOS_START';
export const GET_ESTUDIOS_CERTIFICADOS_SUCCESS =
  'GET_ESTUDIOS_CERTIFICADOS_SUCCESS';
export const GET_ESTUDIOS_CERTIFICADOS_ERROR =
  'GET_ESTUDIOS_CERTIFICADOS_ERROR';

export const getEstudiosCertificadosStart = () => ({
  type: GET_ESTUDIOS_CERTIFICADOS_START,
});

export const getEstudiosCertificadosSuccess = (payload) => ({
  type: GET_ESTUDIOS_CERTIFICADOS_SUCCESS,
  payload,
});

export const getEstudiosCertificadosError = (error) => ({
  type: GET_ESTUDIOS_CERTIFICADOS_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Estudios certificados',
    message: 'Error al obtener los estudios certificados.',
  },
});
