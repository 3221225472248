import createCategoryCatalogReducer, {
  getListActions,
} from '../CategoryCreatorRedux';

const TYPE = 'TEMPLATES';
const scopeName = `CATALOGO/${TYPE}`;

export const {
  getTemplatesByCatStart,
  getTemplatesByCatSuccess,
  getTemplatesByCatError,
} = getListActions('TemplatesByCat', scopeName);

export default createCategoryCatalogReducer(scopeName);
