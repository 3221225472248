export const GET_CATEGORIAS_MEDIDAS_LIST_START = 'unidadesMedida/GET_CATEGORIAS_MEDIDAS_LIST_START'
export const GET_CATEGORIAS_MEDIDAS_LIST_SUCCESS = 'unidadesMedida/GET_CATEGORIAS_MEDIDAS_LIST_SUCCESS'
export const GET_CATEGORIAS_MEDIDAS_LIST_ERROR = 'unidadesMedida/GET_CATEGORIAS_MEDIDAS_LIST_ERROR'

export const GET_UNIDADES_MEDIDAS_LIST_START = "unidadesMedida/GET_UNIDADES_MEDIDAS_LIST_START"
export const GET_UNIDADES_MEDIDAS_LIST_SUCCESS = "unidadesMedida/GET_UNIDADES_MEDIDAS_LIST_SUCCESS"
export const GET_UNIDADES_MEDIDAS_LIST_ERROR = "unidadesMedida/GET_UNIDADES_MEDIDAS_LIST_ERROR"

export const GET_UNIDAD_MEDIDA_START = "unidadesMedida/GET_UNIDAD_MEDIDA_START"
export const GET_UNIDAD_MEDIDA_SUCCESS = "unidadesMedida/GET_UNIDAD_MEDIDA_SUCCESS"
export const GET_UNIDAD_MEDIDA_ERROR = "unidadesMedida/GET_UNIDAD_MEDIDA_ERROR"

export const CREATE_UNIDAD_MEDIDA_START = 'unidadesMedida/CREATE_UNIDAD_MEDIDA_START'
export const CREATE_UNIDAD_MEDIDA_SUCCESS = 'unidadesMedida/CREATE_UNIDAD_MEDIDA_SUCCESS'
export const CREATE_UNIDAD_MEDIDA_ERROR = 'unidadesMedida/CREATE_UNIDAD_MEDIDA_ERROR'

export const UPDATE_UNIDAD_MEDIDA_START = 'unidadesMedida/UPDATE_UNIDAD_MEDIDA_START'
export const UPDATE_UNIDAD_MEDIDA_SUCCESS = 'unidadesMedida/UPDATE_UNIDAD_MEDIDA_SUCCESS'
export const UPDATE_UNIDAD_MEDIDA_ERROR = 'unidadesMedida/UPDATE_UNIDAD_MEDIDA_ERROR'

export const DELETE_UNIDAD_MEDIDA_START = 'unidadesMedida/DELETE_UNIDAD_MEDIDA_START'
export const DELETE_UNIDAD_MEDIDA_SUCCESS = 'unidadesMedida/DELETE_UNIDAD_MEDIDA_SUCCESS'
export const DELETE_UNIDAD_MEDIDA_ERROR = 'unidadesMedida/DELETE_UNIDAD_MEDIDA_ERROR'

export const RESET = 'unidadesMedida/RESET'
export const RESET_UNIDAD_MEDIDA = 'unidadesMedida/RESET_UNIDAD_MEDIDA'