import { combineReducers } from 'redux'

import estudios from './estudios'
import pacientes from './pacientes'
import medicos from './medicos'
import clientes from './clientes'
import productos from './productos'
import ordenes from './ordenes'

export default combineReducers({
    estudios,
    pacientes,
    medicos,
    clientes,
    productos,
    ordenes
})