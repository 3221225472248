
export const GET_RECOLECCIONES_LIST_START = "MENSAJERIA/GET_RECOLECCIONES_LIST_START"
export const GET_RECOLECCIONES_LIST_SUCCESS = "MENSAJERIA/GET_RECOLECCIONES_LIST_SUCCESS"
export const GET_RECOLECCIONES_LIST_ERROR = "MENSAJERIA/GET_RECOLECCIONES_LIST_ERROR"

export const GET_RECOLECCION_START = "MENSAJERIA/GET_RECOLECCION_START"
export const GET_RECOLECCION_SUCCESS = "MENSAJERIA/GET_RECOLECCION_SUCCESS"
export const GET_RECOLECCION_ERROR = "MENSAJERIA/GET_RECOLECCION_ERROR"

export const CREATE_RECOLECCION_START = 'MENSAJERIA/CREATE_RECOLECCION_START'
export const CREATE_RECOLECCION_SUCCESS = 'MENSAJERIA/CREATE_RECOLECCION_SUCCESS'
export const CREATE_RECOLECCION_ERROR = 'MENSAJERIA/CREATE_RECOLECCION_ERROR'

export const UPDATE_RECOLECCION_START = 'MENSAJERIA/UPDATE_RECOLECCION_START'
export const UPDATE_RECOLECCION_SUCCESS = 'MENSAJERIA/UPDATE_RECOLECCION_SUCCESS'
export const UPDATE_RECOLECCION_ERROR = 'MENSAJERIA/UPDATE_RECOLECCION_ERROR'

export const DELETE_RECOLECCION_START = 'MENSAJERIA/DELETE_RECOLECCION_START'
export const DELETE_RECOLECCION_SUCCESS = 'MENSAJERIA/DELETE_RECOLECCION_SUCCESS'
export const DELETE_RECOLECCION_ERROR = 'MENSAJERIA/DELETE_RECOLECCION_ERROR'

export const RESET = 'MENSAJERIA/RESET'