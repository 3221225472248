const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0
}

export const GET_PRODUCTOS_LIST = "PRODUCTOS/GET_PRODUCTOS_LIST"
export const GET_PRODUCTOS_LIST_SUCCESS = "PRODUCTOS/GET_PRODUCTOS_LIST_SUCCESS"
export const GET_PRODUCTOS_LIST_ERROR = "PRODUCTOS/GET_PRODUCTOS_LIST_ERROR"
export const GET_PRODUCTO = "PRODUCTO/GET_PRODUCTO"
export const GET_PRODUCTO_SUCCESS = "PRODUCTO/GET_PRODUCTO_SUCCESS"
export const GET_PRODUCTO_ERROR = "PRODUCTO/GET_PRODUCTO_ERROR"

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTOS_LIST:
    case GET_PRODUCTO:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_PRODUCTOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        list: payload.data,
        totalItems: payload.totalItems
      }

    case GET_PRODUCTO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        element: payload
      }

    case GET_PRODUCTO_ERROR:
    case GET_PRODUCTOS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    default: return state
  }
}

export const getProductos = () => ({
  type: GET_PRODUCTOS_LIST
})

export const getProductosSuccess = payload => ({
  type: GET_PRODUCTOS_LIST_SUCCESS,
  payload
})

export const getProductosError = payload => ({
  type: GET_PRODUCTOS_LIST_ERROR,
  payload
})