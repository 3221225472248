import {
  GET_DETALLE_ORDEN_START,
  GET_DETALLE_ORDEN_SUCCESS,
  GET_DETALLE_ORDEN_ERROR,
  ADD_COMENTARIO_DETALLE_ORDEN_ERROR,
  ADD_COMENTARIO_DETALLE_ORDEN_SUCCESS,
  ADD_COMENTARIO_DETALLE_ORDEN_START,
  RESET_DETALLE_ORDEN,
  HOT_UPDATE_DETALLE_ORDEN_SUCCESS,
} from '../actions';

const initialState = {
  loading: false,
  data: null,
  mensajeError: null,
  comentario: null,
  updateComentario: false,
};

const detalleOrden = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DETALLE_ORDEN_START:
      return {
        ...state,
        loading: true,
        mensajeError: null,
      };
    case GET_DETALLE_ORDEN_ERROR:
      return {
        ...state,
        loading: false,
        mensajeError: payload,
      };
    case GET_DETALLE_ORDEN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case HOT_UPDATE_DETALLE_ORDEN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case ADD_COMENTARIO_DETALLE_ORDEN_START:
      return {
        ...state,
        mensajeError: null,
        updateComentario: true,
      };
    case ADD_COMENTARIO_DETALLE_ORDEN_SUCCESS:
      return {
        ...state,
        updateComentario: false,
        comentario: payload,
      };
    case ADD_COMENTARIO_DETALLE_ORDEN_ERROR:
      return {
        ...state,
        mensajeError: payload,
        updateComentario: false,
      };
    case RESET_DETALLE_ORDEN:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default detalleOrden;
