export const GET_ESTADO_CUENTA_START = "estadoCuenta/START"
export const GET_ESTADO_CUENTA_SUCCESS = "estadoCuenta/SUCCESSFUL"
export const GET_ESTADO_CUENTA_ERROR = "estadoCuenta/ERROR"

const initialState = {
  isFetching: false,
  list: null,
  error: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_ESTADO_CUENTA_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case GET_ESTADO_CUENTA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: payload
      }

    case GET_ESTADO_CUENTA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload
      }

    default: return state

  }

}

export const getEstadoCuentaStart = () => ({
  type: GET_ESTADO_CUENTA_START
})

export const getEstadoCuentaSuccess = payload => ({
  type: GET_ESTADO_CUENTA_SUCCESS,
  payload
})

export const getEstadoCuentaError = error => ({
  type: GET_ESTADO_CUENTA_ERROR,
  error
})