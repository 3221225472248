import * as types from './types';
import { UPDATE_LOG_BY_ID } from "./actions";

const initialState = {
  loading: false,
  mensajeError: null,
  entrega: null,
  error: null,
  list: [],
  totalItems: 0,
  searchParams: {},
  updateLoading: false,
  updateMail: false,
};

export default (state = initialState, { type, payload, index, name, value }) => {
  switch (type) {
    case types.GET_ENTREGA_START:
      return {
        ...state,
        mensajeErrorLoading: null,
        updateLoading: true,
      };
    case types.GET_ENTREGA_SUCCESS:
      return {
        ...state,
        loading: false,
        updateLoading: payload.data,
      };
    case types.GET_ENTREGA_ERROR:
      return {
        ...state,
        mensajeError: payload,
      };
    case types.GET_LOG_ENTREGAS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.GET_LOG_ENTREGAS_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case types.GET_LOG_ENTREGAS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case types.SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: payload,
        loading: true,
      };
    case types.CLEAR_STATE:
      return initialState;

    case types.GET_LOG_INFO_START:
      return {
        ...state,
        error: null,
        updateLoading: true,
      };
    case types.GET_LOG_INFO_SUCCESS:
      return {
        ...state,
        list: payload,
        updateLoading: false,
      };
    case types.GET_LOG_INFO_ERROR:
      return {
        ...state,
        error: payload,
        updateLoading: false,
      };
    case types.GET_ENTREGA_ORDEN_START:
      return {
        ...state,
        error: null,
        updateLoading: true,
      };
    case types.GET_ENTREGA_ORDEN_SUCCESS:
      return {
        ...state,
        list: payload,
        updateLoading: false,
      };
    case types.GET_ENTREGA_ORDEN_ERROR:
      return {
        ...state,
        error: payload,
        updateLoading: false,
      };
    case types.POST_ENTREGA_ORDEN_START:
      return {
        ...state,
        error: null,
        updateLoading: true,
      };
    case types.POST_ENTREGA_ORDEN_SUCCESS:
      return {
        ...state,
        list: payload,
        updateLoading: false,
      };
    case types.POST_ENTREGA_ORDEN_ERROR:
      return {
        ...state,
        error: payload,
        updateLoading: false,
      };
    case types.POST_EXTRA_MAIL_START:
      return {
        ...state,
        error: null,
        updateLoading: true,
      };
    case types.POST_EXTRA_MAIL_SUCCESS:
      return {
        ...state,
        list: payload,
        updateLoading: false,
      };
    case types.POST_EXTRA_MAIL_ERROR:
      return {
        ...state,
        error: payload,
        updateLoading: false,
      };
    case types.PUT_UPDATE_MAIL_START:
      return {
        ...state,
        error: null,
        updateMail: true,
      };
    case types.PUT_UPDATE_MAIL_SUCCESS:
      return {
        ...state,
        list: payload,
        updateMail: false,
      };
    case types.PUT_UPDATE_MAIL_ERROR:
      return {
        ...state,
        error: payload,
        updateMail: false,
      };
      case UPDATE_LOG_BY_ID:
        return {
          ...state,
          list: state.list.map(log => log.id === index ? {...log, [name]: value} : log
          )
        };
    default:
      return state;
  }
};
