import * as types from './types';

const initialState = {
  loading: false,
  mensajeError: null,
  empresas: [],
  grupos: [],
  totalItems: 0,
  empresa: {
    id: '',
    clave: '',
    rfc: '',
    razonSocial: '',
    descripcion: '',
    grupo: '',
    activo: true,
  },
  updating: false,
  updateSuccess: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_EMPRESAS_LIST_START:
      return {
        ...state,
        mensajeError: null,
        loading: true,
      };
    case types.GET_GRUPOS_LIST_START:
    case types.GET_EMPRESA_START:
    case types.CREATE_EMPRESA_START:
    case types.UPDATE_EMPRESA_START:
    case types.DELETE_EMPRESA_START:
      return {
        ...state,
        mensajeError: null,
        updating: true,
      };
    case types.GET_EMPRESAS_LIST_ERROR:
    case types.GET_GRUPOS_LIST_ERROR:
    case types.GET_EMPRESA_ERROR:
    case types.CREATE_EMPRESA_ERROR:
    case types.UPDATE_EMPRESA_ERROR:
    case types.DELETE_EMPRESA_ERROR:
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        mensajeError: payload.data,
      };
    case types.GET_EMPRESAS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        empresas: payload.data,
        totalItems: parseInt(payload.headers['x-total-count'], 10),
      };
    case types.GET_GRUPOS_LIST_SUCCESS:
      return {
        ...state,
        updating: false,
        grupos: payload.data,
      };
    case types.GET_EMPRESA_SUCCESS:
      return {
        ...state,
        updating: false,
        empresa: payload.data,
      };
    case types.CREATE_EMPRESA_SUCCESS:
    case types.UPDATE_EMPRESA_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        empresa: payload.data,
      };
    case types.DELETE_EMPRESA_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        empresa: initialState.empresa,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
