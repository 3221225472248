export const GET_ESTUDIOS_MATCH_START = "busqueda/GET_ESTUDIOS_MATCH_START"
export const GET_ESTUDIOS_MATCH_SUCCESS = "busqueda/GET_ESTUDIOS_MATCH_SUCCESS"
export const GET_ESTUDIOS_MATCH_ERROR = "busqueda/GET_ESTUDIOS_MATCH_ERROR"
export const RESET_ESTUDIOS = "busqueda/RESET_ESTUDIOS"

export const GET_ORDENES_MATCH_START = "busqueda/GET_ORDENES_MATCH_START"
export const GET_ORDENES_MATCH_SUCCESS = "busqueda/GET_ORDENES_MATCH_SUCCESS"
export const GET_ORDENES_MATCH_ERROR = "busqueda/GET_ORDENES_MATCH_ERROR"
export const RESET_ORDENES = "busqueda/RESET_ORDENES"
export const GET_STATUS_ORDENES_MATCH_START = "busqueda/GET_STATUS_ORDENES_MATCH_START"
export const GET_STATUS_ORDENES_MATCH_SUCCESS = "busqueda/GET_STATUS_ORDENES_MATCH_SUCCESS"
export const GET_STATUS_ORDENES_MATCH_ERROR = "busqueda/GET_STATUS_ORDENES_MATCH_ERROR"

export const GET_PACIENTES_MATCH_START = "busqueda/GET_PACIENTES_MATCH_START"
export const GET_PACIENTES_MATCH_SUCCESS = "busqueda/GET_PACIENTES_MATCH_SUCCESS"
export const GET_PACIENTES_MATCH_ERROR = "busqueda/GET_PACIENTES_MATCH_ERROR"
export const RESET_PACIENTES = "busqueda/RESET_PACIENTES"

export const GET_MEDICOS_MATCH_START = "busqueda/GET_MEDICOS_MATCH_START"
export const GET_MEDICOS_MATCH_SUCCESS = "busqueda/GET_MEDICOS_MATCH_SUCCESS"
export const GET_MEDICOS_MATCH_ERROR = "busqueda/GET_MEDICOS_MATCH_ERROR"
export const RESET_MEDICOS = "busqueda/RESET_MEDICOS"

export const GET_CLIENTES_MATCH_START = "busqueda/GET_CLIENTES_MATCH_START"
export const GET_CLIENTES_MATCH_SUCCESS = "busqueda/GET_CLIENTES_MATCH_SUCCESS"
export const GET_CLIENTES_MATCH_ERROR = "busqueda/GET_CLIENTES_MATCH_ERROR"
export const RESET_CLIENTES = 'busqueda/RESET_CLIENTES'

export const GET_PRODUCTOS_MATCH_START = "busqueda/GET_PRODUCTOS_MATCH_START"
export const GET_PRODUCTOS_MATCH_SUCCESS = "busqueda/GET_PRODUCTOS_MATCH_SUCCESS"
export const GET_PRODUCTOS_MATCH_ERROR = "busqueda/GET_PRODUCTOS_MATCH_ERROR"
export const RESET_PRODUCTOS = 'busqueda/RESET_PRODUCTOS'