const SET_CENTRO_TRABAJO = 'asignaciones/SET_CENTRO_TRABAJO';
const SET_SUCURSAL = 'asignaciones/SET_SUCURSAL';
const SET_GRUPO_EMPRESARIAL = 'asignaciones/SET_GRUPO_EMPRESARIAL';
const SET_CAJA = 'asignaciones/SET_CAJA';
const SET_AREA = 'asignaiones/SET_AREA';
const SET_STATE = 'asignaciones/SET_STATE';

const initialState = {
  sucursal: null,
  grupoEmpresarial: null,
  caja: null,
  centroTrabajo: null,
  area: null,
  logo: null,
};
export const setState = (payload) => ({
  type: SET_STATE,
  payload,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATE:
      return payload;
    case SET_CENTRO_TRABAJO:
      return { ...state, centroTrabajo: payload };
    case SET_SUCURSAL:
      return { ...state, sucursal: payload };
    case SET_GRUPO_EMPRESARIAL:
      return { ...state, grupoEmpresarial: payload };
    case SET_CAJA:
      return { ...state, caja: payload };
    case SET_AREA:
      return { ...state, area: payload };
    default:
      return state;
  }
};
