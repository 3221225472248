import {
    GET_PREGUNTAS_ORDEN_START,
    GET_PREGUNTAS_ORDEN_SUCCESS,
    GET_PREGUNTAS_ORDEN_ERROR,
    CREATE_ENCUESTA_ORDEN_START,
    CREATE_ENCUESTA_ORDEN_SUCCESS,
    CREATE_ENCUESTA_ORDEN_ERROR
} from '../actions'

const initialState = {
    loading: false,
    data: null,
    mensajeError: null,
    updateSuccess: false,
    updating: false    
}

const encuesta = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PREGUNTAS_ORDEN_START:
            return {
                ...state,
                loading: true,
                mensajeError: null
            }
        case CREATE_ENCUESTA_ORDEN_START:
            return {
                ...state,
                mensajeError: null,
                updateSuccess: false,
                updating: true
            }
        case GET_PREGUNTAS_ORDEN_ERROR:
        case CREATE_ENCUESTA_ORDEN_ERROR:
            return {
                ...state,
                loading: false,
                mensajeError: payload,                
                updating: false,
                updateSuccess: false,
            }
        case GET_PREGUNTAS_ORDEN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            }
        case CREATE_ENCUESTA_ORDEN_SUCCESS:
            return {
                ...state,
                // data: payload.data,
                updating: false,
                updateSuccess: true,
            }
        default: return state
    }
}

export default encuesta;