export const GET_LIST_CONFIG_START = 'configuracion/GET_LIST_CONFIG_START'
export const GET_LIST_CONFIG_SUCCESS = 'configuracion/GET_LIST_CONFIG_SUCCESS'
export const GET_LIST_CONFIG_ERROR = 'configuracion/GET_LIST_CONFIG_ERROR'

export const GET_CONFIG_START = 'configuracion/GET_CONFIG_START'
export const GET_CONFIG_SUCCESS = 'configuracion/GET_CONFIG_SUCCESS'
export const GET_CONFIG_ERROR = 'configuracion/GET_CONFIG_ERROR'

export const CREATE_CONFIG_START = 'configuracion/CREATE_CONFIG_START'
export const CREATE_CONFIG_SUCCESS = 'configuracion/CREATE_CONFIG_SUCCESS'
export const CREATE_CONFIG_ERROR = 'configuracion/CREATE_CONFIG_ERROR'

export const UPDATE_CONFIG_START = 'configuracion/UPDATE_CONFIG_START'
export const UPDATE_CONFIG_SUCCESS = 'configuracion/UPDATE_CONFIG_SUCCESS'
export const UPDATE_CONFIG_ERROR = 'configuracion/UPDATE_CONFIG_ERROR'

export const DELETE_CONFIG_START = 'configuracion/DELETE_CONFIG_START'
export const DELETE_CONFIG_SUCCESS = 'configuracion/DELETE_CONFIG_SUCCESS'
export const DELETE_CONFIG_ERROR = 'configuracion/DELETE_CONFIG_ERROR'


export const RESET_CONFIG = 'configuracion/RESET_CONFIG'
export const RESET = 'configuracion/RESET'