import {
  ADD_COMENTARIO_START,
  ADD_COMENTARIO_SUCCESS,
  ADD_COMENTARIO_ERROR
} from '../actions'

const initialState = {
  isFetching: false,
  payload: null,
  error: null
}

const comentarios = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case ADD_COMENTARIO_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case ADD_COMENTARIO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload
      }

    case ADD_COMENTARIO_ERROR:
      return {
        ...state,
        isFetching: false,
        error
      }

    default: return state

  }

}

export default comentarios