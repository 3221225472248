import { getCurrentConfigPdf } from "../../../helpers/Utils";

const SET_OPTION_REPORTE = 'asignaciones/SET_OPTION_REPORTE';

export const setOption = (payload) => ({
  type: SET_OPTION_REPORTE,
  payload,
});

const initialState = {
  frameless: getCurrentConfigPdf(),
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPTION_REPORTE:
      return { ...state, frameless: payload };
    default:
      return state;
  }
};
