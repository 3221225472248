// Eventos Recolecciones
export const GET_RECOLECCIONES_START = "GET_RECOLECCIONES_START"
export const GET_RECOLECCIONES_SUCCESS = "GET_RECOLECCIONES_SUCCESS"
export const GET_RECOLECCIONES_ERROR = "GET_RECOLECCIONES_ERROR"
export const SET_RECOLECCIONES_DAY_VIEW = 'SET_RECOLECCIONES_DAY_VIEW'

export const getRecoleccionesStart = () => ({
  type: GET_RECOLECCIONES_START
})

export const getRecoleccionesSuccess = (payload) => ({
  type: GET_RECOLECCIONES_SUCCESS,
  payload
})
export const setRecoleccionesDayView = (payload) => ({
  type: SET_RECOLECCIONES_DAY_VIEW,
  payload
})

export const getRecoleccionesError = (error) => ({
  type: GET_RECOLECCIONES_ERROR,
  error,
  notification: {
    type: "error",
    title: "Obtener eventos",
    message: "No fue posible obtener las solicitudes de recolección"
  }
})

// Usuarios
export const GET_USUARIOS_RECOLECCION_START = "GET_USUARIO_RECOLECCION_START"
export const GET_USUARIOS_RECOLECCION_SUCCESS = "GET_USUARIO_RECOLECCION_SUCCESS"
export const GET_USUARIOS_RECOLECCION_ERROR = "GET_USUARIO_RECOLECCION_ERROR"

export const getUsuariosRecoleccionStart = () => ({
  type: GET_USUARIOS_RECOLECCION_START
})

export const getUsuariosRecoleccionSuccess = (payload) => ({
  type: GET_USUARIOS_RECOLECCION_SUCCESS,
  payload
})

export const getUsuariosRecoleccionError = (error) => ({
  type: GET_USUARIOS_RECOLECCION_ERROR,
  error,
  notification: {
    type: "error",
    title: "Obtenet mensajeros",
    message: "No fue posible obtener los mensajeros."
  }
})

// Recolecciones solicitadas
export const GET_RECOLECCIONES_SOLICITADAS_START = "GET_RECOLECCIONES_SOLICITADAS_START"
export const GET_RECOLECCIONES_SOLICITADAS_SUCCESS = "GET_RECOLECCIONES_SOLICITADAS_SUCCESS"
export const GET_RECOLECCIONES_SOLICITADAS_ERROR = "GET_RECOLECCIONES_SOLICITADAS_ERROR"

export const getRecoleccionesSolicitadasStart = () => ({
  type: GET_RECOLECCIONES_SOLICITADAS_START
})

export const getRecoleccionesSolicitadasSuccess =  (payload) => ({
  type: GET_RECOLECCIONES_SOLICITADAS_SUCCESS,
  payload
})

export const getRecoleccionesSolicitadasError = (error) => ({
  type: GET_RECOLECCIONES_SOLICITADAS_ERROR,
  error,
  notification: {
    type: "error",
    title: "Obtener recolecciones solicitadas",
    message: "No fue posible obtener las recolecciones solicitadas"
  }
})

// Recolecciones Asignadas
export const GET_RECOLECCIONES_ASIGNADAS_START = "GET_RECOLECCIONES_ASIGNADAS_START"
export const GET_RECOLECCIONES_ASIGNADAS_SUCCESS = "GET_RECOLECCIONES_ASIGNADAS_SUCCESS"
export const GET_RECOLECCIONES_ASIGNADAS_ERROR = "GET_RECOLECCIONES_ASIGNADAS_ERROR"

export const getRecoleccionesAsignadasStart = () => ({
  type: GET_RECOLECCIONES_ASIGNADAS_START
})

export const getRecoleccionesAsignadasSuccess = (payload) => ({
  type: GET_RECOLECCIONES_ASIGNADAS_SUCCESS,
  payload
})

export const getRecoleccionesAsignadasError = (error) => ({
  type: GET_RECOLECCIONES_ASIGNADAS_ERROR,
  error,
  notification: {
    type: "error",
    title: "Obtener recolecciones asignadas",
    message: "No fue posible obtener las recolecciones asginadas"
  }
})

// Recolecciones Realizadas
export const GET_RECOLECCIONES_REALIZADAS_START = "GET_RECOLECCIONES_REALIZADAS_START"
export const GET_RECOLECCIONES_REALIZADAS_SUCCESS = "GET_RECOLECCIONES_REALIZADAS_SUCCESS"
export const GET_RECOLECCIONES_REALIZADAS_ERROR = "GET_RECOLECCIONES_REALIZADAS_ERROR"

export const getRecoleccionesRealizadasStart = () => ({
  type: GET_RECOLECCIONES_REALIZADAS_START
})

export const getRecoleccionesRealizadasSuccess = (payload) => ({
  type: GET_RECOLECCIONES_REALIZADAS_SUCCESS,
  payload
})

export const getRecoleccionesRealizadasError = (error) => ({
  type: GET_RECOLECCIONES_REALIZADAS_ERROR,
  error,
  notification: {
    type: "error",
    title: "Obtener recolecciones realizadas",
    message: "No fue posible obtener las recolecciones realizadas"
  }
})

// Recolecciones Canceladas
export const GET_RECOLECCIONES_CANCELADAS_START = "GET_RECOLECCIONES_CANCELADAS_START"
export const GET_RECOLECCIONES_CANCELADAS_SUCCESS = "GET_RECOLECCIONES_CANCELADAS_SUCCESS"
export const GET_RECOLECCIONES_CANCELADAS_ERROR = "GET_RECOLECCIONES_CANCELADAS_ERROR"

export const getRecoleccionesCanceladasStart = () => ({
  type: GET_RECOLECCIONES_CANCELADAS_START
})

export const getRecoleccionesCanceladasSuccess = (payload) => ({
  type: GET_RECOLECCIONES_CANCELADAS_SUCCESS,
  payload
})

export const getRecoleccionesCanceladasError = (error) => ({
  type: GET_RECOLECCIONES_CANCELADAS_ERROR,
  error,
  notification: {
    type: "error",
    title: "Obtener recolecciones canceladas",
    message: "No fue posible obtener las recolecciones canceladas"
  }
})

// ACtualiza status de recolecciones
export const UPDATE_RECOLECCION_STATUS_START = "UPDATE_RECOLECCION_STATUS_START"
export const UPDATE_RECOLECCION_STATUS_SUCCESS = "UPDATE_RECOLECCION_STATUS_SUCCESS"
export const UPDATE_RECOLECCION_STATUS_ERROR = "UPDATE_RECOLECCION_STATUS_ERROR"

export const updateRecoleccionStatusStart = (draggableId) => ({
  type: UPDATE_RECOLECCION_STATUS_START,
  draggableId
})

export const updateRecoleccionStatusSuccess = (payload) => ({
  type: UPDATE_RECOLECCION_STATUS_SUCCESS,
  payload,
  notification: {
    type: "success",
    title: "Cambiar status",
    message: "La solicitud a cambiado de estado"
  }
})

export const updateRecoleccionStatusError = (error) => ({
  type: UPDATE_RECOLECCION_STATUS_ERROR,
  error,
  notification: {
    type: "error",
    title: "Cambiar status",
    message: "No fue posible cambiar el estado"
  }
})

// Cerrar recolección con ciertas muestras
export const POST_RECOLECCION_MUESTRAS_START = "POST_RECOLECCION_MUESTRAS_START"
export const POST_RECOLECCION_MUESTRAS_SUCCESS = "POST_RECOLECCION_MUESTRAS_SUCCESS"
export const POST_RECOLECCION_MUESTRAS_ERROR = "POST_RECOLECCION_MUESTRAS_ERROR"

export const postCerrarRecoleccionMuestraStart = () => ({
  type: POST_RECOLECCION_MUESTRAS_START
})

export const postCerrarRecoleccionMuestraSuccess = payload=> ({
  type: POST_RECOLECCION_MUESTRAS_SUCCESS,
  payload
})

export const postCerrarRecoleccionMuestraError = payload => ({
  type: POST_RECOLECCION_MUESTRAS_ERROR,
  payload
})


