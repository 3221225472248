import * as types from '../types'

const initialState = {
    loading: false,
    list: null,
    error: null
}

const tiposUnidadesMedidas = (state = initialState, { type, payload }) => {
    switch (type) {

        case types.GET_CATEGORIAS_MEDIDAS_LIST_START:
            return {
                ...state,
                loading: true,
                error: payload
            }

        case types.GET_CATEGORIAS_MEDIDAS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case types.GET_CATEGORIAS_MEDIDAS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }

        default: return state

    }
}
export default tiposUnidadesMedidas