import {
  GET_CUPON_START,
  GET_CUPON_SUCCESS,
  GET_CUPON_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: null,
  error: null
}

const cupon = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_CUPON_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_CUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case GET_CUPON_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default cupon