import {
  REQUEST,
  FAILURE,
  SUCCESS,
} from '../../../../helpers/actionTypeCreator';

const GET_CAT_VALUE = 'store/GET_CAT_VALUE';

const initialState = {
  unidadesMedida: {},
  antibioticos: {},
  bacterias: {},
  formulas: {},
  cultivos: {},
  esquemas: {},
  parametrosCultivo: {},
  predeterminados: {},
};

export const getCatValueByIdStart = (cat, id) => ({
  type: REQUEST(GET_CAT_VALUE),
  cat,
  id,
});
export const getCatValueByIdError = (cat, id, payload) => ({
  type: FAILURE(GET_CAT_VALUE),
  cat,
  id,
  payload,
});
export const getCatValueByIdSuccess = (cat, id, payload) => ({
  type: SUCCESS(GET_CAT_VALUE),
  cat,
  id,
  payload,
});

const storeByIdReducer = (state = initialState, action) => {
  const { type, payload, cat, id } = action;
  switch (type) {
    case REQUEST(GET_CAT_VALUE):
      return {
        ...state,
        [cat]: {
          ...state[cat],
          [id]: { loading: true, error: null, value: null },
        },
      };
    case FAILURE(GET_CAT_VALUE): {
      return {
        ...state,
        [cat]: {
          ...state[cat],
          [id]: { loading: false, error: payload },
        },
      };
    }
    case SUCCESS(GET_CAT_VALUE):
      return {
        ...state,
        [cat]: {
          ...state[cat],
          [id]: {
            loading: false,
            value: payload,
          },
        },
      };
    default:
      return state;
  }
};

export default storeByIdReducer;
