import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';
import { CRUDTypes, ListDataItems } from '../../../helpers/CRUDTypes';
import { EstudioI } from '../EstudioI';

enum EstudiosTypes {
  GET_ESTUDIOS = 'estudios/GET_ESTUDIOS',
  GET_ESTUDIO = 'estudios/GET_ESTUDIO',
  CREATE_ESTUDIO = 'estudios/CREATE_ESTUDIO',
  UPDATE_ESTUDIO = 'estudios/UPDATE_ESTUDIO',
  DELETE_ESTUDIO = 'estudios/DELETE_ESTUDIO',
  GET_DISENIOS_ESTUDIOS = 'estudios/GET_DISENIOS_ESTUDIOS',
  GET_DISENIO = 'estudios/GET_DISENIO',
  CREATE_DISENIO = 'estudios/CREATE_DISENIO',
  UPDATE_DISENIO = 'estudios/UPDATE_DISENIO',
  REMOVE_DISENIO = 'estudios/REMOVE_DISENIO',
  RESET = 'estudios/RESET',
}

export const {
  getEstudiosStart,
  getEstudiosSuccess,
  getEstudiosError,
} = getListActions('Estudios', EstudiosTypes.GET_ESTUDIOS);
export const {
  getEstudioStart,
  getEstudioSuccess,
  getEstudioError,
} = getReadActions('Estudio', EstudiosTypes.GET_ESTUDIO);
export const {
  createEstudioStart,
  createEstudioSuccess,
  createEstudioError,
} = getCreateActions('Estudio', EstudiosTypes.CREATE_ESTUDIO);
export const {
  updateEstudioStart,
  updateEstudioSuccess,
  updateEstudioError,
} = getUpdateActions('Estudio', EstudiosTypes.UPDATE_ESTUDIO);

export const {
  getDiseniosEstudioStart,
  getDiseniosEstudioSuccess,
  getDiseniosEstudioError,
} = getListActions('DiseniosEstudio', EstudiosTypes.GET_DISENIOS_ESTUDIOS);

export const {
  getDisenioStart,
  getDisenioSuccess,
  getDisenioError,
} = getReadActions('Disenio', EstudiosTypes.GET_DISENIO);

export const {
  createDisenioStart,
  createDisenioSuccess,
  createDisenioError,
} = getCreateActions('Disenio', EstudiosTypes.CREATE_DISENIO);

export const {
  updateDisenioStart,
  updateDisenioSuccess,
  updateDisenioError,
} = getUpdateActions('Disenio', EstudiosTypes.UPDATE_DISENIO);

export const resetEstudio = () => ({
  type: EstudiosTypes.RESET,
});

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
  disenios: {
    list: [],
    loading: false,
    updating: false,
    actual: null,
    error: null,
  },
};

const reducer = (
  state = initialState,
  { type, payload }: CRUDTypes<EstudioI>
) => {
  switch (type) {
    case REQUEST(EstudiosTypes.GET_ESTUDIOS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(EstudiosTypes.GET_ESTUDIO):
    case REQUEST(EstudiosTypes.CREATE_ESTUDIO):
    case REQUEST(EstudiosTypes.UPDATE_ESTUDIO):
    case REQUEST(EstudiosTypes.DELETE_ESTUDIO):
      return {
        ...state,
        error: null,
        updating: true,
      };
    case REQUEST(EstudiosTypes.GET_DISENIOS_ESTUDIOS):
      return {
        ...state,
        disenios: { ...initialState.disenios, loading: true, error: null },
      };
    case REQUEST(EstudiosTypes.GET_DISENIO):
    case REQUEST(EstudiosTypes.CREATE_DISENIO):
    case REQUEST(EstudiosTypes.UPDATE_DISENIO):
      return {
        ...state,
        disenios: { ...state.disenios, updating: true, error: null },
      };
    case FAILURE(EstudiosTypes.GET_ESTUDIOS):
    case FAILURE(EstudiosTypes.GET_ESTUDIO):
    case FAILURE(EstudiosTypes.CREATE_ESTUDIO):
    case FAILURE(EstudiosTypes.UPDATE_ESTUDIO):
    case FAILURE(EstudiosTypes.DELETE_ESTUDIO):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case FAILURE(EstudiosTypes.GET_DISENIOS_ESTUDIOS):
      return {
        ...state,
        disenios: {
          ...state.disenios,
          loading: false,
          error: payload,
        },
      };
    case FAILURE(EstudiosTypes.GET_DISENIO):
    case FAILURE(EstudiosTypes.CREATE_DISENIO):
    case FAILURE(EstudiosTypes.UPDATE_DISENIO):
      return {
        ...state,
        disenios: {
          ...state.disenios,
          updating: false,
          error: payload,
        },
      };
    case SUCCESS(EstudiosTypes.GET_ESTUDIOS):
      return {
        ...state,
        loading: false,
        list: (payload as ListDataItems<EstudioI>).data,
        totalItems: (payload as ListDataItems<EstudioI>).totalItems,
      };
    case SUCCESS(EstudiosTypes.GET_ESTUDIO):
    case SUCCESS(EstudiosTypes.CREATE_ESTUDIO):
    case SUCCESS(EstudiosTypes.UPDATE_ESTUDIO):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    case SUCCESS(EstudiosTypes.DELETE_ESTUDIO):
      return {
        ...state,
        updating: false,
        element: null,
      };
    case SUCCESS(EstudiosTypes.GET_DISENIOS_ESTUDIOS):
      return {
        ...state,
        disenios: {
          ...state.disenios,
          loading: false,
          list: payload,
        },
      };
    case SUCCESS(EstudiosTypes.GET_DISENIO):
    case SUCCESS(EstudiosTypes.CREATE_DISENIO):
    case SUCCESS(EstudiosTypes.UPDATE_DISENIO):
      return {
        ...state,
        disenios: {
          ...state.disenios,
          updating: false,
          actual: payload,
        },
      };
    case REQUEST(EstudiosTypes.RESET):
      return {
        ...state,
        disenios: {
          list: [],
          loading: false,
          updating: false,
          actual: null,
          error: null,
        },
      };
    default:
      return state;
  }
};
export default reducer;
