import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const GET_BACTERIAS = 'bacterias/GET_BACTERIAS';
const GET_BACTERIA = 'bacterias/GET_BACTERIA';
const CREATE_BACTERIA = 'bacterias/CREATE_BACTERIA';
const UPDATE_BACTERIA = 'bacterias/UPDATE_BACTERIA';

export const {
  getBacteriasStart,
  getBacteriasSuccess,
  getBacteriasError,
} = getListActions('Bacterias', GET_BACTERIAS);

export const {
  getBacteriaStart,
  getBacteriaSuccess,
  getBacteriaError,
} = getReadActions('Bacteria', GET_BACTERIA);

export const {
  createBacteriaStart,
  createBacteriaSuccess,
  createBacteriaError,
} = getCreateActions('Bacteria', CREATE_BACTERIA);

export const {
  updateBacteriaStart,
  updateBacteriaSuccess,
  updateBacteriaError,
} = getUpdateActions('Bacteria', UPDATE_BACTERIA);

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(GET_BACTERIAS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(GET_BACTERIA):
    case REQUEST(CREATE_BACTERIA):
    case REQUEST(UPDATE_BACTERIA):
      return {
        ...state,
        updating: true,
        error: null,
      };
    case FAILURE(GET_BACTERIAS):
    case FAILURE(GET_BACTERIA):
    case FAILURE(CREATE_BACTERIA):
    case FAILURE(UPDATE_BACTERIA):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case SUCCESS(GET_BACTERIAS):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(GET_BACTERIA):
    case SUCCESS(CREATE_BACTERIA):
    case SUCCESS(UPDATE_BACTERIA):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
