export const GET_CONFIG_CORREOS = 'correosSettings/GET_CONFIG_CORREOS';
export const GET_CONFIG_CORREOS_SUCCESS =
  'correosSettings/GET_CONFIG_CORREOS_SUCCESS';
export const GET_CONFIG_CORREOS_ERROR =
  'correosSettings/GET_CONFIG_CORREOS_SUCCESS';
export const UPDATE_CONFIG_CORREOS = 'correosSettings/UPDATE_CONFIG_CORREOS';
export const UPDATE_CONFIG_CORREOS_SUCCESS =
  'correosSettings/UPDATE_CONFIG_CORREOS_SUCCESS';
export const UPDATE_CONFIG_CORREOS_ERROR =
  'correosSettings/UPDATE_CONFIG_CORREOS_SUCCESS';
export const RESET = 'correosSettings/RESET';
