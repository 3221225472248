import { combineReducers } from 'redux'
import { createCatalogReducer } from '../../../redux/reducerCreator'
import unidadesMedida from '../unidades-medida'
import templates from '../templates'
import db from '../../designer/engine/redux/createCatalogStore'

export default combineReducers({
  db,
  antibioticos: createCatalogReducer('ANTIBIOTICOS'),
  antibiogramas: createCatalogReducer('ANTIBIOGRAMAS'),
  bacterias: createCatalogReducer('BACTERIAS'),
  unidadesMedida,
  normas: createCatalogReducer('NORMAS'),
  gruposBacteriologicos: createCatalogReducer('GRUPOS_BACTERIOLOGICOS'),
  gruposCultivos: createCatalogReducer('GRUPOS_CULTIVOS'),
  esquemasBacteriologicos: createCatalogReducer('ESQUEMAS_BACTERIOLOGICOS'),
  templates,
  predeterminados: createCatalogReducer('RESULTADOS_PREDETERMINADOS')
})