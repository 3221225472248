export const GET_DETALLE_VENTA_START='ventas/GET_DETALLE_VENTA_START'
export const GET_DETALLE_VENTA_SUCCESS='ventas/GET_DETALLE_VENTA_SUCCESS'
export const GET_DETALLE_VENTA_ERROR='ventas/GET_DETALLE_VENTA_ERROR'

export const RESET_DETALLE_VENTA='ventas/RESET_DETALLE_VENTA'

export const UPDATE_PAGOS_VENTA_START='ventas/UPDATE_PAGOS_VENTA_START'
export const UPDATE_PAGOS_VENTA_SUCCESS='ventas/UPDATE_PAGOS_VENTA_SUCCESS'
export const UPDATE_PAGOS_VENTA_ERROR='ventas/UPDATE_PAGOS_VENTA_ERROR'

export const GET_SOLICITUD_CANCELAR_START = "GET_SOLICITUD_CANCELAR_START";
export const GET_SOLICITUD_CANCELAR_SUCCESS = "GET_SOLICITUD_CANCELAR_SUCCESS";
export const GET_SOLICITUD_CANCELAR_ERROR = "GET_SOLICITUD_CANCELAR_ERROR";

export const CANCELAR_VENTA_START = "CANCELAR_VENTA_START";
export const CANCELAR_VENTA_SUCCESS = "CANCELAR_VENTA_SUCCESS";
export const CANCELAR_VENTA_ERROR =  "CANCELAR_VENTA_ERROR";