import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

export const GET_PERFIL_LIST = 'PERFIL/GET_PERFIL_LIST';
export const GET_PERFIL = 'PERFIL/GET_PERFIL';
export const CREATE_PERFIL = 'PERFIL/CREATE_PERFIL';
export const UPDATE_PERFIL = 'PERFIL/UPDATE_PERFIL';
export const DELETE_PERFIL = 'PERFIL/DELETE_PERFIL';

export const {
  getPerfilesStart,
  getPerfilesSuccess,
  getPerfilesError,
} = getListActions('Perfiles', GET_PERFIL_LIST);
export const {
  getPerfilStart,
  getPerfilSuccess,
  getPerfilError,
} = getReadActions('Perfil', GET_PERFIL);
export const {
  createPerfilStart,
  createPerfilSuccess,
  createPerfilError,
} = getCreateActions('Perfil', CREATE_PERFIL);
export const {
  updatePerfilStart,
  updatePerfilSuccess,
  updatePerfilError,
} = getUpdateActions('Perfil', UPDATE_PERFIL);


const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(GET_PERFIL_LIST):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(GET_PERFIL):
    case REQUEST(CREATE_PERFIL):
    case REQUEST(UPDATE_PERFIL):
    case REQUEST(DELETE_PERFIL):
      return {
        ...state,
        error: null,
        updating: true,
      };
    case FAILURE(GET_PERFIL_LIST):
    case FAILURE(GET_PERFIL):
    case FAILURE(CREATE_PERFIL):
    case FAILURE(UPDATE_PERFIL):
    case FAILURE(DELETE_PERFIL):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };

    case SUCCESS(GET_PERFIL_LIST):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(GET_PERFIL):
    case SUCCESS(CREATE_PERFIL):
    case SUCCESS(UPDATE_PERFIL):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    case SUCCESS(DELETE_PERFIL):
      return {
        ...state,
        updating: false,
        element: null,
      };

    default:
      return state;
  }
};
