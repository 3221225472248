import {
    CREATE_TRASPASO_START,
    CREATE_TRASPASO_SUCCESS,
    CREATE_TRASPASO_ERROR
} from '../types'

const initialState = {
    loading: false,
    data: null,
    mensajeError: null
}

const detalleOrden = (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_TRASPASO_START:
            return {
                ...state,
                loading: true,
                mensajeError: null
            }
        case CREATE_TRASPASO_ERROR:
            return {
                ...state,
                loading: false,
                mensajeError: payload
            }
        case CREATE_TRASPASO_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            }
        default: return state
    }
}

export default detalleOrden;