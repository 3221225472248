import { REQUEST, SUCCESS, FAILURE } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const FETCH_PARAMETROS_CULTIVOS =
  'parametrosCultivos/FETCH_PARAMETROS_CULTIVOS';
const FETCH_PARAMETRO_CULTIVO = 'parametrosCultivos/FETCH_PARAMETRO_CULTIVO';
const CREATE_PARAMETRO_CULTIVO = 'parametrosCultivos/CREATE_PARAMETRO_CULTIVO';
const UPDATE_PARAMETRO_CULTIVO = 'parametrosCultivos/UPDATE_PARAMETRO_CULTIVO';
const DELETE_PARAMETRO_CULTIVO = 'parametrosCultivos/DELETE_PARAMETRO_CULTIVO';
const RESET = 'parametrosCultivos/RESET';

export const {
  getParametrosCultivosStart,
  getParametrosCultivosSuccess,
  getParametrosCultivosError,
} = getListActions('ParametrosCultivos', FETCH_PARAMETROS_CULTIVOS);
export const {
  createParametroCultivoStart,
  createParametroCultivoSuccess,
  createParametroCultivoError,
} = getCreateActions('ParametroCultivo', CREATE_PARAMETRO_CULTIVO);
export const {
  getParametroCultivoStart,
  getParametroCultivoSuccess,
  getParametroCultivoError,
} = getReadActions('ParametroCultivo', FETCH_PARAMETRO_CULTIVO);
export const {
  updateParametroCultivoStart,
  updateParametroCultivoSuccess,
  updateParametroCultivoError,
} = getUpdateActions('ParametroCultivo', UPDATE_PARAMETRO_CULTIVO);
export const reset = () => ({ type: RESET });

const initialState = {
  loading: false,
  errorMessage: null,
  list: [],
  parametroCultivo: null,
  updating: false,
  totalItems: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(FETCH_PARAMETROS_CULTIVOS):
    case REQUEST(FETCH_PARAMETRO_CULTIVO):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(CREATE_PARAMETRO_CULTIVO):
    case REQUEST(UPDATE_PARAMETRO_CULTIVO):
    case REQUEST(DELETE_PARAMETRO_CULTIVO):
      return {
        ...state,
        errorMessage: null,
        updating: true,
      };
    case FAILURE(FETCH_PARAMETROS_CULTIVOS):
    case FAILURE(FETCH_PARAMETRO_CULTIVO):
    case FAILURE(CREATE_PARAMETRO_CULTIVO):
    case FAILURE(UPDATE_PARAMETRO_CULTIVO):
    case FAILURE(DELETE_PARAMETRO_CULTIVO):
      return {
        ...state,
        loading: false,
        updating: false,
        errorMessage: payload,
      };
    case SUCCESS(FETCH_PARAMETROS_CULTIVOS):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(FETCH_PARAMETRO_CULTIVO):
      return {
        ...state,
        loading: false,
        sucursal: payload,
      };
    case SUCCESS(CREATE_PARAMETRO_CULTIVO):
    case SUCCESS(UPDATE_PARAMETRO_CULTIVO):
      return {
        ...state,
        updating: false,
        sucursal: payload,
      };
    case SUCCESS(DELETE_PARAMETRO_CULTIVO):
      return {
        ...state,
        updating: false,
        sucursal: null,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
