export const GET_GRUPOS_LIST_START = "grupos/GET_GRUPOS_LIST_START"
export const GET_GRUPOS_LIST_SUCCESS = "grupos/GET_GRUPOS_LIST_SUCCESS"
export const GET_GRUPOS_LIST_ERROR = "grupos/GET_GRUPOS_LIST_ERROR"

export const GET_GRUPO_START = "grupos/GET_GRUPO_START"
export const GET_GRUPO_SUCCESS = "grupos/GET_GRUPO_SUCCESS"
export const GET_GRUPO_ERROR = "grupos/GET_GRUPO_ERROR"

export const CREATE_GRUPO_START = 'grupos/CREATE_GRUPO_START'
export const CREATE_GRUPO_SUCCESS = 'grupos/CREATE_GRUPO_SUCCESS'
export const CREATE_GRUPO_ERROR = 'grupos/CREATE_GRUPO_ERROR'

export const UPDATE_GRUPO_START = 'grupos/UPDATE_GRUPO_START'
export const UPDATE_GRUPO_SUCCESS = 'grupos/UPDATE_GRUPO_SUCCESS'
export const UPDATE_GRUPO_ERROR = 'grupos/UPDATE_GRUPO_ERROR'

export const DELETE_GRUPO_START = 'grupos/DELETE_GRUPO_START'
export const DELETE_GRUPO_SUCCESS = 'grupos/DELETE_GRUPO_SUCCESS'
export const DELETE_GRUPO_ERROR = 'grupos/DELETE_GRUPO_ERROR'

export const RESET = 'grupos/RESET'