import * as types from '../types'

const initialState = {
    loading: false,
    mensajeError: null,
    muestra: null,
    notaMuestra: null,
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_MUESTRA_START:
            return {
                ...state,
                mensajeError: null,
                loading: true,
            }
        case types.UPDATE_MUESTRA_ERROR:
            return {
                ...state,
                loading: false,
                mensajeError: payload,
            }
        case types.UPDATE_MUESTRA_SUCCESS:
            return {
                ...state,
                loading: false,
                muestra: payload.data
            }
        default:
            return state;
    }
}