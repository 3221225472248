const SHOW_NOTIFICATION = "SHOW_NOTIFICATION"

export const showNotification = (type, message, title) => ({
  type: SHOW_NOTIFICATION,
  notification:{
    type,
    message,
    title
  }
})

const initialState = {
  lastNotfification: null
}

const notification = (state = initialState, action) => {

  switch(action.type) {
    case SHOW_NOTIFICATION: 
      return {
        lastNotfification: action.notification
      }
    default: return state

  }

}

export default notification;