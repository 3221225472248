import * as types from './types'

const initialState = {
  loading: false,
  list: null,
  datosSuper: null,
  datosGerencia: null,
  datosLaboratorio: null,
  datosVentas: null,
  datosMensajero: null,
  datosOrdenes: null,
  datosTomaMuestra: null,
  error: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_DASHBOARD_START:
    case types.GET_DASHBOARD_SUPER_START:
    case types.GET_DASHBOARD_GERENCIA_START:
    case types.GET_DASHBOARD_LABORATORIO_START:
    case types.GET_DASHBOARD_VENTAS_START:
    case types.GET_DASHBOARD_MENSAJERO_START:
    case types.GET_DASHBOARD_ORDENES_START:
    case types.GET_DASHBOARD_TOMA_MUESTRA_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.GET_DASHBOARD_SUPER_SUCCESS:
      return {
        ...state,
        loading: false,
        datosSuper: payload.data
      }
    case types.GET_DASHBOARD_GERENCIA_SUCCESS:
      return {
        ...state,
        loading: false,
        datosGerencia: payload.data
      }
    case types.GET_DASHBOARD_LABORATORIO_SUCCESS:
      return {
        ...state,
        loading: false,
        datosLaboratorio: payload.data
      }
    case types.GET_DASHBOARD_VENTAS_SUCCESS:
      return {
        ...state,
        loading: false,
        datosVentas: payload.data
      }
    case types.GET_DASHBOARD_MENSAJERO_SUCCESS:
      return {
        ...state,
        loading: false,
        datosMensajero: payload.data
      }
    case types.GET_DASHBOARD_ORDENES_SUCCESS:
      return {
        ...state,
        loading: false,
        datosOrdenes: payload.data
      }
    case types.GET_DASHBOARD_TOMA_MUESTRA_SUCCESS:
      return {
        ...state,
        loading: false,
        datosTomaMuestra: payload.data
      }
    case types.GET_DASHBOARD_ERROR:
    case types.GET_DASHBOARD_SUPER_ERROR:
    case types.GET_DASHBOARD_GERENCIA_ERROR:
    case types.GET_DASHBOARD_LABORATORIO_ERROR:
    case types.GET_DASHBOARD_VENTAS_ERROR:
    case types.GET_DASHBOARD_MENSAJERO_ERROR:
    case types.GET_DASHBOARD_ORDENES_ERROR:
    case types.GET_DASHBOARD_TOMA_MUESTRA_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }

    case types.RESET_DASHBOARD_SUPER:
      return {
        ...state,
        loading: false,
        datosSuper: null
      }
    case types.RESET_DASHBOARD_GERENCIA:
      return {
        ...state,
        loading: false,
        datosGerencia: null
      }
    case types.RESET_DASHBOARD_LABORATORIO:
      return {
        ...state,
        loading: false,
        datosLaboratorio: null
      }
    case types.RESET_DASHBOARD_VENTAS:
      return {
        ...state,
        loading: false,
        datosVentas: null
      }
    case types.RESET_DASHBOARD_MENSAJERO:
      return {
        ...state,
        loading: false,
        datosMensajero: null
      }
    case types.RESET_DASHBOARD_ORDENES:
      return {
        ...state,
        loading: false,
        datosOrdenes: null
      }
    case types.RESET_DASHBOARD_TOMA_MUESTRA:
      return {
        ...state,
        loading: false,
        datosTomaMuestra: null
      }
    default:
      return state
  }
}