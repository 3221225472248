import {
  GET_PROMOCIONES_EXPIRADAS_START,
  GET_PROMOCIONES_EXPIRADAS_SUCCESS,
  GET_PROMOCIONES_EXPIRADAS_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: [],
  error: null
}

const promocionesExpiradas = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_PROMOCIONES_EXPIRADAS_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_PROMOCIONES_EXPIRADAS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      }

    case GET_PROMOCIONES_EXPIRADAS_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default promocionesExpiradas