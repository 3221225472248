
const SAVE_LOCAL_SETTINGS = 'settings/SAVE_LOCAL_SETTINGS'
const RESET = 'settings/RESET'


// Actions
export const saveLocalSettings = (localSettings)=>({type: SAVE_LOCAL_SETTINGS, payload: localSettings});
export const reset = () => ({ type: RESET });

const initialState =  {
    impresoraTicket: false
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOCAL_SETTINGS:
      return action.payload;
    case RESET:
      return {...initialState};
    default:
      return state;
  }
};