import * as types from '../types'

const initialState = {
  isFetching: false,
  payload: null,
  error: null
}

const resetPassword = (state = initialState, action) => {

  const { type, payload, error } = action

  switch(type) {

    case types.RESET_PASSWORD_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case types.RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        isFetching: false,
        payload
      }

    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        error
      }

    default: return state;

  }

}

export default resetPassword