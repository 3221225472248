import {
  GET_RECOLECCIONES_START,
  GET_RECOLECCIONES_SUCCESS,
  GET_RECOLECCIONES_ERROR,
  SET_RECOLECCIONES_DAY_VIEW,
} from '../actions';

const initialState = {
  currentView: new Date(),
  viewSelected: 'day',
  loading: false,
  payload: [],
  error: null,
};

const todas = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_RECOLECCIONES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_RECOLECCIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };

    case SET_RECOLECCIONES_DAY_VIEW:
      return {
        ...state,
        currentView: payload.currentView,
        viewSelected: payload.viewSelected,
      };

    case GET_RECOLECCIONES_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    default:
      return state;
  }
};

export default todas;
