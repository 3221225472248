const getInitialState = () => {
    return  {
        loading: false,
        error: null,
        list: [],
        ultimaCarga: null,
        element: null,
        updating: false,
        errorElement: null,
    }
}
const createTypeElement = name =>{
    return `GET_${name}_START`;
}
const createTypeElementSuccess = name =>{
    return `GET_${name}_SUCCESS`;
}
const createTypeElementError = name =>{
    return `GET_${name}_ERROR`;
}
const createType = name =>{
    return `GET_${name}_LIST`;
}
const createTypeSuccess = name =>{
    return `GET_${name}_LIST_SUCCESS`;
}
const createTypeError = name =>{
    return `GET_${name}_LIST_ERROR`;
}
const createTypeC = name =>{
    return `CREATE_${name}_START`;
}
const createTypeCSuccess = name =>{
    return `CREATE_${name}_SUCCESS`;
}
const createTypeCError = name =>{
    return `CREATE_${name}_ERROR`;
}
export const getActionsCreators = (name)=>{
    const type = name.toUpperCase();
    const methodname = name.replace(/_/g,'');
    return {
        [`get${methodname}`]: ()=>({ type: createType(type)}),
        [`get${methodname}Success`]: (payload)=>({ type: createTypeSuccess(type), payload}),
        [`get${methodname}Error`]: (payload)=>({ type: createTypeError(type), payload}),
        [`create${methodname}Start`]: ()=>({ type: createTypeC(type)}),
        [`create${methodname}Success`]: (payload)=>({ type: createTypeCSuccess(type),payload}),
        [`create${methodname}Error`]: (payload)=>({ type: createTypeCError(type),payload})
    }
}
export const getElementActions = (name)=>{
    const type = name.toUpperCase();
    const methodname = name.replace(/_/g,'');
    return {
        [`get${methodname}`]: ()=>({ type: createTypeElement(type)}),
        [`get${methodname}Success`]: (payload)=>({ type: createTypeElementSuccess(type), payload}),
        [`get${methodname}Error`]: (payload)=>({ type: createTypeElementError(type), payload}),
    }
}

export const createCatalogReducer = name => (state = getInitialState() , action)=>{

    switch (action.type){

        case createType(name):
            return {
                ...state,
                loading: true,
                error: null
            }
        case createTypeSuccess(name):
            return {
                ...state,
                loading: false,
                error: null,
                list: action.payload,
                ultimaCarga: new Date()
            }
        case createTypeError(name):
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case createTypeC(name):
            return {
                ...state,                
                updating: true,
                errorElement:null,
    
            }
        case createTypeCError(name):
            return {
                ...state,
                updating: false,
                errorElement: action.payload
            }
        case createTypeCSuccess(name):
            return {
                ...state,
                updating: false,
                element: action.payload
            }

        default: return state
    }
}