import { REQUEST, SUCCESS, FAILURE } from '../../../helpers/actionTypeCreator';
import { getListActions, getCreateActions, getReadActions, getUpdateActions  } from '../../../helpers/actionCreatorCreator'


const FETCH_TEMPLATES = 'templates/FETCH_TEMPLATES'
const FETCH_TEMPLATE = 'templates/FETCH_TEMPLATE'
const CREATE_TEMPLATE = 'templates/CREATE_TEMPLATE'
const UPDATE_TEMPLATE = 'templates/UPDATE_TEMPLATE'
const DELETE_TEMPLATE = 'templates/DELETE_TEMPLATE'
const RESET = 'templates/RESET'

// Actions
export const { getTemplatesStart, getTemplatesSuccess, getTemplatesError } = getListActions('Templates', FETCH_TEMPLATES);
export const { createTemplateStart, createTemplateSuccess, createTemplateError } = getCreateActions('Template', CREATE_TEMPLATE);
export const { getTemplateStart, getTemplateSuccess, getTemplateError } = getReadActions('Template', FETCH_TEMPLATE);
export const { updateTemplateStart, updateTemplateSuccess, updateTemplateError } = getUpdateActions('Template', UPDATE_TEMPLATE);
export const reset = () => ({ type: RESET });

const initialState = {
  loading: false,
  errorMessage: null,
  list: [],
  template: null,
  updating: false,
  totalItems: 0,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(FETCH_TEMPLATES):
    case REQUEST(FETCH_TEMPLATE):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(CREATE_TEMPLATE):
    case REQUEST(UPDATE_TEMPLATE):
    case REQUEST(DELETE_TEMPLATE):
      return {
        ...state,
        errorMessage: null,
        updating: true,
      };
    case FAILURE(FETCH_TEMPLATES):
    case FAILURE(FETCH_TEMPLATE):
    case FAILURE(CREATE_TEMPLATE):
    case FAILURE(UPDATE_TEMPLATE):
    case FAILURE(DELETE_TEMPLATE):
      return {
        ...state,
        loading: false,
        updating: false,
        errorMessage: action.payload,
      };
    case SUCCESS(FETCH_TEMPLATES):
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        totalItems: action.payload.totalItems,
      };
    case SUCCESS(FETCH_TEMPLATE):
      return {
        ...state,
        loading: false,
        sucursal: action.payload,
      };
    case SUCCESS(CREATE_TEMPLATE):
    case SUCCESS(UPDATE_TEMPLATE):
      return {
        ...state,
        updating: false,
        sucursal: action.payload,
      };
    case SUCCESS(DELETE_TEMPLATE):
      return {
        ...state,
        updating: false,
        sucursal: null,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};