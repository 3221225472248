import { UPDATE_FORM_GENERALES, RESET_FORM_GENERALES, RESET_AGENDA_DATA, INITIALIZE_FORM_GENERALES } from '../actions'

import { CLIENTE_DEFAULT } from '../../../../constants/ordenes'

const initialState = {
  listaPrecio: null,
  vendedor: null,
  cliente: CLIENTE_DEFAULT(),
  convenios: null,
  observaciones: '',
  subtotal: 0,
  descuento: 0,
  importe: 0,
  iva: 0,
  total: 0,
  canal: null,
  verAgenda: false,
  agenda: {
    dia: new Date(),
    tomaEnSucursal: false,
    sucursal: null,
    zipcodeStatus: false,
    direccion: {
      codigoPostal: '',
      colonia: null,
      municipio: null,
      estado: null,
      pais: null,
      noExt: '',
      noInt: '',
      calle: '',
      referencia: '',
    }
  },
  // resetAgenda: false,
  // vistaPagos: false,
  tipoVenta: null,
  ventaOrCotizacionId: null,
  ventaOrCotizacionFolio: null,
  indexContexto: 0,
  convenioAplicado: null
};

const generales = (state = initialState, { type, name, value, initialData }) => {

  switch(type) {

    case UPDATE_FORM_GENERALES:
      return {
        ...state,
        [name]: value
      }

    case INITIALIZE_FORM_GENERALES:
      return initialData

    case RESET_FORM_GENERALES:
      return initialState

    case RESET_AGENDA_DATA:
      return {
        ...state,
        verAgenda: false,
        agenda: {
          dia: new Date(),
          tomaEnSucursal: false,
          sucursal: null,
          zipcodeStatus: false,
          direccion: {
            codigoPostal: '',
            colonia: null,
            municipio: null,
            estado: null,
            pais: null,
            noExt: '',
            noInt: '',
            calle: '',
            referencia: '',
          }
        },
        // resetAgenda: false,
      }

    default: return state

  }

}

export default generales