import * as types from '../types';

const initialState = {
  loading: false,
  mensajeError: null,
  data: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_DETALLE_VENTA_START:
      return {
        ...state,
        mensajeError: null,
        loading: true,
      };
    case types.GET_DETALLE_VENTA_ERROR:
      return {
        ...state,
        loading: false,
        mensajeError: payload.data,
      };
    case types.GET_DETALLE_VENTA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    case types.RESET_DETALLE_VENTA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
