export const GET_FILE_START = 'archivos/GET_FILE_START'
export const GET_FILE_SUCCESS = 'archivos/GET_FILE_SUCCESS'
export const GET_FILE_ERROR = 'archivos/GET_FILE_ERROR'

export const UPLOAD_FILE_START = 'archivos/UPLOAD_FILE_START'
export const UPLOAD_FILE_SUCCESS = 'archivos/UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_ERROR = 'archivos/UPLOAD_FILE_ERROR'

export const GET_TIPOS_FILES_START = 'archivos/GET_TIPOS_FILES_START'
export const GET_TIPOS_FILES_SUCCESS = 'archivos/GET_TIPOS_FILES_SUCCESS'
export const GET_TIPOS_FILES_ERROR = 'archivos/GET_TIPOS_FILES_ERROR'