import * as types from './types';

const initialState = {
  system: {
    host: '',
    port: '',
    username: '',
    password: '',
    protocol: '',
    tls: '',
    auth: '',
    starttls: '',
    sslTrust: '',
  },
  resultados: {
    host: '',
    port: '',
    username: '',
    password: '',
    protocol: '',
    tls: '',
    auth: '',
    starttls: '',
    sslTrust: '',
  },
  loading: false,
  error: null,
  updating: false,
  errorUpdating: null,
};

// Reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_CONFIG_CORREOS:
      return { ...state, loading: true, error: null };
    case types.GET_CONFIG_CORREOS_SUCCESS:
      return { ...state, ...payload, loading: false, error: null };
    case types.GET_CONFIG_CORREOS_ERROR:
      return {
        ...state,
        system: {},
        resultados: {},
        loading: false,
        error: payload,
      };
    case types.UPDATE_CONFIG_CORREOS:
      return { ...state, updating: true, errorUpdating: null };
    case types.UPDATE_CONFIG_CORREOS_SUCCESS:
      return { ...state, ...payload, updating: false, errorUpdating: null };
    case types.UPDATE_CONFIG_CORREOS_ERROR:
      return {
        ...state,
        system: {},
        resultados: {},
        updating: false,
        errorUpdating: payload,
      };
    case types.RESET:
      return { ...initialState };
    default:
      return state;
  }
};
