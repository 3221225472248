import * as types from '../types'

const initialState = {
  isFetching: false,
  payload: null,
  error: null
}

const sendEmail = (state = initialState, action) => {

  const { type, payload, error } = action

  switch(type) {

    case types.SEND_EMAIL_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case types.SEND_EMAIL_SUCCESSFUL:
      return {
        ...state,
        isFetching: false,
        payload
      }

    case types.SEND_EMAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        error
      }

    default: return state

  }

}

export default sendEmail