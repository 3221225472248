import { combineReducers } from 'redux'

import todas from './todas'
import usuarios from './usuarios'
import solicitadas from './solicitadas'
import asignadas from './asignadas'
import realizadas from './realizadas'
import canceladas from './canceladas'
import updateStatus from './updateStatus'

export default combineReducers({
  todas,
  usuarios,
  solicitadas,
  asignadas,
  realizadas,
  canceladas,
  updateStatus
})