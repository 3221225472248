import {
  CANCELAR_ORDEN_START,
  CANCELAR_ORDEN_SUCCESS,
  CANCELAR_ORDEN_ERROR,
} from '../actions';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

const cancelaOrden = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case CANCELAR_ORDEN_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CANCELAR_ORDEN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };

    case CANCELAR_ORDEN_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    default:
      return state;
  }
};

export default cancelaOrden;
