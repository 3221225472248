import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ProtectedRoute from './helpers/authHelper'

import { NotificationContainer } from "./services/react-notifications"

const Views = React.lazy(()=> import("./views"))
const ViewError = React.lazy(() => import('./views/error'))
const ViewLogin = React.lazy(() => import('./views/login/Login'))
const ViewUnauthorized = React.lazy(() => import('./views/unauthorized'))
const ViewSendEmail = React.lazy(() => import('./views/password/SendPasswordEmail'))
const ViewResetPassword = React.lazy(() => import('./views/password/ResetPassword'))

const InvitadosReg= React.lazy(()=> import('./views/pacientes/AddPacienteInvitado'))
const InvitadosValRes= React.lazy(()=> import('./views/socialEvents/ResultsValidation'))

function App() {

  const currentPath = useSelector(state => state.router.location)

  return (
    <>
      <NotificationContainer />
      <Suspense fallback={<div className="loading"/>}>
        <Switch>
          <Route
            path="/login"
            render={props => <ViewLogin {...props} />}
            exact
          />
          <Route
            path="/send-email"
            render={props => <ViewSendEmail {...props} />}
            exact
          />
          <Redirect from="/account/reset/finish" to={`/account/reset/${currentPath.query.key}`}/>
          <Route
            exact
            path="/account/reset/:key"
            render={props => <ViewResetPassword {...props} />}
          />
          <Route
            path="/unauthorized"
            render={props => <ViewUnauthorized {...props} />}
            exact
          />
           <Route
            path="/error"
            render={props => <ViewError { ...props }/>}
            exact
          />
          <Route
            path ="/public/register"
            render={props => <InvitadosReg { ...props}/>}
            exact
          />
          <Route
            path ="/public/validation"
            render={props => <InvitadosValRes { ...props}/>}
            exact
          />
          <ProtectedRoute
            path="/"
            component={Views}
          />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;