
const initialState = {
    loading: false,
    error: null,
    list: [],
    totalItems: 0,
    formula: null,
    updating: false,
    updateSuccess: false
}

export const GET_FORMULA_LIST = "FORMULA/GET_FORMULA_LIST"
export const GET_FORMULA_LIST_SUCCESS = "FORMULA/GET_FORMULA_LIST_SUCCESS"
export const GET_FORMULA_LIST_ERROR = "FORMULA/GET_FORMULA_LIST_ERROR"

export const GET_FORMULA = "FORMULA/GET_FORMULA"
export const GET_FORMULA_SUCCESS = "FORMULA/GET_FORMULA_SUCCESS"
export const GET_FORMULA_ERROR = "FORMULA/GET_FORMULA_ERROR"

export const CREATE_FORMULA_START = 'FORMULA/CREATE_FORMULA_START'
export const CREATE_FORMULA_SUCCESS = 'FORMULA/CREATE_FORMULA_SUCCESS'
export const CREATE_FORMULA_ERROR = 'FORMULA/CREATE_FORMULA_ERROR'

export const UPDATE_FORMULA_START = 'FORMULA/UPDATE_FORMULA_START'
export const UPDATE_FORMULA_SUCCESS = 'FORMULA/UPDATE_FORMULA_SUCCESS'
export const UPDATE_FORMULA_ERROR = 'FORMULA/UPDATE_FORMULA_ERROR'

export const DELETE_FORMULA_START = 'FORMULA/DELETE_FORMULA_START'
export const DELETE_FORMULA_SUCCESS = 'FORMULA/DELETE_FORMULA_SUCCESS'
export const DELETE_FORMULA_ERROR = 'FORMULA/DELETE_FORMULA_ERROR'

export const RESET = 'FORMULA/RESET'
export const RESET_FORMULA = 'FORMULA/RESET_FORMULA'

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_FORMULA_LIST:
        case GET_FORMULA:
            return {
                ...state,
                loading: true,
                error: null,
                updateSuccess: false
            }

        case CREATE_FORMULA_START:
        case UPDATE_FORMULA_START:
        case DELETE_FORMULA_START:
            return {
                ...state,
                mensajeError: null,
                updateSuccess: false,
                updating: true
            }
        case GET_FORMULA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                formula: payload.data,
            }
        case GET_FORMULA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: payload.data,
                totalItems: payload.totalItems
            }
        case CREATE_FORMULA_SUCCESS:
        case UPDATE_FORMULA_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                formula: payload.data
            }
        case DELETE_FORMULA_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                formula: initialState.formula
            }
        case GET_FORMULA_LIST_ERROR:
        case GET_FORMULA_ERROR:
        case CREATE_FORMULA_ERROR:
        case UPDATE_FORMULA_ERROR:
        case DELETE_FORMULA_ERROR:
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                error: payload
            }
        case RESET:
            return {
                ...initialState,
            }
        case RESET_FORMULA:
            return {
                ...state,
                formula: initialState.formula
            }

        default:
            return state;
    }
}

export const getFormulas = () => ({
    type: GET_FORMULA_LIST
})
export const getFormulasSuccess = payload => ({
    type: GET_FORMULA_LIST_SUCCESS,
    payload
})
export const getFormulasError = payload => ({
    type: GET_FORMULA_LIST_ERROR,
    payload
})

export const getFormulaStart = () => ({
    type: GET_FORMULA
})
export const getFormulaSuccess = payload => ({
    type: GET_FORMULA_SUCCESS,
    payload
})
export const getFormulaError = payload => ({
    type: GET_FORMULA_ERROR,
    payload
})

export const createFormulaStart = () => ({
    type: CREATE_FORMULA_START
})
export const createFormulaSuccess = (payload) => ({
    type: CREATE_FORMULA_SUCCESS,
    payload
})
export const createFormulaError = (payload) => ({
    type: CREATE_FORMULA_ERROR,
    payload
})

export const updateFormulaStart = () => ({
    type: UPDATE_FORMULA_START
})
export const updateFormulaSuccess = (payload) => ({
    type: UPDATE_FORMULA_SUCCESS,
    payload
})
export const updateFormulaError = (payload) => ({
    type: UPDATE_FORMULA_ERROR,
    payload
})

export const deleteFormulaStart = () => ({
    type: DELETE_FORMULA_START
})
export const deleteFormulaSuccess = payload => ({
    type: DELETE_FORMULA_SUCCESS,
    payload
})
export const deleteFormulaError = payload => ({
    type: DELETE_FORMULA_ERROR,
    payload
})

export const reset = () => ({
    type: RESET
})

export const resetFormula = () => ({
    type: RESET_FORMULA
})