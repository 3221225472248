import { combineReducers } from 'redux'

import Password from './password.reducer'
import Settings from './settings.reducer'
import Asignaciones from './asignaciones'

export default combineReducers({
    Asignaciones,
    Password,
    Settings
})