import moment from 'moment';
import * as types from '../types';

const initialState = {
  loading: false,
  data: [],
  error: null,
  ordenesMuyUrgentes: null,
  ordenesUrgentes: null,
  ordenesNormales: null,
  ordenesAtrasadas: null,
};
const sorter = (a, b) => {
  const isSamefechaEntrega = moment(
    a.fechaEntrega,
    'DD MMMM YYYY HH:mm',
    'es'
  ).diff(moment(b.fechaEntrega, 'DD MMMM YYYY HH:mm', 'es'));

  return isSamefechaEntrega === 0 ? a.id - b.id : isSamefechaEntrega;
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_DASHBOARD_LIBERACION_ESTUDIOS_START:
    case types.HOT_UPDATE_DASHBOARD_LIBERACION_ESTUDIOS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.GET_DASHBOARD_LIBERACION_ESTUDIOS_ERROR:
    case types.HOT_UPDATE_DASHBOARD_LIBERACION_ESTUDIOS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.data,
      };
    case types.GET_DASHBOARD_LIBERACION_ESTUDIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload?.data,
        ordenesMuyUrgentes: payload?.data?.muyUrgentes
          ?.map((elemento) => elemento.ordenes)
          .reduce((acc, act) => acc.concat(act), [])
          .sort(sorter),
        ordenesUrgentes: payload?.data?.urgentes
          ?.map((elemento) => elemento.ordenes)
          .reduce((acc, act) => acc.concat(act), [])
          .sort(sorter),
        ordenesNormales: payload?.data?.normales
          ?.map((elemento) => elemento.ordenes)
          .reduce((acc, act) => acc.concat(act), [])
          .sort(sorter),
        ordenesAtrasadas: payload?.data?.atrasadas
          ?.map((elemento) => elemento.ordenes)
          .reduce((acc, act) => acc.concat(act), [])
          .sort(sorter),
      };

    case types.HOT_UPDATE_DASHBOARD_LIBERACION_ESTUDIOS_SUCCESS:
      return {
        ...state,
        ...payload.data,
        loading: false,
      };
    case types.RESET_DASHBOARD_LIBERACION_ESTUDIOS:
      return {
        ...state,
        loading: false,
        data: null,
        error: null,
        ordenesMuyUrgentes: null,
        ordenesUrgentes: null,
        ordenesNormales: null,
        ordenesAtrasadas: null,
      };
    default:
      return state;
  }
};
