import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({
  component: Component,
  roles = undefined,
  ...rest
}) => {
  const { token, user: currentUser } = useSelector((state) => state.login);
  const setComponent = (props) => {
    const { location } = props;

    if (!token) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }
    if (!roles || roles.some((r) => currentUser.authorities.includes(r))) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/unauthorized',
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={setComponent} />;
};
export function ComponenteValidacionRol({
  roles = [],
  children,
  fallback: Fallback,
}) {
  const allowRender = useUserAuthorities(roles);

  if (allowRender) {
    return children;
  }
  if (Fallback) {
    return <Fallback />;
  }
  return null;
}

export function useUserAuthorities(roles = []) {
  const { user: currentUser } = useSelector((state) => state.login);
  const allowed = useMemo(
    () =>
      Array.isArray(roles)
        ? roles.some((r) => currentUser.authorities.includes(r))
        : false,
    [roles, currentUser]
  );
  return allowed;
}

export default ProtectedRoute;
