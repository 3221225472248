
export const GET_DASHBOARD_START = "DASHBOARD/START"
export const GET_DASHBOARD_SUCCESS = "DASHBOARD/SUCCESS"
export const GET_DASHBOARD_ERROR = "DASHBOARD/ERROR"

export const GET_DASHBOARD_SUPER_START = "dashboardSuper/START"
export const GET_DASHBOARD_GERENCIA_START = "dashboardGerencia/START"
export const GET_DASHBOARD_LABORATORIO_START = "dashboardLaboratorio/START"
export const GET_DASHBOARD_VENTAS_START = "dashboardventas/START"
export const GET_DASHBOARD_MENSAJERO_START = "dashboardMensajero/START"
export const GET_DASHBOARD_ORDENES_START = 'dashboardOrdenes/START'
export const GET_DASHBOARD_TOMA_MUESTRA_START = 'dashboardTomaMuestra/START'

export const GET_DASHBOARD_SUPER_ERROR = "dashboardSuper/ERROR"
export const GET_DASHBOARD_GERENCIA_ERROR = "dashboardGerencia/ERROR"
export const GET_DASHBOARD_LABORATORIO_ERROR = "dashboardLaboratorio/ERROR"
export const GET_DASHBOARD_VENTAS_ERROR = "dashboardventas/ERROR"
export const GET_DASHBOARD_MENSAJERO_ERROR = "dashboardMensajero/ERROR"
export const GET_DASHBOARD_ORDENES_ERROR = 'dashboardOrdenes/ERROR'
export const GET_DASHBOARD_TOMA_MUESTRA_ERROR = 'dashboardTomaMuestra/ERROR'

export const GET_DASHBOARD_SUPER_SUCCESS = "dashboardSuper/SUCCESS"
export const GET_DASHBOARD_GERENCIA_SUCCESS = "dashboardGerencia/SUCCESS"
export const GET_DASHBOARD_LABORATORIO_SUCCESS = "dashboardLaboratorio/SUCCESS"
export const GET_DASHBOARD_VENTAS_SUCCESS = "dashboardventas/SUCCESS"
export const GET_DASHBOARD_MENSAJERO_SUCCESS = "dashboardMensajero/SUCCESS"
export const GET_DASHBOARD_ORDENES_SUCCESS = 'dashboardOrdenes/SUCCESS'
export const GET_DASHBOARD_TOMA_MUESTRA_SUCCESS = 'dashboardTomaMuestra/SUCCESS'

export const RESET_DASHBOARD_SUPER = "dashboardSuper/RESET"
export const RESET_DASHBOARD_GERENCIA = "dashboardGerencia/RESET"
export const RESET_DASHBOARD_LABORATORIO = "dashboardLaboratorio/RESET"
export const RESET_DASHBOARD_VENTAS = "dashboardventas/RESET"
export const RESET_DASHBOARD_MENSAJERO = "dashboardMensajero/RESET"
export const RESET_DASHBOARD_ORDENES = 'dashboardOrdenes/RESET'
export const RESET_DASHBOARD_TOMA_MUESTRA = 'dashboardTomaMuestra/RESET'

export const GET_DASHBOARD_LIBERACION_ESTUDIOS_START = 'dashboardLiberacionEstudios/START'
export const GET_DASHBOARD_LIBERACION_ESTUDIOS_ERROR = 'dashboardLiberacionEstudios/ERROR'
export const GET_DASHBOARD_LIBERACION_ESTUDIOS_SUCCESS = 'dashboardLiberacionEstudios/SUCCESS'
export const HOT_UPDATE_DASHBOARD_LIBERACION_ESTUDIOS_START = 'dashboardLiberacionEstudios/HOT_UPDATE_START'
export const HOT_UPDATE_DASHBOARD_LIBERACION_ESTUDIOS_ERROR = 'dashboardLiberacionEstudios/HOT_UPDATE_ERROR'
export const HOT_UPDATE_DASHBOARD_LIBERACION_ESTUDIOS_SUCCESS = 'dashboardLiberacionEstudios/HOT_UPDATE_SUCCESS'
export const RESET_DASHBOARD_LIBERACION_ESTUDIOS = 'dashboardLiberacionEstudios/RESET'


export const GET_DASHBOARD_ENTREGAS_ESTUDIOS_START = 'dashboardEntregasEstudios/START'
export const GET_DASHBOARD_ENTREGAS_ESTUDIOS_ERROR = 'dashboardEntregasEstudios/ERROR'
export const GET_DASHBOARD_ENTREGAS_ESTUDIOS_SUCCESS = 'dashboardEntregasEstudios/SUCCESS'
export const SET_DASHBOARD_ENTREGAS_ESTUDIOS_LISTS_START = 'dashboardEntregasEstudios/SET_LISTS_START'
export const SET_DASHBOARD_ENTREGAS_ESTUDIOS_LISTS_ERROR = 'dashboardEntregasEstudios/SET_LISTS_ERROR'
export const SET_DASHBOARD_ENTREGAS_ESTUDIOS_LISTS_SUCCESS = 'dashboardEntregasEstudios/SET_LISTS_SUCCESS'
export const GET_DASHBOARD_ENTREGAS_ESTUDIOS_LISTS_START = 'dashboardEntregasEstudios/GET_LISTS_START'
export const GET_DASHBOARD_ENTREGAS_ESTUDIOS_LISTS_ERROR = 'dashboardEntregasEstudios/GET_LISTS_ERROR'
export const GET_DASHBOARD_ENTREGAS_ESTUDIOS_LISTS_SUCCESS = 'dashboardEntregasEstudios/GET_LISTS_SUCCESS'
export const HOT_UPDATE_DASHBOARD_ENTREGAS_ESTUDIOS_START = 'dashboardEntregasEstudios/HOT_UPDATE_START'
export const HOT_UPDATE_DASHBOARD_ENTREGAS_ESTUDIOS_ERROR = 'dashboardEntregasEstudios/HOT_UPDATE_ERROR'
export const HOT_UPDATE_DASHBOARD_ENTREGAS_ESTUDIOS_SUCCESS = 'dashboardEntregasEstudios/HOT_UPDATE_SUCCESS'
export const RESET_DASHBOARD_ENTREGAS_ESTUDIOS = 'dashboardEntregasEstudios/RESET'