const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  item: null,
  updating: false,
  itemError: null,
  updateSuccesss: false,
};

export const GET_CLIENTES_LIST = 'CLIENTES/GET_CLIENTES_LIST';
export const GET_CLIENTES_LIST_SUCCESS = 'CLIENTES/GET_CLIENTES_LIST_SUCCESS';
export const GET_CLIENTES_LIST_ERROR = 'CLIENTES/GET_CLIENTES_LIST_ERROR';

export const GET_CLIENTE = 'CLIENTE/GET_CLIENTE';
export const GET_CLIENTE_SUCCESS = 'CLIENTE/GET_CLIENTE_SUCCESS';
export const GET_CLIENTE_ERROR = 'CLIENTE/GET_CLIENTE_ERROR';

export const CREATE_CLIENTE = 'CLIENTE/CREATE_CLIENTE';
export const CREATE_CLIENTE_SUCCESS = 'CLIENTE/CREATE_CLIENTE_SUCCESS';
export const CREATE_CLIENTE_ERROR = 'CLIENTE/CREATE_CLIENTE_ERROR';

export const UPDATE_CLIENTE = 'CLIENTE/UPDATE_CLIENTE';
export const UPDATE_CLIENTE_SUCCESS = 'CLIENTE/UPDATE_CLIENTE_SUCCESS';
export const UPDATE_CLIENTE_ERROR = 'CLIENTE/UPDATE_CLIENTE_ERROR';

export const RESET = 'CLIENTE/RESET';
export const RESET_CLIENTE = 'CLIENTE/RESET_CLIENTE';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CLIENTES_LIST:
      return {
        ...state,
        error: null,
        loading: true,
        updateSuccesss: false,
      };
    case GET_CLIENTE:
    case CREATE_CLIENTE:
    case UPDATE_CLIENTE:
      return {
        ...state,
        itemError: null,
        updateSuccesss: false,
        updating: true,
      };
    case GET_CLIENTES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_CLIENTE_ERROR:
    case CREATE_CLIENTE_ERROR:
    case UPDATE_CLIENTE_ERROR:
      return {
        ...state,
        updating: false,
        updateSuccesss: false,
        itemError: payload,
      };
    case GET_CLIENTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: parseInt(payload.headers['x-total-count'], 10),
      };
    case GET_CLIENTE_SUCCESS:
    case CREATE_CLIENTE_SUCCESS:
    case UPDATE_CLIENTE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccesss: true,
        item: payload.data,
      };
    case RESET:
      return {
        ...initialState,
      };
    case RESET_CLIENTE:
      return {
        ...state,
        item: initialState.paciente,
      };
    default:
      return state;
  }
};

export const getClientes = () => ({
  type: GET_CLIENTES_LIST,
});
export const getClientesSuccess = (payload) => ({
  type: GET_CLIENTES_LIST_SUCCESS,
  payload,
});
export const getClientesError = (payload) => ({
  type: GET_CLIENTES_LIST_ERROR,
  payload,
});

export const getCliente = () => ({
  type: GET_CLIENTE,
});
export const getClienteSuccess = (payload) => ({
  type: GET_CLIENTE_SUCCESS,
  payload,
});
export const getClienteError = (payload) => ({
  type: GET_CLIENTE_ERROR,
  payload,
});

export const createClienteStart = () => ({
  type: CREATE_CLIENTE,
});
export const createClienteSuccess = (payload) => ({
  type: CREATE_CLIENTE_SUCCESS,
  payload,
});
export const createClienteError = (payload) => ({
  type: CREATE_CLIENTE_ERROR,
  payload,
});

export const updateClienteStart = () => ({
  type: UPDATE_CLIENTE,
});
export const updateClienteSuccess = (payload) => ({
  type: UPDATE_CLIENTE_SUCCESS,
  payload,
});
export const updateClienteError = (payload) => ({
  type: UPDATE_CLIENTE_ERROR,
  payload,
});

export const reset = () => ({
  type: RESET,
});

export const resetCliente = () => ({
  type: RESET_CLIENTE,
});
