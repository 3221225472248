import { REQUEST, SUCCESS, FAILURE } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const FETCH_SUCURSALES = 'sucursales/FETCH_SUCURSALES';
const FETCH_SUCURSAL = 'sucursales/FETCH_SUCURSAL';
const CREATE_SUCURSAL = 'sucursales/CREATE_SUCURSAL';
const UPDATE_SUCURSAL = 'sucursales/UPDATE_SUCURSAL';
const DELETE_SUCURSAL = 'sucursales/DELETE_SUCURSAL';
const RESET = 'sucursales/RESET';

// Actions
export const {
  getSucursalesStart,
  getSucursalesSuccess,
  getSucursalesError,
} = getListActions('Sucursales', FETCH_SUCURSALES);
export const {
  createSucursalStart,
  createSucursalSuccess,
  createSucursalError,
} = getCreateActions('Sucursal', CREATE_SUCURSAL);
export const {
  getSucursalStart,
  getSucursalSuccess,
  getSucursalError,
} = getReadActions('Sucursal', FETCH_SUCURSAL);
export const {
  updateSucursalStart,
  updateSucursalSuccess,
  updateSucursalError,
} = getUpdateActions('Sucursal', UPDATE_SUCURSAL);
export const reset = () => ({ type: RESET });

const initialState = {
  loading: false,
  errorMessage: null,
  list: [],
  totalItems: 0,
  sucursal: null,
  updating: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(FETCH_SUCURSALES):
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case REQUEST(FETCH_SUCURSAL):
    case REQUEST(CREATE_SUCURSAL):
    case REQUEST(UPDATE_SUCURSAL):
    case REQUEST(DELETE_SUCURSAL):
      return {
        ...state,
        errorMessage: null,
        updating: true,
      };
    case FAILURE(FETCH_SUCURSALES):
    case FAILURE(FETCH_SUCURSAL):
    case FAILURE(CREATE_SUCURSAL):
    case FAILURE(UPDATE_SUCURSAL):
    case FAILURE(DELETE_SUCURSAL):
      return {
        ...state,
        loading: false,
        updating: false,
        errorMessage: action.payload,
      };
    case SUCCESS(FETCH_SUCURSALES):
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        totalItems: action.payload.totalItems,
      };
    case SUCCESS(FETCH_SUCURSAL):
    case SUCCESS(CREATE_SUCURSAL):
    case SUCCESS(UPDATE_SUCURSAL):
      return {
        ...state,
        updating: false,
        sucursal: action.payload,
      };
    case SUCCESS(DELETE_SUCURSAL):
      return {
        ...state,
        updating: false,
        sucursal: null,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
