import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const GET_ANTIBIOTICOS = 'antibioticos/GET_ANTIBIOTICOS';
const GET_ANTIBIOTICO = 'antibioticos/GET_ANTIBIOTICO';
const CREATE_ANTIBIOTICO = 'antibioticos/CREATE_ANTIBIOTICO';
const UPDATE_ANTIBIOTICO = 'antibioticos/UPDATE_ANTIBIOTICO';

export const {
  getAntibioticosStart,
  getAntibioticosSuccess,
  getAntibioticosError,
} = getListActions('Antibioticos', GET_ANTIBIOTICOS);

export const {
  getAntibioticoStart,
  getAntibioticoSuccess,
  getAntibioticoError,
} = getReadActions('Antibiotico', GET_ANTIBIOTICO);

export const {
  createAntibioticoStart,
  createAntibioticoSuccess,
  createAntibioticoError,
} = getCreateActions('Antibiotico', CREATE_ANTIBIOTICO);

export const {
  updateAntibioticoStart,
  updateAntibioticoSuccess,
  updateAntibioticoError,
} = getUpdateActions('Antibiotico', UPDATE_ANTIBIOTICO);

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(GET_ANTIBIOTICOS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(GET_ANTIBIOTICO):
    case REQUEST(CREATE_ANTIBIOTICO):
    case REQUEST(UPDATE_ANTIBIOTICO):
      return {
        ...state,
        updating: true,
        error: null,
      };
    case FAILURE(GET_ANTIBIOTICOS):
    case FAILURE(GET_ANTIBIOTICO):
    case FAILURE(CREATE_ANTIBIOTICO):
    case FAILURE(UPDATE_ANTIBIOTICO):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case SUCCESS(GET_ANTIBIOTICOS):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(GET_ANTIBIOTICO):
    case SUCCESS(CREATE_ANTIBIOTICO):
    case SUCCESS(UPDATE_ANTIBIOTICO):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
