export const GET_MEDICOS_LIST_START = "medicos/GET_MEDICOS_LIST_START"
export const GET_MEDICOS_LIST_SUCCESS = "medicos/GET_MEDICOS_LIST_SUCCESS"
export const GET_MEDICOS_LIST_ERROR = "medicos/GET_MEDICOS_LIST_ERROR"

export const GET_MEDICO_START = "medicos/GET_MEDICO_START"
export const GET_MEDICO_SUCCESS = "medicos/GET_MEDICO_SUCCESS"
export const GET_MEDICO_ERROR = "medicos/GET_MEDICO_ERROR"

export const CREATE_MEDICO_START = 'medicos/CREATE_MEDICO_START'
export const CREATE_MEDICO_SUCCESS = 'medicos/CREATE_MEDICO_SUCCESS'
export const CREATE_MEDICO_ERROR = 'medicos/CREATE_MEDICO_ERROR'

export const UPDATE_MEDICO_START = 'medicos/UPDATE_MEDICO_START'
export const UPDATE_MEDICO_SUCCESS = 'medicos/UPDATE_MEDICO_SUCCESS'
export const UPDATE_MEDICO_ERROR = 'medicos/UPDATE_MEDICO_ERROR'

export const DELETE_MEDICO_START = 'medicos/DELETE_MEDICO_START'
export const DELETE_MEDICO_SUCCESS = 'medicos/DELETE_MEDICO_SUCCESS'
export const DELETE_MEDICO_ERROR = 'medicos/DELETE_MEDICO_ERROR'

export const RESET = 'medicos/RESET'
export const RESET_MEDICO = 'medicos/RESET_MEDICO'