const CHANGE_BUTTON_LEFT = 'CHANGE_BUTTON_LEFT';
const CHANGE_BUTTON_RIGHT = 'CHANGE_BUTTON_RIGHT';
const CLEAR_BUTTON_LEFT = 'CLEAR_BUTTON_LEFT';
const CLEAR_BUTTON_RIGHT = 'CLEAR_BUTTON_RIGHT';

export const setLeftButton = (payload) => ({
  type: CHANGE_BUTTON_LEFT,
  payload,
});
export const setRightButton = (payload) => ({
  type: CHANGE_BUTTON_RIGHT,
  payload,
});
export const clearLeft = ()=>({type: CLEAR_BUTTON_LEFT})
export const clearRight = ()=>({type: CLEAR_BUTTON_RIGHT})

const initialState = {
  right: null,
  left: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_BUTTON_LEFT:
      return {
        ...state,
        left: action.payload,
      };
    case CHANGE_BUTTON_RIGHT:
      return {
        ...state,
        right: action.payload,
      };
    case CLEAR_BUTTON_LEFT:
      return {
        ...state,
        left: null,
      };
    case CLEAR_BUTTON_RIGHT:
      return {
        ...state,
        right: null,
      };
    default:
      return state;
  }
};
