import * as types from './types';

const initialState = {
  loading: false,
  mensajeErrorLoading: null,
  list: null,
  totalItems: 0,

  item: null,
  updating: false,
  mensajeErrorUpdating: null,
  updateSuccess: false,

  mensajeErrorPB: null,
  loadingPB: false,
  listaPB: null,
  totalItemsPB: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PRECIOS_BASE_START:
      return {
        ...state,
        mensajeErrorPB: null,
        loadingPB: false,
      };
    case types.GET_PLANTILLAS_PRECIOS_LIST_START:
      return {
        ...state,
        mensajeErrorLoading: null,
        loading: true,
        updateSuccess: false,
      };
    case types.GET_PLANTILLA_START:
    case types.CREATE_PLANTILLA_START:
    case types.UPDATE_PLANTILLA_START:
      return {
        ...state,
        mensajeErrorUpdating: null,
        updateSuccess: false,
        updating: true,
      };
    case types.GET_PRECIOS_BASE_ERROR:
      return {
        ...state,
        loadingPB: false,
        mensajeErrorPB: payload,
      };
    case types.GET_PLANTILLAS_PRECIOS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        mensajeErrorLoading: payload,
      };
    case types.GET_PLANTILLA_ERROR:
    case types.CREATE_PLANTILLA_ERROR:
    case types.UPDATE_PLANTILLA_ERROR:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        mensajeErrorUpdating: payload,
      };
    case types.GET_PRECIOS_BASE_SUCCESS:
      return {
        ...state,
        mensajeErrorPB: null,
        loadingPB: false,
        listaPB: payload.data,
      };
    case types.GET_PLANTILLAS_PRECIOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: parseInt(payload.headers['x-total-count'], 10),
      };
    case types.GET_PLANTILLA_SUCCESS:
    case types.CREATE_PLANTILLA_SUCCESS:
    case types.UPDATE_PLANTILLA_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        item: payload.data,
      };
    case types.RESET_ALL:
      return {
        ...initialState,
      };
    case types.RESET_PLANTILLA:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        item: initialState.item,
        mensajeErrorUpdating: null,
      };
    default:
      return state;
  }
};
