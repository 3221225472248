import {
  GET_RECOLECCIONES_ASIGNADAS_START,
  GET_RECOLECCIONES_ASIGNADAS_SUCCESS,
  GET_RECOLECCIONES_ASIGNADAS_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: [],
  error: null
}

const asignadas = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_RECOLECCIONES_ASIGNADAS_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_RECOLECCIONES_ASIGNADAS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case GET_RECOLECCIONES_ASIGNADAS_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default asignadas