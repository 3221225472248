import { FAILURE, REQUEST, SUCCESS } from '../../../helpers/actionTypeCreator';
/// Types
export const ACTION_TYPES = {
  UPDATE_ACCOUNT: 'account/UPDATE_ACCOUNT',
  RESET: 'account/RESET',
};

/// Actions
export const saveAccountSettingsStart = () => async (dispatch) => {
  await dispatch({
    type: REQUEST(ACTION_TYPES.UPDATE_ACCOUNT),
  });
};
export const saveAccountSettingsSuccess = (account) => async (dispatch) => {
  await dispatch({
    type: SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT),
    payload: account,
  });
};
export const saveAccountSettingsError = (payload) => async (dispatch) => {
  await dispatch({
    type: SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT),
    payload,
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
