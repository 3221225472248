export const GET_AUTORIZACIONES_LIST_START = "autorizaciones/GET_AUTORIZACIONES_LIST_START"
export const GET_AUTORIZACIONES_LIST_SUCCESS = "autorizaciones/GET_AUTORIZACIONES_LIST_SUCCESS"
export const GET_AUTORIZACIONES_LIST_ERROR = "autorizaciones/GET_AUTORIZACIONES_LIST_ERROR"

export const GET_AUTORIZACION_START = "autorizaciones/GET_AUTORIZACION_START"
export const GET_AUTORIZACION_SUCCESS = "autorizaciones/GET_AUTORIZACION_SUCCESS"
export const GET_AUTORIZACION_ERROR = "autorizaciones/GET_AUTORIZACION_ERROR"

export const CREATE_AUTORIZACION_START = 'autorizaciones/CREATE_AUTORIZACION_START'
export const CREATE_AUTORIZACION_SUCCESS = 'autorizaciones/CREATE_AUTORIZACION_SUCCESS'
export const CREATE_AUTORIZACION_ERROR = 'autorizaciones/CREATE_AUTORIZACION_ERROR'

export const UPDATE_AUTORIZACION_START = 'autorizaciones/UPDATE_AUTORIZACION_START'
export const UPDATE_AUTORIZACION_SUCCESS = 'autorizaciones/UPDATE_AUTORIZACION_SUCCESS'
export const UPDATE_AUTORIZACION_ERROR = 'autorizaciones/UPDATE_AUTORIZACION_ERROR'

export const DELETE_AUTORIZACION_START = 'autorizaciones/DELETE_AUTORIZACION_START'
export const DELETE_AUTORIZACION_SUCCESS = 'autorizaciones/DELETE_AUTORIZACION_SUCCESS'
export const DELETE_AUTORIZACION_ERROR = 'autorizaciones/DELETE_AUTORIZACION_ERROR'

export const RESET = 'autorizaciones/RESET'
export const RESET_AUTORIZACION = 'autorizaciones/RESET_AUTORIZACION'