const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
};

export const GET_CONVENIOS_LIST = 'CONVENIOS/GET_CONVENIOS_LIST';
export const GET_CONVENIOS_LIST_SUCCESS =
  'CONVENIOS/GET_CONVENIOS_LIST_SUCCESS';
export const GET_CONVENIOS_LIST_ERROR = 'CONVENIOS/GET_CONVENIOS_LIST_ERROR';
export const GET_CONVENIO = 'CONVENIO/GET_CONVENIO';
export const GET_CONVENIO_SUCCESS = 'CONVENIO/GET_CONVENIO_SUCCESS';
export const GET_CONVENIO_ERROR = 'CONVENIO/GET_CONVENIO_ERROR';
export const RESET_CONVENIO = 'CONVENIO/RESET_CONVENIO';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONVENIOS_LIST:
    case GET_CONVENIO:
      return {
        ...state,
        loading: true,
        error: null,
        updateSuccesss: false,
      };

    case GET_CONVENIOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: parseInt(payload.headers['x-total-count'], 10),
      };

    case GET_CONVENIO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        element: payload,
      };

    case GET_CONVENIO_ERROR:
    case GET_CONVENIOS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case RESET_CONVENIO:
      return {
        ...state,
        element: null,
      };

    default:
      return state;
  }
};

export const getConvenios = () => ({
  type: GET_CONVENIOS_LIST,
});

export const getConveniosSuccess = (payload) => ({
  type: GET_CONVENIOS_LIST_SUCCESS,
  payload,
});

export const getConveniosError = (payload) => ({
  type: GET_CONVENIOS_LIST_ERROR,
  payload,
});

export const getConvenio = () => ({
  type: GET_CONVENIO,
});

export const getConvenioSuccess = (payload) => ({
  type: GET_CONVENIO_SUCCESS,
  payload,
});

export const getConvenioError = (payload) => ({
  type: GET_CONVENIO_ERROR,
  payload,
});

export const resetConvenio = () => ({
  type: RESET_CONVENIO,
});
