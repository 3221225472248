import {
  GET_PRODUCTOS_KIT_START,
  GET_PRODUCTOS_KIT_SUCCESS,
  GET_PRODUCTOS_KIT_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: [],
  error: null
}

const kits = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_PRODUCTOS_KIT_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_PRODUCTOS_KIT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case GET_PRODUCTOS_KIT_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }
  
}

export default kits