import {
  UPDATE_FORM_DATA,
  RESET_FORM_DATA,
  INITIALIZE_FORM_DATA,
  DELETE_ORDEN_BY_INDEX,
  ADD_ORDEN,
  UPDATE_ORDEN_VALUE_BY_INDEX_AND_NAME,
  UPDATE_ALL_ORDEN_VALUE_BY_NAME,
  UPDATE_ORDEN_ESTUDIOS_BY_INDEX_AND_NAME,
} from '../actions';
import { ORDEN } from '../../../../constants/ordenes';

const initialState = {
  ordenes: [ORDEN()],
};

const ordenPorPaciente = (
  state = initialState,
  { type, value, index, name, initialData }
) => {
  switch (type) {
    case UPDATE_FORM_DATA:
      return {
        ordenes: value,
      };

    case DELETE_ORDEN_BY_INDEX:
      return {
        ordenes: state.ordenes.filter((_, idx) => idx !== index),
      };

    case ADD_ORDEN:
      return {
        ordenes: [...state.ordenes, value],
      };
    case UPDATE_ORDEN_VALUE_BY_INDEX_AND_NAME:
      return {
        ordenes: state.ordenes.map((orden, idx) =>
          idx !== index ? orden : { ...orden, [name]: value }
        ),
      };
    case UPDATE_ORDEN_ESTUDIOS_BY_INDEX_AND_NAME: {
      const data = {
        ordenes: state.ordenes.map((orden, idx) =>
          idx !== index
            ? orden
            : {
                ...orden,
                estudios: orden.estudios.map(({ id, idRes, ...rest }) =>
                  value.has(idRes || id)
                    ? { id, idRes, ...rest, ...value.get(idRes || id) }
                    : { id, idRes, ...rest }
                ),
              }
        ),
      };
      return data;
    }
    case UPDATE_ALL_ORDEN_VALUE_BY_NAME:
      return {
        ordenes: state.ordenes.map((orden) => ({ ...orden, [name]: value })),
      };
    case INITIALIZE_FORM_DATA:
      return initialData;

    case RESET_FORM_DATA:
      return { ordenes: [ORDEN()] };

    default:
      return state;
  }
};

export default ordenPorPaciente;
