import {
  DELETE_COMENTARIO_MEDICO_START,
  DELETE_COMENTARIO_MEDICO_SUCCESS,
  DELETE_COMENTARIO_MEDICO_ERROR
} from '../../actions'

const initialState = {
  loading: false,
  payload: null,
  error: null
}

const medico = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case DELETE_COMENTARIO_MEDICO_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case DELETE_COMENTARIO_MEDICO_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case DELETE_COMENTARIO_MEDICO_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default medico