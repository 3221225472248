import * as types from '../types'

const initialState = {
    loading: false,
    mensajeError: null,
    cat: null,
    ums: null,
    totalItems: 0,
    um: {},
    updating: false,
    updateSuccess: false
}

const unidadesMedidas = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_UNIDADES_MEDIDAS_LIST_START:
            return {
                ...state,
                cat: payload,
                mensajeError: null,
                loading: true,
                updateSuccess: false
            }
        case types.GET_UNIDAD_MEDIDA_START:
        case types.CREATE_UNIDAD_MEDIDA_START:
        case types.UPDATE_UNIDAD_MEDIDA_START:
        case types.DELETE_UNIDAD_MEDIDA_START:
            return {
                ...state,
                cat: payload,
                mensajeError: null,
                updateSuccess: false,
                updating: true
            }
        case types.GET_UNIDADES_MEDIDAS_LIST_ERROR:
        case types.GET_UNIDAD_MEDIDA_ERROR:
        case types.CREATE_UNIDAD_MEDIDA_ERROR:
        case types.UPDATE_UNIDAD_MEDIDA_ERROR:
        case types.DELETE_UNIDAD_MEDIDA_ERROR:
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                mensajeError: payload
            }
        case types.GET_UNIDADES_MEDIDAS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                ums: payload.data,
                totalItems: parseInt(payload.headers['x-total-count'], 10)
            }
        case types.GET_UNIDAD_MEDIDA_SUCCESS:
        case types.CREATE_UNIDAD_MEDIDA_SUCCESS:
        case types.UPDATE_UNIDAD_MEDIDA_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                um: payload.data
            }
        case types.DELETE_UNIDAD_MEDIDA_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                um: initialState.um
            }
        case types.RESET:
            return {
                ...initialState,
            }
        case types.RESET_UNIDAD_MEDIDA:
            return {
                ...state,
                um: initialState.um
            }
        default:
            return state;
    }
}
export default unidadesMedidas