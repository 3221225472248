import {
  GET_EVENTOS_CANCELADOS_START,
  GET_EVENTOS_CANCELADOS_SUCCESS,
  GET_EVENTOS_CANCELADOS_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: [],
  error: null
}

const cancelados = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_EVENTOS_CANCELADOS_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    
    case GET_EVENTOS_CANCELADOS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case GET_EVENTOS_CANCELADOS_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default cancelados