import {
  UPDATE_AGENDA_STATUS_START,
  UPDATE_AGENDA_STATUS_SUCCESS,
  UPDATE_AGENDA_STATUS_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: null,
  error: null,
  draggableId: null
}

const actualizaStatus = (state = initialState, { type, draggableId, payload, error }) => {

  switch(type) {

    case UPDATE_AGENDA_STATUS_START:
      return {
        ...state,
        draggableId,
        loading: true,
        error: null
      }
    
    case UPDATE_AGENDA_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case UPDATE_AGENDA_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default actualizaStatus