import * as types from './types';

const initialState = {
  loading: false,
  mensajeError: null,
  list: [],
  totalItems: 0,
  filterParams: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LOG_GENERAL_START:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case types.GET_LOG_GENERAL_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case types.GET_LOG_GENERAL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case types.SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: payload,
      };
    default:
      return state;
  }
};
