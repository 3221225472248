import { REQUEST, SUCCESS, FAILURE } from '../../../../helpers/actionTypeCreator';
import { getListActions, getCreateActions, getReadActions, getUpdateActions } from '../../../../helpers/actionCreatorCreator'
import { DEFAULT_USER } from '../../../../constants/defaultValues';

const FETCH_ROLES = 'clientManagement/FETCH_ROLES'
const FETCH_USERS = 'clientManagement/FETCH_USERS'
const FETCH_USER = 'clientManagement/FETCH_USER'
const CREATE_USER = 'clientManagement/CREATE_USER'
const UPDATE_USER = 'clientManagement/UPDATE_USER'
const DELETE_USER = 'clientManagement/DELETE_USER'
const RESET = 'clientManagement/RESET'

// Actions
export const { getUsersStart, getUsersSuccess, getUsersError } = getListActions('Users', FETCH_USERS);
export const { getRolesStart, getRolesSuccess, getRolesError } = getListActions('Roles', FETCH_ROLES);
export const { createUserStart, createUserSuccess, createUserError } = getCreateActions('User', CREATE_USER);
export const { getUserStart, getUserSuccess, getUserError } = getReadActions('User', FETCH_USER);
export const { updateUserStart, updateUserSuccess, updateUserError } = getUpdateActions('User', UPDATE_USER);
export const reset = () => ({ type: RESET });

const initialState = {
  loading: false,
  errorMessage: null,
  users: [],
  authorities: [],
  user: DEFAULT_USER,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(FETCH_ROLES):
      return {
        ...state,
      };
    case REQUEST(FETCH_USERS):
    case REQUEST(FETCH_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(CREATE_USER):
    case REQUEST(UPDATE_USER):
    case REQUEST(DELETE_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(FETCH_USERS):
    case FAILURE(FETCH_USER):
    case FAILURE(FETCH_ROLES):
    case FAILURE(CREATE_USER):
    case FAILURE(UPDATE_USER):
    case FAILURE(DELETE_USER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(FETCH_ROLES):
      return {
        ...state,
        authorities: action.payload.data,
      };
    case SUCCESS(FETCH_USERS):
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(FETCH_USER):
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SUCCESS(CREATE_USER):
    case SUCCESS(UPDATE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: action.payload.data,
      };
    case SUCCESS(DELETE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: null,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

