import * as types from './types'
import { getToken, getUser } from '../../../helpers/Utils'

const initialState = {
  isFetching: false,
  user: getUser(),
  token: getToken(),
  error: null,
  session: Boolean(getToken())
}

const login = (state = initialState, action) => {

  const { type, payload, error } = action;

  switch (type) {

    case types.USER_LOGIN_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case types.USER_LOGIN_SUCCESSFUL:
      return {
        ...state,
        isFetching: false,
        user: payload,
        session: true
      }

    case types.USER_SET_TOKEN:
      return {
        ...state,
        token: payload
      }

    case types.USER_LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
        session: false,
        error
      }
    case types.USER_SESSION_EXPIRED:
      return {
        ...state,
        session: false
      }
    case types.USER_LOGOUT_USER:
      return {
        isFetching: false,
        user: null,
        token: null,
        error: null,
        session: false
      }
    default: return state

  }

}

export default login