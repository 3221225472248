import {
  GET_RECOLECCIONES_REALIZADAS_START,
  GET_RECOLECCIONES_REALIZADAS_SUCCESS,
  GET_RECOLECCIONES_REALIZADAS_ERROR
} from '../actions'

const initialState = {
  loading: false,
  payload: [],
  error:  null
}

const realizadas = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case GET_RECOLECCIONES_REALIZADAS_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_RECOLECCIONES_REALIZADAS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case GET_RECOLECCIONES_REALIZADAS_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default realizadas