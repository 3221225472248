import {
    UPLOAD_FILE_START,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
    GET_FILE_START,
    GET_FILE_SUCCESS,
    GET_FILE_ERROR,
    GET_TIPOS_FILES_START,
    GET_TIPOS_FILES_SUCCESS,
    GET_TIPOS_FILES_ERROR
} from './types'

const initialState = {
    loading: false,
    data: null,
    error: null,
    catalogo: null,
    ultimaCarga: null
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case UPLOAD_FILE_START:
        case GET_FILE_START:
        case GET_TIPOS_FILES_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case UPLOAD_FILE_ERROR:
        case GET_FILE_ERROR:
        case GET_TIPOS_FILES_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case UPLOAD_FILE_SUCCESS:
        case GET_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            }
        case GET_TIPOS_FILES_SUCCESS:
            return {
                ...state,
                loading: false,
                catalogo: payload,
                ultimaCarga: new Date()
            }
        default: return state
    }
}
