import { combineReducers } from 'redux';

import crud from './puntosRecoleccion';
import sucursal from './sucursal';
import cliente from './cliente';
import globales from './globales';

export default combineReducers({
  crud,
  sucursal,
  cliente,
  globales,
});
