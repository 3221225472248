import * as types from './types'

const initialState = {
    loading: false,
    mensajeErrorLoading: null,
    medicos: null,
    totalItems: 0,
    medico: {
        apeMaterno: '',
        apePaterno: '',
        email: '',
        id: 0,
        nombre: '',
        telefono: ''
    },
    updating: false,
    mensajeErrorUpdating: null,
    updateSuccess: false
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_MEDICOS_LIST_START:
            return {
                ...state,
                mensajeErrorLoading: null,
                loading: true,
                updateSuccess: false
            }
        case types.GET_MEDICO_START:
        case types.CREATE_MEDICO_START:
        case types.UPDATE_MEDICO_START:
        case types.DELETE_MEDICO_START:
            return {
                ...state,
                mensajeErrorUpdating: null,
                updateSuccess: false,
                updating: true
            }
        case types.GET_MEDICOS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                mensajeErrorLoading: payload
            }
        case types.GET_MEDICO_ERROR:
        case types.CREATE_MEDICO_ERROR:
        case types.UPDATE_MEDICO_ERROR:
        case types.DELETE_MEDICO_ERROR:
            return {
                ...state,
                updating: false,
                updateSuccess: false,
                mensajeErrorUpdating: payload,
            }
        case types.GET_MEDICOS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                medicos: payload.data,
                totalItems: parseInt(payload.headers['x-total-count'], 10)
            }
        case types.GET_MEDICO_SUCCESS:
        case types.CREATE_MEDICO_SUCCESS:
        case types.UPDATE_MEDICO_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                medico: payload.data
            }
        case types.DELETE_MEDICO_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                medico: initialState.medico
            }
        case types.RESET:
            return {
                ...initialState,
            }
        case types.RESET_MEDICO:
            return {
                ...state,
                medico: initialState.medico
            }
        default:
            return state;
    }
}