import * as types from "../types";

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

const solicitudCancelar = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case types.GET_SOLICITUD_CANCELAR_START:
      return {
        ...state,
        loading: true,
        error:  null
      };

    case  types.GET_SOLICITUD_CANCELAR_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };

    case types.GET_SOLICITUD_CANCELAR_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    default: return state;

  }

}

export default solicitudCancelar;