import * as types from "../types";

const initialState = {
  loading: false,
  payload: null,
  error: null
};

const cancelaVenta = (state = initialState, { type, payload, error }) => {
  switch(type) {

    case types.CANCELAR_VENTA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.CANCELAR_VENTA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };

    case types.CANCELAR_VENTA_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    default: return state;

  }
}

export default cancelaVenta;