import * as types from '../types';

const initialState = {
  loading: false,
  mensajeErrorLoading: null,
  list: null,
  totalItems: 0,
  item: null,
  updating: false,
  mensajeErrorUpdating: null,
  updateSuccess: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PUNTOS_RECOLECCION_LIST_START:
      return {
        ...state,
        mensajeErrorLoading: null,
        loading: true,
        updateSuccess: false,
      };
    case types.GET_PUNTO_RECOLECCION_START:
    case types.CREATE_PUNTO_RECOLECCION_START:
    case types.UPDATE_PUNTO_RECOLECCION_START:
      return {
        ...state,
        mensajeErrorUpdating: null,
        updateSuccess: false,
        updating: true,
      };
    case types.GET_PUNTOS_RECOLECCION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        mensajeErrorLoading: payload,
      };
    case types.GET_PUNTO_RECOLECCION_ERROR:
    case types.CREATE_PUNTO_RECOLECCION_ERROR:
    case types.UPDATE_PUNTO_RECOLECCION_ERROR:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        mensajeErrorUpdating: payload,
      };
    case types.GET_PUNTOS_RECOLECCION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: parseInt(payload.headers['x-total-count'], 10),
      };
    case types.GET_PUNTO_RECOLECCION_SUCCESS:
    case types.CREATE_PUNTO_RECOLECCION_SUCCESS:
    case types.UPDATE_PUNTO_RECOLECCION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        item: payload.data,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
