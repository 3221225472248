import * as types from './types'

const initialState = {
    loading: false,
    mensajeError: null,
    grupos: [],
    totalItems: 0,
    grupo: {
        id: '',
        clave: '',
        nombre: '',
        descripcion: '',
        activo: true,
        articulos: [],
        web:''
    },
    updating: false,
    updateSuccess: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_GRUPOS_LIST_START:
            return {
                ...state,
                mensajeError: null,
                loading: true,
            }
        case types.GET_GRUPO_START:
        case types.CREATE_GRUPO_START:
        case types.UPDATE_GRUPO_START:
        case types.DELETE_GRUPO_START:
            return {
                ...state,
                mensajeError: null,
                updateSuccess: false,
                updating: true
            }
        case types.GET_GRUPOS_LIST_ERROR:
        case types.GET_GRUPO_ERROR:
        case types.CREATE_GRUPO_ERROR:
        case types.UPDATE_GRUPO_ERROR:
        case types.DELETE_GRUPO_ERROR:
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                mensajeError: payload.data
            }
        case types.GET_GRUPOS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                grupos: payload.data,
                totalItems: parseInt(payload.headers['x-total-count'], 10)
            }
        case types.GET_GRUPO_SUCCESS:
        case types.CREATE_GRUPO_SUCCESS:
        case types.UPDATE_GRUPO_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                grupo: payload.data
            }
        case types.DELETE_GRUPO_SUCCESS:
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                grupo: initialState.grupo
            }
        case types.RESET:
            return {
                ...initialState,
            }
        default:
            return state;
    }
}