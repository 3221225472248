import {
  SAVE_VENTA_START,
  SAVE_VENTA_SUCCESS,
  SAVE_VENTA_ERROR
} from '../actions'

const initialState = {
  isFetching: false,
  payload: null,
  error: null
}

const venta = (state = initialState, { type, payload, error }) => {
  switch(type) {

    case SAVE_VENTA_START:
      return {
        ...state,
        isFetching: true,
        error
      }
    
    case SAVE_VENTA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload
      }

    case SAVE_VENTA_ERROR:
      return {
        ...state,
        isFetching: false,
        error
      }

    default: return state

  }
}

export default venta