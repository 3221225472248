import {
  DELETE_COMENTARIO_CLIENTE_START,
  DELETE_COMENTARIO_CLIENTE_SUCCESS,
  DELETE_COMENTARIO_CLIENTE_ERROR
} from '../../actions'

const initialState = {
  loading: false,
  payload: null,
  error: null
}

const cliente = (state = initialState, { type, payload, error }) => {

  switch(type) {

    case DELETE_COMENTARIO_CLIENTE_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case DELETE_COMENTARIO_CLIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      }

    case DELETE_COMENTARIO_CLIENTE_ERROR:
      return {
        ...state,
        loading: false,
        error
      }

    default: return state

  }

}

export default cliente