import { CRUDTypes, ListDataItems } from '../../../helpers/CRUDTypes';
import { ElementoI } from '../ElementoI';
import {
  getCreateActions,
  getListActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';
import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';

enum ElementTypes {
  GET_ELEMENTOS = 'elementos/GET_ELEMENTOS',
  GET_ELEMENTO = 'elementos/GET_ELEMENTO',
  CREATE_ELEMENTO = 'elementos/CREATE_ELEMENTO',
  UPDATE_ELEMENTO = 'elementos/UPDATE_ELEMENTO',
}

export const {
  getElementosStart,
  getElementosSuccess,
  getElementosError,
} = getListActions('Elementos', ElementTypes.GET_ELEMENTOS);
export const {
  getElementoStart,
  getElementoSuccess,
  getElementoError,
} = getReadActions('Elemento', ElementTypes.GET_ELEMENTO);
export const {
  createElementoStart,
  createElementoSuccess,
  createElementoError,
} = getCreateActions('Elemento', ElementTypes.CREATE_ELEMENTO);
export const {
  updateElementoStart,
  updateElementoSuccess,
  updateElementoError,
} = getUpdateActions('Elemento', ElementTypes.UPDATE_ELEMENTO);

type ElementosState = {
  loading: Boolean;
  error: Error | null;
  list: ElementoI[];
  totalItems: Number;
  element: ElementoI | null;
  updating: Boolean;
};

const initialState: ElementosState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

const reducer = (
  state: ElementosState = initialState,
  { type, payload }: CRUDTypes<ElementoI>
) => {
  switch (type) {
    case REQUEST(ElementTypes.GET_ELEMENTOS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(ElementTypes.GET_ELEMENTO):
    case REQUEST(ElementTypes.CREATE_ELEMENTO):
    case REQUEST(ElementTypes.UPDATE_ELEMENTO):
      return {
        ...state,
        error: null,
        updating: true,
      };
    case FAILURE(ElementTypes.GET_ELEMENTOS):
    case FAILURE(ElementTypes.GET_ELEMENTO):
    case FAILURE(ElementTypes.CREATE_ELEMENTO):
    case FAILURE(ElementTypes.UPDATE_ELEMENTO):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case SUCCESS(ElementTypes.GET_ELEMENTOS):
      return {
        ...state,
        loading: false,
        list: (payload as ListDataItems<ElementoI>).data,
        totalItems:  (payload as ListDataItems<ElementoI>).totalItems,
        error: null,
      };
    case SUCCESS(ElementTypes.GET_ELEMENTO):
    case SUCCESS(ElementTypes.CREATE_ELEMENTO):
    case SUCCESS(ElementTypes.UPDATE_ELEMENTO):
      return {
        ...state,
        loading: false,
        error: null,
        updating: false,
        element: payload,
      };

    default:
      return state;
  }
};

export default reducer;
