export const GET_ENTREGA_START = "entregas/GET_PACIENTE_START"
export const GET_ENTREGA_SUCCESS = "entregas/GET_PACIENTE_SUCCESS"
export const GET_ENTREGA_ERROR = "entregas/GET_PACIENTE_ERROR"

export const GET_LOG_ENTREGAS_START = 'LOG_ENTREGAS/GET_LOG_ENTREGAS_START';
export const GET_LOG_ENTREGAS_ERROR = 'LOG_ENTREGAS/GET_LOG_ENTREGAS_ERROR';
export const GET_LOG_ENTREGAS_SUCCESS = 'LOG_ENTREGAS/GET_LOG_ENTREGAS_SUCCESS';

export const GET_LOG_INFO_START = 'entregas/GET_LOG_INFO_START';
export const GET_LOG_INFO_ERROR = 'entregas/GET_LOG_INFO_ERROR';
export const GET_LOG_INFO_SUCCESS = 'entregas/GET_LOG_INFO_SUCCESS';
export const SET_SEARCH_PARAMS = 'LOG_ENTREGAS/SET_SEARCH_PARAMS';
export const CLEAR_STATE = 'LOG_ENTREGAS/CLEAR_STATE';
// export const SET_SEARCH_PARAMS = 'LOG_ENTREGAS/SET_SEARCH_PARAMS';

export const GET_ENTREGA_ORDEN_START = 'GET_ENTREGA_ORDEN_START';
export const GET_ENTREGA_ORDEN_SUCCESS = 'GET_ENTREGA_ORDEN_SUCCESS';
export const GET_ENTREGA_ORDEN_ERROR = 'GET_ENTREGA_ORDEN_ERROR';

export const POST_ENTREGA_ORDEN_START = 'POST_ENTREGA_ORDEN_START';
export const POST_ENTREGA_ORDEN_SUCCESS = 'POST_ENTREGA_ORDEN_SUCCESS';
export const POST_ENTREGA_ORDEN_ERROR = 'POST_ENTREGA_ORDEN_ERROR';

export const POST_EXTRA_MAIL_START = 'POST_EXTRA_MAIL_START';
export const POST_EXTRA_MAIL_SUCCESS = 'POST_EXTRA_MAIL_SUCCESS';
export const POST_EXTRA_MAIL_ERROR = 'POST_EXTRA_MAIL_ERROR';

export const PUT_UPDATE_MAIL_START = 'PUT_UPDATE_MAIL_START';
export const PUT_UPDATE_MAIL_SUCCESS = 'PUT_UPDATE_MAIL_SUCCESS';
export const PUT_UPDATE_MAIL_ERROR = 'PUT_UPDATE_MAIL_ERROR';
