import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import menu from './menu/reducer'
import login from '../views/login/redux/reducer'
import userManagement from '../views/administracion/user-management/redux/user-management.reducer'
import clientManagement from '../views/administracion/cliente-management/redux/client-management.reducer'
import estudios from '../views/estudios/redux/reducer'
import notification from '../services/react-notifications/redux/reducer'
import impresiones from '../services/impresiones/redux/reducer'
import correos from '../services/correos/redux/reducer'
import navButtons from '../services/nav-buttons/redux/reducer'
import sendEmail from '../views/password/redux/reducers/sendEmail'
import resetPassword from '../views/password/redux/reducers/resetPassword'
import { createCatalogReducer } from "./reducerCreator"
import perfiles from '../views/perfil/redux/reducer'
import formulasView from '../views/formulas/redux/reducer'
import dashboard from '../views/dashboards/redux/reducer'
import promociones from '../views/promociones/redux/reducer'
import sucursales from '../views/sucursales/redux/reducer'
import convenios from '../views/convenios/redux/reducer'
import clientes from '../views/clientes/redux/reducer'
import productos from '../views/productos/redux/reducer'
import empresas from '../views/empresas/redux/reducer'
import grupos from '../views/grupos/redux/reducer'
import estadoCuenta from '../views/estadoCuenta/redux/reducer'
import busqueda from '../views/busqueda/redux/reducers'
import pacientes from '../views/pacientes/redux/reducer'
import medicos from '../views/medicos/redux/reducer'
import ventas from '../views/ordenes/redux/reducers'
import cajas from '../views/caja/redux/reducers'
import detalleVenta from '../views/ventas/redux/reducers'
import catalogos from '../views/catalogos/redux'
import recolecciones from '../views/mensajeria/redux/reducer'
import recepcion from '../views/muestras/redux/reducers'
import configuracionesLocales from '../views/administracion/settings/redux/reducer';
import catalogoUnidadesMedida from '../views/unidadesMedida/redux/reducers'
import templates from '../views/templates/redux/reducer'
import agenda from '../views/agenda/redux/reducers'
import recolecta from '../views/recolecciones/redux/reducers'
import bacterias from '../views/bacterias/redux/reducer'
import antibioticos from '../views/antibioticos/redux/reducer'
import antibiogramas from '../views/antibiogramas/redux/reducer'
import account from '../views/account/redux'
import esquemasBacteriologicos from '../views/esquemasBacteriologicos/redux/reducer'
import gruposCultivos from '../views/gruposCultivos/redux/reducer'
import parametrosCultivos from '../views/parametrosCultivos/redux/reducer'
import archivos from '../views/archivos/redux/reducer'
import dashboards from '../views/dashboards/redux/reducers'
import listaPrecios from '../views/listaPrecios/redux/reducer'
import eventos from '../views/socialEvents/redux/reducer'
import autorizaciones from '../views/autorizaciones/redux/reducer'
import reportesOptPdf from '../services/reportePDF/redux/reducer'
import puntosRecoleccion from '../views/puntosDeRecoleccion/redux/reducers'
import configuracionesCorreo from '../views/administracion/correos/redux/reducer';
import entregas from '../views/entregas/redux/reducer';
import logGeneral from '../views/logGeneral/redux/reducer';
import elementoReportes from '../views/elementoReportes/redux/reducer';
import configuracionesGlobales from '../views/configGlob/redux/reducer';


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  configuracionesLocales,
  menu,
  login,
  userManagement,
  clientManagement,
  account,
  estudios,
  notification,
  impresiones,
  correos,
  navButtons,
  sendEmail,
  resetPassword,
  catalogos, 
  dashboards,
  tags: createCatalogReducer("TAGS"),
  sinonimos: createCatalogReducer("SINONIMOS"),
  contenedores: createCatalogReducer("CONTENEDORES"),
  muestras: createCatalogReducer("MUESTRAS"),
  metodologias: createCatalogReducer("METODOLOGIAS"),
  maquinas: createCatalogReducer("MAQUINAS"),
  proveedores: createCatalogReducer("PROVEEDORES"),
  areas: createCatalogReducer("AREAS"),
  areasOperativas: createCatalogReducer("AREAS_OPERATIVAS"),
  unidadesMedida: createCatalogReducer("UNIDADES_MEDIDA"),
  indicacionesPaciente: createCatalogReducer("INDICACIONES_PACIENTE"),
  indicacionesAdicionales: createCatalogReducer("INDICACIONES_ADICIONALES"),
  indicacionesTomaMuestra: createCatalogReducer("INDICACIONES_TOMA_MUESTRA"),
  variantes: createCatalogReducer("VARIANTES"),
  formulas: createCatalogReducer("FORMULAS"),
  categorias: createCatalogReducer("CATEGORIAS"),
  prioridades: createCatalogReducer("PRIORIDADES"),
  perfiles,
  fuentes: createCatalogReducer('FUENTES'),
  generos: createCatalogReducer('GENEROS'),
  razas: createCatalogReducer('RAZAS'),
  namesucursals: createCatalogReducer('NAMESUCURSALS'),
  tiposSangre: createCatalogReducer('TIPOS_SANGRE'),
  componentesVariante: createCatalogReducer('COMPONENTES_VARIANTES'),
  estudiosList: createCatalogReducer('ESTUDIOS'),
  dashboard,
  promociones,
  formulasView,
  perfilesList: createCatalogReducer('PERFILES'),
  sucursales,
  empresasList: createCatalogReducer('EMPRESAS'),
  dias: createCatalogReducer('DIAS'),
  articuloClinico: createCatalogReducer('ARTICULO_CLINICO'),
  convenios,
  clientes,
  productos,
  empresas,
  estadoCuenta,
  colonias: createCatalogReducer('COLONIAS'),
  municipios: createCatalogReducer('MUNICIPIOS'),
  estados: createCatalogReducer('ESTADOS'),
  pais: createCatalogReducer('PAIS'),
  paisPublic: createCatalogReducer('PAIS_PUBLIC'),
  tipoClientes: createCatalogReducer('TIPO_CLIENTES'),
  impuestos: createCatalogReducer('IMPUESTOS'),
  tipoProductos: createCatalogReducer('TIPO_PRODUCTOS'),
  clientesList: createCatalogReducer('CLIENTES'),
  tipoRelacion: createCatalogReducer('TIPO_RELACION'),
  metodosEntrega: createCatalogReducer('METODOS_ENTREGA'),
  grupos,
  busqueda,
  conveniosList: createCatalogReducer('CONVENIOS'),
  pacientes,
  medicos,
  ventas,
  cajas,
  bacterias,
  antibioticos,
  antibiogramas,
  pacientesList: createCatalogReducer('PACIENTES'),
  medicosList: createCatalogReducer('MEDICOS'),
  especialidades: createCatalogReducer('ESPECIALIDADES'),
  vendedoresList: createCatalogReducer('VENDEDORES'),
  sucursalesList: createCatalogReducer('SUCURSALES'),
  detalleVenta,
  tiposRecoleccionList: createCatalogReducer('TIPO_RECOLECCION'),
  centrosTrabajoList: createCatalogReducer('CENTRO_TRABAJOS'),
  recolecciones,
  metodosPago: createCatalogReducer('METODOS_PAGO'),
  cajasList: createCatalogReducer("CAJAS"),
  canales: createCatalogReducer("CANALES"),
  motivosSalida: createCatalogReducer('MOTIVOS_SALIDA'),
  motivosEntradas: createCatalogReducer('MOTIVOS_ENTRADAS'),
  motivosSalidas: createCatalogReducer('MOTIVOS_SALIDAS'),
  recepcion,
  catalogoUnidadesMedida,
  templates,
  agenda,
  recolecta,
  esquemasBacteriologicos,
  gruposCultivos,
  parametrosCultivos,
  archivos,
  tipoLog: createCatalogReducer('TIPO_LOG'),
  tipoContactoTel: createCatalogReducer('TIPO_CONTACTO_TEL'),
  tipoContactoMail: createCatalogReducer('TIPO_CONTACTO_MAIL'),
  listaPrecios,
  eventos,
  invitadosList: createCatalogReducer('INVITADOS_EVENTO'),
  ordenesEventoList: createCatalogReducer('ORDENES_EVENTO'),
  tipoEvento: createCatalogReducer("TIPO_EVENTO"),
  plataforma: createCatalogReducer("PLATAFORMA"),
  terminales: createCatalogReducer('TERMINALES'),
  autorizaciones,
  reportesOptPdf,
  puntosRecoleccion,
  grupoEmpresarialList: createCatalogReducer('GRUPO_EMPRESARIAL'),
  configuracionesCorreo,
  entregas,
  logGeneral,
  elementoReportes,
  configuracionesGlobales,
})

export default createRootReducer