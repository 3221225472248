import createCategoryCatalogReducer, {
  getListActions,
} from '../CategoryCreatorRedux';

const TYPE = 'UNIDADES_MEDIDA';
const scopeName = `CATALOGO/${TYPE}`;

export const {
  getUnidadesMedidaByCatStart,
  getUnidadesMedidaByCatSuccess,
  getUnidadesMedidaByCatError,
} = getListActions('UnidadesMedidaByCat', scopeName);

export default createCategoryCatalogReducer(scopeName);
