import {
  GET_ESTUDIOS_CERTIFICADOS_START,
  GET_ESTUDIOS_CERTIFICADOS_SUCCESS,
  GET_ESTUDIOS_CERTIFICADOS_ERROR,
} from '../actions';

const initialState = {
  loading: false,
  payload: [],
  error: null,
};

const estudiosCertificados = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case GET_ESTUDIOS_CERTIFICADOS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ESTUDIOS_CERTIFICADOS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };

    case GET_ESTUDIOS_CERTIFICADOS_ERROR:
      return {
        ...state,
        loading: false,
        error,
      };

    default:
      return state;
  }
};

export default estudiosCertificados;
