/* eslint-disable import/no-extraneous-dependencies, global-require */
import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import notificationMiddleware from '../services/react-notifications/notification-middleware'

import createRootReducer from './reducers'
import { USER_LOGOUT_USER } from '../views/login/redux/types'

export const history = createBrowserHistory()

const middleware = routerMiddleware(history)

const appReducer = createRootReducer(history)

const configureStore = preloadedState => {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const rootReducer = (state, action) => {
    return appReducer(action.type === USER_LOGOUT_USER ? undefined : state, action);
  }
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunk, notificationMiddleware, middleware)
    )
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default configureStore
