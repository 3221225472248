// Eventos calendario
export const GET_EVENTOS_START = "GET_EVENTOS_START"
export const GET_EVENTOS_SUCCESS = "GET_EVENTOS_SUCCESS"
export const GET_EVENTOS_ERROR = "GET_EVENTOS_ERROR"

export const getEventosStart = () => ({
  type: GET_EVENTOS_START
})

export const getEventosSuccess = (payload) => ({
  type: GET_EVENTOS_SUCCESS,
  payload
})

export const getEventosError = (error) => ({
  type: GET_EVENTOS_ERROR,
  error,
  notification: {
    type:'error',
    title:"Obtener eventos",
    message:"No fue posible obtener los eventos."
  }
})

// Agentes
export const GET_AGENTES_START = "GET_AGENTES_START"
export const GET_AGENTES_SUCCESS = "GET_AGENTES_SUCCESS"
export const GET_AGENTES_ERROR = "GET_AGENTES_ERROR"

export const getAgentesStart = () => ({
  type: GET_AGENTES_START
})

export const getAgentesSuccess = (payload) => ({
  type: GET_AGENTES_SUCCESS,
  payload
})

export const getAgentesError = (error) => ({
  type: GET_AGENTES_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Obtener agentes',
    message: 'No fue posible obtener los agentes'
  }
})

// Eventos no asignados
export const GET_EVENTOS_NO_ASIGNADOS_START = "GET_EVENTOS_NO_ASIGNADOS_START"
export const GET_EVENTOS_NO_ASIGNADOS_SUCCESS = "GET_EVENTOS_NO_ASIGNADOS_SUCCESS"
export const GET_EVENTOS_NO_ASIGNADOS_ERROR = "GET_EVENTOS_NO_ASIGNADOS_ERROR"

export const getEventosNoAsignadosStart = () => ({
  type: GET_EVENTOS_NO_ASIGNADOS_START
})

export const getEventosNoAsignadosSuccess = (payload) => ({
  type: GET_EVENTOS_NO_ASIGNADOS_SUCCESS,
  payload
})

export const getEventosNoAsignadosError = (error) => ({
  type: GET_EVENTOS_NO_ASIGNADOS_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Obtener eventos no asignados',
    message: 'No fue posible obtener los eventos no asignados'
  }
})

// Eventos asignados
export const GET_EVENTOS_ASIGNADOS_START = "GET_EVENTOS_ASIGNADOS_START"
export const GET_EVENTOS_ASIGNADOS_SUCCESS = "GET_EVENTOS_ASIGNADOS_SUCCESS"
export const GET_EVENTOS_ASIGNADOS_ERROR = "GET_EVENTOS_ASIGNADOS_ERROR"

export const getEventosAsignadosStart = () => ({
  type: GET_EVENTOS_ASIGNADOS_START
})

export const getEventosAsignadosSuccess = (payload) => ({
  type: GET_EVENTOS_ASIGNADOS_SUCCESS,
  payload
})

export const getEventosAsignadosError = (error) => ({
  type: GET_EVENTOS_ASIGNADOS_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Obtener eventos asignados',
    message: 'No fue posible obtener los eventos asignados'
  }
})

// Eventos realizados
export const GET_EVENTOS_REALIZADOS_START = "GET_EVENTOS_REALIZADOS_START"
export const GET_EVENTOS_REALIZADOS_SUCCESS = "GET_EVENTOS_REALIZADOS_SUCCESS"
export const GET_EVENTOS_REALIZADOS_ERROR = "GET_EVENTOS_REALIZADOS_ERROR"

export const getEventosRealizadosStart = () => ({
  type: GET_EVENTOS_REALIZADOS_START
})

export const getEventosRealizadosSuccess = (payload) => ({
  type: GET_EVENTOS_REALIZADOS_SUCCESS,
  payload
})

export const getEventosRealizadosError = (error) => ({
  type: GET_EVENTOS_REALIZADOS_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Obtener eventos realizados',
    message: 'No fue posible obtener los eventos realizados'
  }
})

// Eventos cancelados
export const GET_EVENTOS_CANCELADOS_START = "GET_EVENTOS_CANCELADOS_START"
export const GET_EVENTOS_CANCELADOS_SUCCESS = "GET_EVENTOS_CANCELADOS_SUCCESS"
export const GET_EVENTOS_CANCELADOS_ERROR = "GET_EVENTOS_CANCELADOS_ERROR"

export const getEventosCanceladosStart = () => ({
  type: GET_EVENTOS_CANCELADOS_START
})

export const getEventosCanceladosSuccess = (payload) => ({
  type: GET_EVENTOS_CANCELADOS_SUCCESS,
  payload
})

export const getEventosCanceladosError = (error) => ({
  type: GET_EVENTOS_CANCELADOS_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Obtener eventos cancelados',
    message: 'No fue posible obtener los eventos cancelados'
  }
})

// Actualiza agenda
export const UPDATE_AGENDA_STATUS_START = "UPDATE_AGENDA_STATUS_START"
export const UPDATE_AGENDA_STATUS_SUCCESS = "UPDATE_AGENDA_STATUS_SUCCESS"
export const UPDATE_AGENDA_STATUS_ERROR = "UPDATE_AGENDA_STATUS_ERROR"

export const updateAgendaStatusStart = (draggableId) => ({
  type: UPDATE_AGENDA_STATUS_START,
  draggableId
})

export const updateAgendaStatusSuccess = (payload) => ({
  type: UPDATE_AGENDA_STATUS_SUCCESS,
  payload,
  notification: {
    type: 'success',
    title: 'Cambiar estatus',
    message: 'El evento fue cambiado de estatus satisfactoriamente'
  }
})

export const updateAgendaStatusError = (error) => ({
  type: UPDATE_AGENDA_STATUS_ERROR,
  error,
  notification: {
    type: 'error',
    title: 'Cambiar estatus',
    message: 'No fue posible cambiar el estatus en la agenda'
  }
})