export const ORDEN = () => ({
  id: new Date().getTime(),
  paciente: null,
  medico: MEDICO_DEFAULT(),
  prioridad: {
    value: 'NORMAL',
    label: 'NORMAL',
  },
  // tipoEntrega: null,
  notas: '',
  estudios: [],
  subtotal: 0,
  descuento: 0,
  total: 0,
  indicaciones: [],
  indicacionesAdicionales: [],
  fechaEntrega: new Date(),
  tiempoElaboracion: '',
  // tienePromo: false,
  // dataPromo: [],
  kits: [],
  // envioPaciente: false,
  // envioMedico: false,
  // envioCliente: false,
  metodosEnvioPaciente: null,
  metodosEnvioMedico: null,
  promocion: null,
  articulosKits: [],
  kitsSeleccionados: [],
});

export const CLIENTE_DEFAULT = () => ({
  activo: true,
  apePaterno: null,
  apeMaterno: null,
  comentarios: null,
  contactos: null,
  descripcion: 'PUBLICO EN GENERAL',
  direcciones: null,
  id: 1,
  nombre: null,
  persona: 'FISICA',
  razonSocial: 'PUBLICO EN GENERAL ',
  rfc: 'XAXX010101000',
  tipoCliente: { id: 1, descripcion: '', clientes: null },
  value: 1,
  label: 'PUBLICO EN GENERAL',
});

export const MEDICO_DEFAULT = () => ({
  apeMaterno: ' ',
  apePaterno: ' ',
  comentarios: null,
  email: ' ',
  especialidad: null,
  grupo: null,
  id: 0,
  label: 'A QUIEN CORRESPONDA',
  nombre: 'A QUIEN CORRESPONDA',
  promociones: null,
  resume: {
    especialidad: null,
    id: 0,
    nombre: 'A QUIEN CORRESPONDA',
  },
  telefono: '-',
  value: 0,
});

export const STATUS_FECHA_ENTREGA_CODES = {
  NO_DATA: {
    target: 'errorIcon',
    color: 'danger',
    icon: 'simple-icon-exclamation',
  },
  NO_RANGES: {
    target: 'errorIcon',
    color: 'danger',
    icon: 'simple-icon-exclamation',
  },
  FALL_BACK_PRIORITY: {
    target: 'warningIcon',
    color: 'warning',
    icon: 'iconsminds-idea',
  },
  NOT_SAME_DAY: {
    target: 'warningIcon',
    color: 'warning',
    icon: 'iconsminds-idea',
  },
  OK: {
    target: 'successIcon',
    color: 'success',
    icon: 'simple-icon-check',
  },
};