import { REQUEST, SUCCESS, FAILURE } from '../../../helpers/actionTypeCreator';
import { getCreateActions } from '../../../helpers/actionCreatorCreator';

const SEND_REPORTE = 'correos/SEND_REPORTE';
const RESET = 'correos/RESET';
const SHOW_MODAL = 'correos/SHOW_MODAL';
const HIDE_MODAL = 'correos/HIDE_MODAL';
// Actions
export const {
  createEnvioReporteStart,
  createEnvioReporteSuccess,
  createEnvioReporteError,
} = getCreateActions('EnvioReporte', SEND_REPORTE);

export const showModal = () => ({ type: SHOW_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });

export const reset = () => ({ type: RESET });

const initialState = {
  sending: false,
  errorMessage: null,
  file: null,
  modal: false,
  serviceStatus: null,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(SEND_REPORTE):
      return {
        ...state,
        errorMessage: null,
        sending: true,
        file: action.payload,
      };
    case FAILURE(SEND_REPORTE):
      return {
        ...state,
        sending: false,
        errorMessage: action.payload,
      };
    case SUCCESS(SEND_REPORTE):
      return {
        ...state,
        sending: false,
        errorMessage: null,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: false,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
