import * as types from '../types';

const initialState = {
  loading: false,
  mensajeError: null,
  estudiosResult: null,
  totalEstudios: 0,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ESTUDIOS_MATCH_START:
      return {
        ...state,
        mensajeError: null,
        loading: true,
      };
    case types.GET_ESTUDIOS_MATCH_ERROR:
      return {
        ...state,
        loading: false,
        mensajeError: payload.data,
      };
    case types.GET_ESTUDIOS_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        estudiosResult: payload.data.map((est) => ({
          ...est,
          newPrice: {
            precio: est.precio,
            tipoArticulo: est.tipoArticulo,
            disponibleVenta: est.disponibleVenta,
            vendidoEnPerfil: est.vendidoEnPerfil,
            perilesEncontrados: est?.perilesEncontrados ?? [],
          },
        })),
        totalEstudios: parseInt(payload.headers['x-total-count'], 10),
      };
    case types.RESET_ESTUDIOS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
