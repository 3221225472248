import * as types from '../types';

const initialState = {
  loading: false,
  error: null,
  list: null,
};

const cliente = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PUNTOS_RECOLECCION_LIST_CLIENTE_START:
      return {
        ...state,
        list: null,
        loading: true,
      };
    case types.GET_PUNTOS_RECOLECCION_LIST_CLIENTE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case types.GET_PUNTOS_RECOLECCION_LIST_CLIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case types.HOT_UPDATE_PUNTOS_RECOLECCION_LIST_CLIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload],
      };
    default:
      return state;
  }
};
export default cliente;
