export const GET_PUNTOS_RECOLECCION_LIST_START =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_START';
export const GET_PUNTOS_RECOLECCION_LIST_SUCCESS =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_SUCCESS';
export const GET_PUNTOS_RECOLECCION_LIST_ERROR =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_ERROR';

export const GET_PUNTO_RECOLECCION_START =
  'puntosRecoleccion/GET_PUNTO_RECOLECCION_START';
export const GET_PUNTO_RECOLECCION_SUCCESS =
  'puntosRecoleccion/GET_PUNTO_RECOLECCION_SUCCESS';
export const GET_PUNTO_RECOLECCION_ERROR =
  'puntosRecoleccion/GET_PUNTO_RECOLECCION_ERROR';

export const GET_PUNTOS_RECOLECCION_LIST_SUCURSAL_START =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_SUCURSAL_START';
export const GET_PUNTOS_RECOLECCION_LIST_SUCURSAL_SUCCESS =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_SUCURSAL_SUCCESS';
export const GET_PUNTOS_RECOLECCION_LIST_SUCURSAL_ERROR =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_SUCURSAL_ERROR';
export const HOT_UPDATE_PUNTOS_RECOLECCION_LIST_SUCURSAL_SUCCESS =
  'puntosRecoleccion/GOT_UPDATE_PUNTOS_RECOLECCION_LIST_SUCURSAL_SUCCESS';

export const GET_PUNTOS_RECOLECCION_LIST_CLIENTE_START =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_CLIENTE_START';
export const GET_PUNTOS_RECOLECCION_LIST_CLIENTE_SUCCESS =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_CLIENTE_SUCCESS';
export const GET_PUNTOS_RECOLECCION_LIST_CLIENTE_ERROR =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_CLIENTE_ERROR';
export const HOT_UPDATE_PUNTOS_RECOLECCION_LIST_CLIENTE_SUCCESS =
  'puntosRecoleccion/GOT_UPDATE_PUNTOS_RECOLECCION_LIST_CLIENTE_SUCCESS';

export const GET_PUNTOS_RECOLECCION_LIST_GLOBALES_START =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_GLOBALES_START';
export const GET_PUNTOS_RECOLECCION_LIST_GLOBALES_SUCCESS =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_GLOBALES_SUCCESS';
export const GET_PUNTOS_RECOLECCION_LIST_GLOBALES_ERROR =
  'puntosRecoleccion/GET_PUNTOS_RECOLECCION_LIST_GLOBALES_ERROR';
export const HOT_UPDATE_PUNTOS_RECOLECCION_LIST_GLOBALES_SUCCESS =
  'puntosRecoleccion/GOT_UPDATE_PUNTOS_RECOLECCION_LIST_GLOBALES_SUCCESS';

export const CREATE_PUNTO_RECOLECCION_START =
  'puntosRecoleccion/CREATE_PUNTO_RECOLECCION_START';
export const CREATE_PUNTO_RECOLECCION_SUCCESS =
  'puntosRecoleccion/CREATE_PUNTO_RECOLECCION_SUCCESS';
export const CREATE_PUNTO_RECOLECCION_ERROR =
  'puntosRecoleccion/CREATE_PUNTO_RECOLECCION_ERROR';

export const UPDATE_PUNTO_RECOLECCION_START =
  'puntosRecoleccion/UPDATE_PUNTO_RECOLECCION_START';
export const UPDATE_PUNTO_RECOLECCION_SUCCESS =
  'puntosRecoleccion/UPDATE_PUNTO_RECOLECCION_SUCCESS';
export const UPDATE_PUNTO_RECOLECCION_ERROR =
  'puntosRecoleccion/UPDATE_PUNTO_RECOLECCION_ERROR';

export const RESET = 'puntosRecoleccion/RESET';
