import * as types from '../types';

const initialState = {
  loading: false,
  loadingCC: false,
  mensajeError: null,
  cajas: [],
  cajasSucursal: null,
  caja: null,
  corteCaja: null,
  cajaActual: null,
  totalItems: 0,
  updating: false,
  updateSuccess: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_CAJA_START:
      return {
        ...state,
        mensajeError: null,
        loadingCC: true,
      }
    case types.GET_CAJAS_LIST_BY_SUCURSAL_START:
      return {
        ...state,
        mensajeError: null,
        loading: true,
        updateSuccess: false,
      };
    case types.CREATE_CAJA_START:
    case types.UPDATE_CAJA_START:
    case types.ENABLE_CAJA_START:
    case types.DISABLE_CAJA_START:
      return {
        ...state,
        mensajeError: null,
        updateSuccess: false,
        updating: true,
      };
    
    case types.GET_CAJAS_LIST_BY_SUCURSAL_ERROR:
    case types.GET_CAJA_ERROR:
    case types.CREATE_CAJA_ERROR:
    case types.UPDATE_CAJA_ERROR:
    case types.ENABLE_CAJA_ERROR:
    case types.DISABLE_CAJA_ERROR:
      return {
        ...state,
        loading: false,
        loadingCC: false,
        updating: false,
        updateSuccess: false,
        mensajeError: payload.data,
      };
    case types.GET_CAJAS_LIST_BY_SUCURSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        cajasSucursal: payload,
      };
    case types.GET_CAJAS_LIST_BY_SUCURSAL_CRUD_SUCCESS:
      return {
        ...state,
        loading: false,
        cajas: payload,
      }
    case types.GET_CAJA_SUCCESS:
      return {
        ...state,
        loadingCC: false,
        caja: payload.data,
      };
    case types.CREATE_CAJA_SUCCESS:
    case types.UPDATE_CAJA_SUCCESS:
    case types.DISABLE_CAJA_SUCCESS:
    case types.ENABLE_CAJA_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        caja: payload.data,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
