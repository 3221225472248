export const GET_PRECIOS_BASE_START = "plantillaPrecios/GET_PRECIOS_BASE_START"
export const GET_PRECIOS_BASE_SUCCESS = "plantillaPrecios/GET_PRECIOS_BASE_SUCCESS"
export const GET_PRECIOS_BASE_ERROR = "plantillaPrecios/GET_PRECIOS_BASE_ERROR"

export const GET_PLANTILLAS_PRECIOS_LIST_START = "plantillaPrecios/GET_PLANTILLAS_PRECIOS_LIST_START"
export const GET_PLANTILLAS_PRECIOS_LIST_SUCCESS = "plantillaPrecios/GET_PLANTILLAS_PRECIOS_LIST_SUCCESS"
export const GET_PLANTILLAS_PRECIOS_LIST_ERROR = "plantillaPrecios/GET_PLANTILLAS_PRECIOS_LIST_ERROR"

export const GET_PLANTILLA_START = "plantillaPrecios/GET_PLANTILLA_START"
export const GET_PLANTILLA_SUCCESS = "plantillaPrecios/GET_PLANTILLA_SUCCESS"
export const GET_PLANTILLA_ERROR = "plantillaPrecios/GET_PLANTILLA_ERROR"

export const CREATE_PLANTILLA_START = 'plantillaPrecios/CREATE_PLANTILLA_START'
export const CREATE_PLANTILLA_SUCCESS = 'plantillaPrecios/CREATE_PLANTILLA_SUCCESS'
export const CREATE_PLANTILLA_ERROR = 'plantillaPrecios/CREATE_PLANTILLA_ERROR'

export const UPDATE_PLANTILLA_START = 'plantillaPrecios/UPDATE_PLANTILLA_START'
export const UPDATE_PLANTILLA_SUCCESS = 'plantillaPrecios/UPDATE_PLANTILLA_SUCCESS'
export const UPDATE_PLANTILLA_ERROR = 'plantillaPrecios/UPDATE_PLANTILLA_ERROR'

export const RESET_ALL = 'plantillaPrecios/RESET'
export const RESET_PLANTILLA = 'plantillaPrecios//RESET_PLANTILLA'