import * as types from '../types';

const initialState = {
  loading: false,
  mensajeError: null,
  ordenesResult: null,
  totalOrdenes: 0,
  ultimaCarga: null,
  listCatalogoStatus: null,
  listCatalogoLoading: false,
  listCatalogoMensajeError: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ORDENES_MATCH_START:
      return {
        ...state,
        mensajeError: null,
        loading: true,
      };
    case types.GET_ORDENES_MATCH_ERROR:
      return {
        ...state,
        loading: false,
        mensajeError: payload.data,
      };
    case types.GET_ORDENES_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        ordenesResult: payload.data.map((r) => ({
          ...r,
          origen: { isVenta: !r?.eventoID, id:  r.ventaId },
          indicadoresTmp: {
            id: r.id,
            indicadores: r.indicadores,
          },
          ver: {
            isCotizacion: r.serie === 'C',
            id: r.ventaId,
            ord: r.ordenTrabajoId,
            folioOrd: r.folioOrden,
            hacerVenta: {
              si: r.serie === 'C',
              idVenta: r.ventaId,
            },
          },
          tipo:{
            isCot: r.serie === 'C',
            fol: r.folioOrden,
          },
          estado:{
            prioridad: r.prioridad,
            isCancelada: r.statusOrdenTrabajo === 'CANCELADA'
          },
        })),
        totalOrdenes: parseInt(payload.headers['x-total-count'], 10),
      };
    case types.GET_STATUS_ORDENES_MATCH_START:
      return {
        ...state,
        listCatalogoMensajeError: null,
        listCatalogoStatus: true,
      };
    case types.GET_STATUS_ORDENES_MATCH_ERROR:
      return {
        ...state,
        listCatalogoLoading: false,
        listCatalogoMensajeError: payload,
      };
    case types.GET_STATUS_ORDENES_MATCH_SUCCESS:
      return {
        ...state,
        listCatalogoLoading: false,
        listCatalogoStatus: payload.data,
        ultimaCarga: new Date(),
      };
    case types.RESET_ORDENES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
