
export const GET_RECOLECCION_LIST_START = "MUESTRAS/GET_RECOLECCION_LIST_START"
export const GET_RECOLECCION_LIST_SUCCESS = "MUESTRAS/GET_RECOLECCION_LIST_SUCCESS"
export const GET_RECOLECCION_LIST_ERROR = "MUESTRAS/GET_RECOLECCION_LIST_ERROR"
export const RESET = 'MUESTRAS/RESET'

export const GET_MUESTRA_START = "MUESTRAS/GET_MUESTRA_START"
export const GET_MUESTRA_SUCCESS = "MUESTRAS/GET_MUESTRA_SUCCESS"
export const GET_MUESTRA_ERROR = "MUESTRAS/GET_MUESTRA_ERROR"

export const UPDATE_MUESTRA_START = "MUESTRAS/UPDATE_MUESTRA_START"
export const UPDATE_MUESTRA_SUCCESS = "MUESTRAS/UPDATE_MUESTRA_SUCCESS"
export const UPDATE_MUESTRA_ERROR = "MUESTRAS/UPDATE_MUESTRA_ERROR"

export const CREATE_TRASPASO_START = 'CREATE_TRASPASO_START'
export const CREATE_TRASPASO_SUCCESS = 'CREATE_TRASPASO_SUCCESS'
export const CREATE_TRASPASO_ERROR = 'CREATE_TRASPASO_ERROR'

export const UPDATE_MUESTRAS_LIST_START = "MUESTRAS/UPDATE_MUESTRAS_LIST_START"
export const UPDATE_MUESTRAS_LIST_SUCCESS = "MUESTRAS/UPDATE_MUESTRAS_LIST_SUCCESS"
export const UPDATE_MUESTRAS_LIST_ERROR = "MUESTRAS/UPDATE_MUESTRAS_LIST_ERROR"