import * as types from '../types'

const initialState = {
    loading: false,
    error: null,
    list: null,
    totalItems: 0
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_PACIENTES_MATCH_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case types.GET_PACIENTES_MATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                list: payload.data,
                totalItems: parseInt(payload.headers['x-total-count'], 10)
            }
        case types.GET_PACIENTES_MATCH_ERROR:
            return {
                ...state,
                list: [],
                loading: false,
                error: payload
            }
        case types.RESET_PACIENTES:
            return {
                ...initialState
            }
        default: return state
    }
}