import { REQUEST, SUCCESS, FAILURE } from './actionTypeCreator';

export const getListActions = (name, type) => {
  return {
    [`get${name}Start`]: () => ({ type: REQUEST(type) }),
    [`get${name}Success`]: (payload) => ({ type: SUCCESS(type), payload }),
    [`get${name}Error`]: (payload) => ({ type: FAILURE(type), payload }),
  };
};
export const getCreateActions = (name, type) => {
  return {
    [`create${name}Start`]: () => ({ type: REQUEST(type) }),
    [`create${name}Success`]: (payload) => ({ type: SUCCESS(type), payload }),
    [`create${name}Error`]: (payload) => ({ type: FAILURE(type), payload }),
  };
};
export const getReadActions = (name, type) => {
  return {
    [`get${name}Start`]: () => ({ type: REQUEST(type) }),
    [`get${name}Success`]: (payload) => ({ type: SUCCESS(type), payload }),
    [`get${name}Error`]: (payload) => ({ type: FAILURE(type), payload }),
  };
};
export const getUpdateActions = (name, type) => {
  return {
    [`update${name}Start`]: () => ({ type: REQUEST(type) }),
    [`update${name}Success`]: (payload) => ({ type: SUCCESS(type), payload }),
    [`update${name}Error`]: (payload) => ({ type: FAILURE(type), payload }),
  };
};
