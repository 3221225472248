import { Storage } from 'react-jhipster';
import jwtDecode from 'jwt-decode';
import {
  defaultColor,
  themeColorStorageKey,
  themeRadiusStorageKey,
  months,
  TOKEN_STORAGE_KEY,
  DEFAULT_USER,
  CONFIG_PDF,
} from '../constants/defaultValues';
import TipoArticulo from '../constants/TipoArticulo';

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = (date) => {
  const today = date ? new Date(date) : new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const currencyFormat = (number) => {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(number);
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getToken = () => {
  return Storage.local.get(TOKEN_STORAGE_KEY);
};

export const getResetStatus = () => {
  let resetStatus = 'NO';
  try {
    resetStatus = localStorage.getItem('resetStatus');
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getResetStatus -> error', error);
  }

  return resetStatus;
};

export const setResetStatus = (status) => {
  try {
    localStorage.setItem('resetStatus', status);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setResetstatus -> error', error);
  }
};

export const getUser = () => {
  try {
    const token = Storage.local.get(TOKEN_STORAGE_KEY);
    const { sub } = jwtDecode(token);
    return {
      ...DEFAULT_USER,
      login: sub,
    };
  } catch (error) {
    console.log('<<<< Error al decodificar el token >>>>>', error);
  }
  return DEFAULT_USER;
};

export const formatForSelectDescripcion = (array) =>
  array.map((v) => ({
    value: v.id,
    label: v.descripcion,
  }));
export const formatForSelectName = (array) =>
  array.map((v) => ({
    value: v.name,
    label: v.name,
  }));
export const formatForSelectNombre = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: v.nombre,
  }));
export const formatForSelectNumero = (array) =>
  array.map((v) => ({
    value: v.id,
    label: v.numero,
  }));
export const formatForSelectPersonName = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: `${v.nombre} ${v.apePaterno ?? ''} ${v.apeMaterno ?? ''}`.trim(),
  }));
export const formatForSelectExpresion = (array) =>
  array.map((v) => ({
    value: v.id,
    label: v.expresion,
    descripcion: v.descripcion,
  }));
export const formatForSelectMaquinas = (array) =>
  array.map((v) => ({
    value: v.id,
    label: v.modelo + ' ' + v.serie,
  }));
export const formatForSelectContenedor = (array) =>
  array.map((v) => ({
    value: v.id,
    label: v.descripcion,
    fracciones: v.fraccion ? v.fraccion : 10,
  }));
export const formatForEnum = (array) =>
  array.map((v) => ({
    value: v,
    label: v,
  }));
export const formatForSelectEstudios = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: v.nombre,
  }));
export const formatForSelectEstudiosDisableOptions = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: v.nombre,
    isDisabled: v.tipoArticulo === TipoArticulo.E && v.precio === 0,
  }));
export const formatForArticuloClinico = (array) =>
  array.map(({ id, estudio, perfil, tipoArticulo }) => ({
    value: id,
    id,
    tipoArticulo,
    label: tipoArticulo === 'ESTUDIO' ? estudio.nombre : perfil.nombre,
    clave: tipoArticulo === 'ESTUDIO' ? estudio.clave : perfil.clave,
    precio: tipoArticulo === 'ESTUDIO' ? estudio.precio : perfil.precio,
    estudio,
    perfil,
  }));
export const formatVariantes = (array) =>
  array.map((v) => ({
    tipoVariante: v.tipoVariante,
    value: v.id,
    label: v.nombre,
  }));
export const formatForSelectEstudiosSearch = (array) =>
  array.map((v) => ({
    ...v,
    descuento: '',
  }));
export const formatForSelectClientes = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: v.razonSocial,
  }));
export const formatForSelectVendedores = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: v.firstName,
  }));

export const getDecimalFormat = (number) => {
  return (Math.round(number * 100) / 100).toFixed(2);
};

export const formatDate = (fechaStr) => {
  return fechaStr.split(' ', 3).join(' ');
};
export const dateToString = (fecha) => {
  const today = new Date(fecha);
  let day = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${day}/${months[month]}/${year}`;
};

export const dateToStringWithTime = (fecha) => {
  const today = new Date(fecha);
  let day = today.getDate();
  const month = today.getMonth();
  const year = today.getUTCFullYear();
  if (day < 10) {
    day = `0${day}`;
  }
  let hours = today.getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let minutes = today.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  let seconds = today.getSeconds();
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
};

export const formatForSelectColonia = (array) =>
  array.map((v) => ({
    ...v,
    id: v.id,
    value: v.id,
    label: v.nombre,
  }));

export const checkForReq = (state, hrs = 0) => {
  if (state.ultimaCarga === null) return true;

  if (state.ultimaCarga !== null && hrs === 0) return false;

  const fecha = new Date(state.ultimaCarga);
  fecha.setHours(fecha.getHours() + hrs);
  const timeTmp = new Date();

  if (fecha.getTime() < timeTmp.getTime()) return true;

  return false;
};
const typesArchivos = {
  RM: 'RECETA_MEDICA',
  SM: 'SOLICITUD_MEDICA',
  DR: 'DOCUMENTO_REFERENCIA',
  O: 'OTRO',
};
export const formatTypesArchivosForEnum = (array) =>
  array
    .filter((v) => v !== 'FP')
    .map((v) => ({
      value: v,
      label: typesArchivos[v],
    }));

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
export const mergeMaps = (a, b) => {
  return new Map([...a, ...b]);
};
export const toCapitalized = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const formatForSelectMedida = (array) =>
  array.map((v) => ({
    ...v,
    value: v.id,
    label: v.expresion,
  }));

export const setCurrentConfigPdf = (opt) => {
  try {
    localStorage.setItem(CONFIG_PDF, opt);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentConfigPdf -> error',
      error
    );
  }
};

export const getCurrentConfigPdf = () => {
  let currentConfig = false;
  try {
    if (localStorage.getItem(CONFIG_PDF)) {
      currentConfig = JSON.parse(localStorage.getItem(CONFIG_PDF));
    } else {
      throw new Error('no existe la llave');
    }
  } catch (error) {
    setCurrentConfigPdf(false);
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentConfigPdf -> error',
      error
    );
    currentConfig = false;
  }
  return currentConfig;
};

export const addColorToOrden = (orders, valor) =>
  orders.map((v) => ({
    ...v,
    color:
      valor === 'muyUrgentes'
        ? 'rgb(245, 23, 23)'
        : valor === 'urgentes'
        ? 'rgb(245, 138, 23)'
        : valor === 'normales'
        ? 'rgb(42, 245, 23)'
        : valor === 'atrasadas'
        ? 'rgb(30, 90, 255)'
        : valor === 'pendientes'
        ? 'rgb(255, 202, 0)'
        : 'rgb(42, 245, 23)',
  }));
