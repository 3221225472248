import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const GET_ANTIBIOGRAMAS = 'antibioticos/GET_ANTIBIOGRAMAS';
const GET_ANTIBIOGRAMA = 'antibioticos/GET_ANTIBIOGRAMA';
const CREATE_ANTIBIOGRAMA = 'antibioticos/CREATE_ANTIBIOGRAMA';
const UPDATE_ANTIBIOGRAMA = 'antibioticos/UPDATE_ANTIBIOGRAMA';

export const {
  getAntibiogramasStart,
  getAntibiogramasSuccess,
  getAntibiogramasError,
} = getListActions('Antibiogramas', GET_ANTIBIOGRAMAS);

export const {
  getAntibiogramaStart,
  getAntibiogramaSuccess,
  getAntibiogramaError,
} = getReadActions('Antibiograma', GET_ANTIBIOGRAMA);

export const {
  createAntibiogramaStart,
  createAntibiogramaSuccess,
  createAntibiogramaError,
} = getCreateActions('Antibiograma', CREATE_ANTIBIOGRAMA);

export const {
  updateAntibiogramaStart,
  updateAntibiogramaSuccess,
  updateAntibiogramaError,
} = getUpdateActions('Antibiograma', UPDATE_ANTIBIOGRAMA);

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(GET_ANTIBIOGRAMAS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(GET_ANTIBIOGRAMA):
    case REQUEST(CREATE_ANTIBIOGRAMA):
    case REQUEST(UPDATE_ANTIBIOGRAMA):
      return {
        ...state,
        updating: true,
        error: null,
      };
    case FAILURE(GET_ANTIBIOGRAMAS):
    case FAILURE(GET_ANTIBIOGRAMA):
    case FAILURE(CREATE_ANTIBIOGRAMA):
    case FAILURE(UPDATE_ANTIBIOGRAMA):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case SUCCESS(GET_ANTIBIOGRAMAS):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(GET_ANTIBIOGRAMA):
    case SUCCESS(CREATE_ANTIBIOGRAMA):
    case SUCCESS(UPDATE_ANTIBIOGRAMA):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
