import * as types from '../types';

const initialState = {
  loading: false,
  mensajeError: null,
  corteCaja: null,
  list: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_CORTE_CAJA_START:
    case types.GET_CORTES_LIST_START:
      return {
        ...state,
        mensajeError: null,
        loading: true,
      };
    case types.GET_CORTE_CAJA_ERROR:
    case types.GET_CORTES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        mensajeError: payload,
      };
    case types.GET_CORTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };
    case types.GET_CORTE_CAJA_SUCCESS:
      return {
        ...state,
        loading: false,
        corteCaja: payload.data,
      };
    case types.RESET_CORTES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
