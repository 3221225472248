import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const GET_ESQUEMAS_BACTERIOLOGICOS = 'antibioticos/GET_ESQUEMAS_BACTERIOLOGICOS';
const GET_ESQUEMA_BACTERIOLOGICO = 'antibioticos/GET_ESQUEMA_BACTERIOLOGICO';
const CREATE_ESQUEMA_BACTERIOLOGICO = 'antibioticos/CREATE_ESQUEMA_BACTERIOLOGICO';
const UPDATE_ESQUEMA_BACTERIOLOGICO = 'antibioticos/UPDATE_ESQUEMA_BACTERIOLOGICO';

export const {
  getEsquemasBacteriologicosStart,
  getEsquemasBacteriologicosSuccess,
  getEsquemasBacteriologicosError,
} = getListActions('EsquemasBacteriologicos', GET_ESQUEMAS_BACTERIOLOGICOS);

export const {
  getEsquemaBacteriologicoStart,
  getEsquemaBacteriologicoSuccess,
  getEsquemaBacteriologicoError,
} = getReadActions('EsquemaBacteriologico', GET_ESQUEMA_BACTERIOLOGICO);

export const {
  createEsquemaBacteriologicoStart,
  createEsquemaBacteriologicoSuccess,
  createEsquemaBacteriologicoError,
} = getCreateActions('EsquemaBacteriologico', CREATE_ESQUEMA_BACTERIOLOGICO);

export const {
  updateEsquemaBacteriologicoStart,
  updateEsquemaBacteriologicoSuccess,
  updateEsquemaBacteriologicoError,
} = getUpdateActions('EsquemaBacteriologico', UPDATE_ESQUEMA_BACTERIOLOGICO);

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(GET_ESQUEMAS_BACTERIOLOGICOS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(GET_ESQUEMA_BACTERIOLOGICO):
    case REQUEST(CREATE_ESQUEMA_BACTERIOLOGICO):
    case REQUEST(UPDATE_ESQUEMA_BACTERIOLOGICO):
      return {
        ...state,
        updating: true,
        error: null,
      };
    case FAILURE(GET_ESQUEMAS_BACTERIOLOGICOS):
    case FAILURE(GET_ESQUEMA_BACTERIOLOGICO):
    case FAILURE(CREATE_ESQUEMA_BACTERIOLOGICO):
    case FAILURE(UPDATE_ESQUEMA_BACTERIOLOGICO):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case SUCCESS(GET_ESQUEMAS_BACTERIOLOGICOS):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(GET_ESQUEMA_BACTERIOLOGICO):
    case SUCCESS(CREATE_ESQUEMA_BACTERIOLOGICO):
    case SUCCESS(UPDATE_ESQUEMA_BACTERIOLOGICO):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
