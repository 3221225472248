import { FAILURE, SUCCESS, REQUEST } from '../../../helpers/actionTypeCreator';
import {
  getListActions,
  getCreateActions,
  getReadActions,
  getUpdateActions,
} from '../../../helpers/actionCreatorCreator';

const GET_GRUPOS_CULTIVOS = 'antibioticos/GET_GRUPOS_CULTIVOS';
const GET_GRUPO_CULTIVO = 'antibioticos/GET_GRUPO_CULTIVO';
const CREATE_GRUPO_CULTIVO = 'antibioticos/CREATE_GRUPO_CULTIVO';
const UPDATE_GRUPO_CULTIVO = 'antibioticos/UPDATE_GRUPO_CULTIVO';

export const {
  getGruposCultivosStart,
  getGruposCultivosSuccess,
  getGruposCultivosError,
} = getListActions('GruposCultivos', GET_GRUPOS_CULTIVOS);

export const {
  getGrupoCultivoStart,
  getGrupoCultivoSuccess,
  getGrupoCultivoError,
} = getReadActions('GrupoCultivo', GET_GRUPO_CULTIVO);

export const {
  createGrupoCultivoStart,
  createGrupoCultivoSuccess,
  createGrupoCultivoError,
} = getCreateActions('GrupoCultivo', CREATE_GRUPO_CULTIVO);

export const {
  updateGrupoCultivoStart,
  updateGrupoCultivoSuccess,
  updateGrupoCultivoError,
} = getUpdateActions('GrupoCultivo', UPDATE_GRUPO_CULTIVO);

const initialState = {
  loading: false,
  error: null,
  list: [],
  totalItems: 0,
  element: null,
  updating: false,
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST(GET_GRUPOS_CULTIVOS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REQUEST(GET_GRUPO_CULTIVO):
    case REQUEST(CREATE_GRUPO_CULTIVO):
    case REQUEST(UPDATE_GRUPO_CULTIVO):
      return {
        ...state,
        updating: true,
        error: null,
      };
    case FAILURE(GET_GRUPOS_CULTIVOS):
    case FAILURE(GET_GRUPO_CULTIVO):
    case FAILURE(CREATE_GRUPO_CULTIVO):
    case FAILURE(UPDATE_GRUPO_CULTIVO):
      return {
        ...state,
        loading: false,
        updating: false,
        error: payload,
      };
    case SUCCESS(GET_GRUPOS_CULTIVOS):
      return {
        ...state,
        loading: false,
        list: payload.data,
        totalItems: payload.totalItems,
      };
    case SUCCESS(GET_GRUPO_CULTIVO):
    case SUCCESS(CREATE_GRUPO_CULTIVO):
    case SUCCESS(UPDATE_GRUPO_CULTIVO):
      return {
        ...state,
        updating: false,
        element: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
